.PrescriptionApp {
  height: 550px;
  width: 730px;
  overflow-y: auto;
  background-color: #fff;
  position: relative;
}
.cp {
  cursor: pointer;
}
/* 
.PrescriptionApp-modal {
  position: absolute;
  border: 1px solid #3a3a3a;
  text-align: left;
  background-color: #fff;
  height: 100%;
  width: 100%;
  overflow-y: auto;
} */

.PrescriptionApp-body{
  background-color: #fff;
  display: block;
  height: auto;
}

/* width */
.customScroll::-webkit-scrollbar {
  width: 1  0px;
}

/* Track */
.customScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.customScroll::-webkit-scrollbar-thumb {
  background: #8f8f8f;
}

/* Handle on hover */
.customScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.PrescriptionApp-header {
  height: 40px;
  background-color: #4b4b4b;
  position: relative;
  padding: 7px;
}

.right-buttons{
  position: absolute;
  right: 15px;
  top: 0;
}

.PrescriptionApp-card {
  height: 200px;
  display: flex;
  width: 100%;
  position: relative;
  border: 2px solid black;
}

.PrescriptionAppCardLogo {
  margin: 15px;
  object-fit: contain;
}

.hospital-details {
  position: absolute;
  height: 100%;
  text-align: center;
  width: 413px;
  display: inline-flex;
  /* justify-content: center; */
  padding-top: 60px;
  flex-direction: column;
  padding-right: 20px;
}
.hospital-details-title {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
}

.hospital-details-subtitle {
  font-size: 13px;
  color: #fff;
}

.hospital-details-address {
  border-top: 3px solid;
  padding-top: 5px;
  margin: auto 50px 15px;
  font-size: 13px;
  color: #fff;
}

.PrescriptionApp-card-doctor-details {
  width: 350px;
  background-color: #fff;
  height: 100%;
  padding: 25px;
  margin-left: auto;
}

.PrescriptionApp-header-edit-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 55;
  cursor: pointer;
  padding: 2px;
}

.doctor-details {
  margin-top: 15px;
}

.DentalIcon {
  position: absolute;
  top: 50px;
  right: 40px;
}

.PrescriptionApp-IgesiaLogo {
  position: absolute;
  bottom: 18px;
  height: 25px;
  right: 45px;
}


.PrescriptionApp-editor .ck-editor__editable {
  min-height: 150px !important;
}

.PrescriptionApp-editor {
  height: auto;
}

.add-header-details-text {
  position: absolute;
  top: 14px;
  right: 35px;
  font-size: 13px;
  color: #020202;
  animation: addDetails 2s linear infinite;
}

@keyframes addDetails {
  0% {
    right: 35px;
  }
  25% {
    right: 25px;
  }
  50% {
    right: 35px;
  }
  75% {
    right: 25px;
  }
  100% {
    right: 35px;
  }
}

.PrescriptionApp-header-edit-modal {
  z-index: 9999;
  width: 100%;
  max-width: 720px;
  position: absolute;
  margin-left: 50%;
  transform: translateX(-50%);
  height: 500px;
  background-color: #fff;
  border: 1px solid;
}

.font-10 {
  font-size: 10px;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}
.font-20 {
  font-size: 20px;
}
.font-22 {
  font-size: 22px;
}
.font-24 {
  font-size: 24px;
}

.loading{
  position: absolute;
  z-index: 555;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.301);
}

.PrescriptionApp-header-edit-modal-footer{
  height: 51px;
}

.PrescriptionApp-header-edit-modal-body{
  height: 410px;
  overflow-y: scroll;
  background-color: #e3e3e4;
}
.SearchBar-input{
  font-size:13px;
  width: 250px;
  height: 30px;
  background-color:#1d1c1d;
  border:none;
  border-radius:3px;
  padding-left: 10px;
  color: #fff;
}

.SearchBar-input:focus{
 outline: none;
}

.SearchBar-input-hide{
  position: absolute;
  color: #919191;
  right: 5px;
  top: 2px;
  cursor: pointer;
  font-size: 15px;
}
/* blog cover */
.blog_cover_card {
    border: 1px solid #707070 !important;
    background-color: #3A3B3C !important;
}

.blog_cover_card_body {
    position: relative;
    background-color: #3A3B3C;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}

.blog_cover_img {
    min-height: 550px;
    background-position: center;
    background-size: cover;
}

.blog_cover_img1 {
    min-height: 350px;
    background-position: center;
    background-size: cover;
}

.blog_cover_logo {
    width: 50px;
    height: auto;
}

.blog_cover_content {
    margin-left: 10px;
    flex-grow: 1;
}

.blog_description {
    white-space: pre-line;
}

.more_blog_list {
    display: flex;
    flex-flow: column;
    overflow-y: scroll;
}
.more_blog {
    cursor: pointer;
}
.more_blog:hover {
    box-shadow: 2px 2px 2px 2px #EFEFEF;
    transition: ease-in ease-out;
}

.related_course_list {
    display: flex;
    flex-flow: column;
    overflow-y: scroll;
}
.related_course {
    cursor: pointer;
}
.related_course:hover {
    box-shadow: 2px 2px 2px 2px #EFEFEF;
    transition: ease-in ease-out;
}

.register_btn {
    padding: 10px 100px !important;
}

.cta_tabs {
    padding: 5px 25px !important;
    border-radius: 10px !important;
    font-weight: bold;
    color: white;
}
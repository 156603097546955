/* Event cover */
.dark_bg {
    /* background-color: #3A3B3C !important; */
    background-color: #FEFEFE !important; 
    
}

.event_cover_card {
    /* border: 1px solid #707070 !important; Arani */
    /* background-color: #3A3B3C !important; Arani */
    background-color: #FEFEFE !important;  /* Arani */
}

.event_cover_card_body {
    position: relative;
    background-color: #3A3B3C;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}

.event_cover_img {
    min-height: 550px;
    background-position: center;
    background-size: cover;
}

.event_cover_img1 {
    min-height: 350px;
    background-position: center;
    background-size: cover;
}

.event_cover_logo {
    width: 50px;
    height: auto;
}

.event_cover_content {
    margin-left: 10px;
    flex-grow: 1;
}

.register_btn {
    padding: 10px 100px !important;
}

.cta_tabs {
    padding: 0px 15px 10px 15px !important;
    font-size: 0.9rem;
}


.border_light {
    border: 1px solid #CFCFCF;
}

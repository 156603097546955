.Timeline {
  align-items: center;
  user-select: none;
  width: 100%;
}
.Timeline .timeline {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 0;
}
.Timeline .timeline .highlight {
  color: #b6854d !important;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 3.25rem;
  transform: translate3d(0, 10px, 0);
  transition: all 0.3s;
  width: 100%;
}
.Timeline .timeline .highlight.current {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.Timeline .timeline .highlight svg {
  fill: #dc3545 dd;
  pointer-events: none;
  stroke: none;
}
.Timeline .timeline .wheel {
  align-items: center;
  color: #fff;
  display: block;
  height: 3rem;
  margin-top: -0.75rem;
  overflow: hidden;
  width: 100%;
}
.Timeline .timeline .wheel__inner {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  pointer-events: none;
  transform-style: preserve-3d;
  width: 100%;
}
.Timeline .timeline .wheel__slides {
  height: 100%;
  margin-top: 2px;
  position: relative;
  width: 100%;
}
.Timeline .timeline .wheel__slide {
  -webkit-backface-visibility: hidden;
  align-items: center;
  backface-visibility: hidden;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
}
.Timeline .timeline .wheel__slide h5 {
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.6;
  padding: 4px;
  pointer-events: all;
  transition: all 0.3s;
  user-select: none;
}
.Timeline .timeline .wheel__slide h5.current {
  background-color: #6c757d 20;
  opacity: 0.8;
}
.Timeline .timeline .wheel__slide h5.current:hover {
  opacity: 1;
}
.Timeline .timeline .wheel__slide h5:hover {
  background-color: #6c757d 30;
  opacity: 0.8;
}
.Timeline .timeline .wheel__slide h5::selection {
  background: transparent !important;
}
.Timeline .timeline .wheel__slide .calendar-icon {
  align-self: center;
  cursor: pointer;
  display: flex;
  margin-left: 0.2rem;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}
.Timeline .timeline .wheel__slide .calendar-icon.show {
  opacity: 1;
  pointer-events: all;
}
.Timeline .timeline .wheel-buttons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  z-index: 1;
}
.Timeline .timeline .wheel-buttons .wheel-button {
  align-items: center;
  color: #6c757d;
  display: flex;
}
.Timeline .timeline .wheel-buttons .wheel-button.left svg {
  transform: rotate(180deg);
}
.Timeline .timeline .wheel-buttons .wheel-button.active svg {
  fill: #4c75f2;
  stroke: none;
}
.Timeline .timeline .wheel-buttons .wheel-button.active svg:hover {
  fill: #4c75f2 99;
  stroke: none;
}
.Timeline .Calendar {
  /* align-items: center;
  display: flex;
  justify-content: center; */
}
.Timeline .Calendar .react-calendar {
  border-radius: 5px;
  line-height: 2em;
}
.Timeline .Calendar .react-calendar *,
.Timeline .Calendar .react-calendar *::before,
.Timeline .Calendar .react-calendar *::after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.Timeline .Calendar .react-calendar .react-calendar__navigation {
  margin-bottom: 1em;
}
.Timeline .Calendar .react-calendar button {
  border: 0;
  border-radius: 5px;
  margin: 0.125rem;
  outline: none;
}
.Timeline .Calendar .react-calendar button:enabled:hover {
  cursor: pointer;
}
.Timeline .Calendar .react-calendar__viewContainer {
  display: flex;
}
.Timeline .Calendar .react-calendar__navigation {
  font-size: 12px;
}
.Timeline .Calendar .react-calendar__navigation button {
  background-color: #1d2124 !important;
  color: #ffffff;
  min-width: 44px;
  padding: 1em 0.5em;
}
.Timeline .Calendar .react-calendar__navigation button:enabled:hover {
  background-color: #6c757d 40;
}
.Timeline .Calendar .react-calendar__navigation button:disabled {
  background: none;
}
.Timeline .Calendar .react-calendar__navigation button div {
  align-items: center;
  display: flex;
  justify-content: center;
}
.Timeline .Calendar .react-calendar__month-view__weekdays {
  font-size: 0.75em;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}
.Timeline .Calendar .react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.Timeline .Calendar .react-calendar__tile {
  background: none;
  color: #6c757d;
  max-width: 100%;
  padding: 1em 0.5em;
  text-align: center;
}
.Timeline .Calendar .react-calendar__tile:enabled {
  background-color: #6c757d 10;
}
.Timeline .Calendar .react-calendar__tile:enabled.react-calendar__tile--now {
  background-color: #ffff76;
  color: #000;
}
.Timeline
  .Calendar
  .react-calendar__tile:enabled.react-calendar__tile--now:hover {
  background-color: #ffffa9;
}
.Timeline .Calendar .react-calendar__tile:enabled.react-calendar__tile--active,
.Timeline
  .Calendar
  .react-calendar__tile:enabled.react-calendar__tile--hasActive {
  background-color: #006edc;
  color: #fff;
}
.Timeline
  .Calendar
  .react-calendar__tile:enabled.react-calendar__tile--active:hover,
.Timeline
  .Calendar
  .react-calendar__tile:enabled.react-calendar__tile--hasActive:hover {
  background-color: #1087ff;
}
.Timeline
  .Calendar
  .react-calendar__tile:enabled.react-calendar__month-view__days__day--neighboringMonth {
  background-color: #6c757d 6;
  color: #6c757d 99;
}
.Timeline .Calendar .react-calendar__tile:enabled:hover {
  background-color: #6c757d 20;
}
.Timeline .Calendar .react-calendar__month-view .react-calendar__tile {
  flex-basis: calc(100% / 7 - 0.25rem) !important;
  max-width: calc(100% / 7 - 0.25rem) !important;
}
.Timeline .Calendar .react-calendar__year-view .react-calendar__tile {
  flex-basis: calc(100% / 3 - 0.25rem) !important;
  max-width: calc(100% / 3 - 0.25rem) !important;
}
.Timeline .Calendar .react-calendar__month-view__days__day--weekend {
  color: #ff073a;
}
.Timeline .Calendar .react-calendar__year-view .react-calendar__tile,
.Timeline .Calendar .react-calendar__decade-view .react-calendar__tile,
.Timeline .Calendar .react-calendar__century-view .react-calendar__tile {
  color: #6c757d;
  padding: 2em 0.5em;
}

.react-datetime-picker__inputGroup__input {
  color: #ffffff;
}

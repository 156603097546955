/**===================== 55. theme layout CSS Start ==========================**/
body .for-dark {
  display: none;
}
body[class*="dark-"] .for-light {
  display: none;
}
body[class*="dark-"] .for-dark {
  display: block;
}

.dark-only {
  color: var(--light);
}

.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .search-full
  .ProfileCard {
  border: 1px solid #374558 !important;
}
.dark-only .customizer-contain {
  color: rgb(35, 38, 38);
}
.dark-only .customizer-contain .form-control {
  background-color: transparent;
  color: #2b2b2b;
  border: 1px solid #ced4da;
}
.dark-only .card {
  background: #262932;
}
.dark-only .b-r-light {
  border-right: 1px solid #374558 !important;
}
.dark-only .btn {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .txt-dark {
  color: #fff !important;
}
.dark-only .btn-outline-light,
.dark-only .btn-outline-dark,
.dark-only .btn-outline-light-2x {
  color: #fff !important;
  border: 1px solid #374558;
}
.dark-only .btn-outline-light:hover {
  color: #000 !important;
}
.dark-only .dropdown-item {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .dropdown-item:hover {
  background-color: #262932;
}
.dark-only .form-control {
  background-color: rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .knowledgebase-search .form-inline .form-control {
  background: transparent;
  border-color: transparent;
}
.dark-only .social-status form .form-group .form-control-social {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .reactour__helper {
  background-color: rgb(35, 38, 38);
}
.dark-only .modal-content {
  background-color: #262932;
}
.dark-only .modal-content .modal-header {
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only .modal-content .modal-header .close {
  color: #98a6ad;
}
.dark-only .modal-footer {
  border-top: 1px solid #374558;
}
.dark-only .modal .grid-showcase span {
  background-color: rgb(35, 38, 38);
  border-color: rgb(35, 38, 38);
}
.dark-only .modal .form-control {
  background-color: rgb(35, 38, 38);
  border-color: rgb(35, 38, 38);
  color: #98a6ad;
}
.dark-only .comingsoon {
  background-blend-mode: overlay;
  background-color: rgba(54, 64, 74, 0.9);
}
.dark-only .comingsoon .comingsoon-inner h5 {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .comingsoon .comingsoon-inner .countdown {
  border-top: 1px solid rgb(35, 38, 38);
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only .comingsoon .comingsoon-inner .countdown .title {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .comingsoon .comingsoon-inner .coming-soon-bottom-link a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .comingsoon-bgimg,
.dark-only .auth-bg-video,
.dark-only .auth-bg {
  background-color: rgba(29, 30, 38, 0.9);
  background-blend-mode: overlay;
}
.dark-only .theme-form .login-divider {
  border-top: 1px solid rgb(35, 38, 38);
}
.dark-only .theme-form .login-divider:before {
  background: rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .authentication-main {
  background-color: rgb(35, 38, 38);
}
.dark-only .authentication-main .card {
  box-shadow: 0 0 41px 18px rgb(35, 38, 38);
  background-color: #262932;
}
.dark-only .authentication-main .auth-innerright .reset-password-box .card {
  background-color: #262932;
}
.dark-only
  .authentication-main
  .auth-innerright
  .reset-password-box
  .reset-password-link {
  color: #98a6ad;
}
.dark-only
  .authentication-main
  .auth-innerright
  .reset-password-box
  .theme-form
  .form-group
  label {
  color: #98a6ad;
}
.dark-only
  .authentication-main
  .auth-innerright
  .reset-password-box
  .theme-form
  .form-group
  .form-control {
  background-color: rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
  border-color: rgb(35, 38, 38);
}
.dark-only
  .authentication-main
  .auth-innerright
  .reset-password-box
  .theme-form
  .opt-box {
  background-color: rgb(35, 38, 38);
}
.dark-only .authentication-main .auth-innerright .authentication-box h4 {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .authentication-main .auth-innerright .authentication-box h6 {
  color: #98a6ad;
}
.dark-only .authentication-main .auth-innerright .authentication-box h3 {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .authentication-main .auth-innerright .authentication-box .card {
  background-color: #262932;
}
.dark-only
  .authentication-main
  .auth-innerright
  .authentication-box
  .card
  .theme-form
  .form-group
  input[type="text"],
.dark-only
  .authentication-main
  .auth-innerright
  .authentication-box
  .card
  .theme-form
  .form-group
  input[type="password"] {
  background-color: rgb(35, 38, 38);
  border: 1px solid rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .authentication-main
  .auth-innerright
  .authentication-box
  .card
  .theme-form
  .checkbox
  label::before {
  background-color: rgb(35, 38, 38);
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .vertical-menu-main {
  background-color: #262932;
}
.dark-only .mega-menu .title {
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only .mega-menu .list-unstyled div a:hover {
  color: var(--blue);
}
.dark-only .default-according .card {
  background-color: #262932;
}
.dark-only .default-according .card .btn-link {
  background-color: #262932;
  border: 1px solid #262932;
  color: #fff;
}
.dark-only .default-according .card .text-muted {
  color: #98a6ad !important;
}
.dark-only .default-according .bg-primary .btn-link {
  background-color: var(--blue);
  border: 1px solid var(--blue);
}
.dark-only .default-according .bg-secondary .btn-link {
  background-color: var(--theme-secondary);
  border: 1px solid var(--theme-secondary);
}
.dark-only .collapse .card-body {
  background-color: #262932;
}
.dark-only .ecommerce-widget {
  border: 1px solid #374558 !important;
}
.dark-only .blockquote {
  border-left: 4px solid #374558;
}
.dark-only .blockquote.text-right {
  border-right: 4px solid #374558;
}
.dark-only .contacts-tabs .nav-pills {
  border-right: 1px solid #374558;
}
.dark-only .contacts-tabs .nav-pills .nav-item + .nav-item {
  border-top: 1px solid #374558;
}
.dark-only .contact-profile .icon-wrapper {
  background-color: rgb(35, 38, 38);
}
.dark-only .list-persons .profile-mail .media .media-body ul li + li {
  border-left: 2px solid #374558;
}
.dark-only
  .product-wrapper.sidebaron
  .product-sidebar
  .filter-section
  .card
  .left-filter {
  background-color: #262932;
}
.dark-only #chart-widget9 .apexcharts-marker {
  stroke: transparent;
}
.dark-only #chart-bubble .apexcharts-marker {
  stroke: transparent;
}
.dark-only #chart-widget8 svg .apexcharts-radar-series polygon,
.dark-only #radarchart svg .apexcharts-radar-series polygon {
  stroke: rgb(35, 38, 38);
}
.dark-only #chart-widget8 svg .apexcharts-radar-series polygon:nth-child(odd),
.dark-only #radarchart svg .apexcharts-radar-series polygon:nth-child(odd) {
  fill: rgb(35, 38, 38);
}
.dark-only #chart-widget8 svg .apexcharts-radar-series polygon:nth-child(even),
.dark-only #radarchart svg .apexcharts-radar-series polygon:nth-child(even) {
  fill: #262932;
}
.dark-only #chart-widget8 svg .apexcharts-radar-series line,
.dark-only #radarchart svg .apexcharts-radar-series line {
  stroke: rgb(35, 38, 38);
}
.dark-only #chart-widget13 .apexcharts-tooltip.apexcharts-theme-light,
.dark-only #candlestick .apexcharts-tooltip.apexcharts-theme-light {
  background-color: rgb(35, 38, 38);
  border: 1px solid #374558;
}
.dark-only .apexcharts-gridline {
  stroke: rgb(35, 38, 38);
}
.dark-only .apexcharts-tooltip-title,
.dark-only .apexcharts-tooltip-series-group,
.dark-only .apexcharts-tooltip.light,
.dark-only .apexcharts-menu-item {
  color: #000;
}
.dark-only .calendar-wrap .fc-unthemed td,
.dark-only .calendar-wrap .fc-unthemed th {
  border-color: rgb(35, 38, 38);
}
.dark-only .rbc-month-view,
.dark-only .rbc-time-view {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .rbc-toolbar button {
  color: #98a6ad;
}
.dark-only .rbc-toolbar button:hover,
.dark-only .rbc-toolbar button:focus {
  background-color: var(--blue);
  color: #fff;
}
.dark-only .rbc-month-row + .rbc-month-row {
  border-top: 1px solid rgb(35, 38, 38);
}
.dark-only .rbc-off-range-bg {
  background: rgb(35, 38, 38);
}
.dark-only .rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid rgb(35, 38, 38);
}
.dark-only .rbc-header,
.dark-only .rbc-timeslot-group {
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only .rbc-header + .rbc-header,
.dark-only .rbc-timeslot-group + .rbc-header {
  border-left: 1px solid rgb(35, 38, 38);
}
.dark-only .rbc-time-content {
  border-top: 2px solid rgb(35, 38, 38);
}
.dark-only .rbc-agenda-view table.rbc-agenda-table {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid rgb(35, 38, 38);
}
.dark-only .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-top: 1px solid rgb(35, 38, 38);
}
.dark-only .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only .rbc-day-slot .rbc-time-slot {
  border-top: 1px solid rgb(35, 38, 38);
}
.dark-only .rbc-time-header-content,
.dark-only .rbc-time-content > * + * > * {
  border-left: rgb(35, 38, 38);
}
.dark-only #external-events {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .fc-unthemed th,
.dark-only .fc-unthemed td,
.dark-only .fc-unthemed thead,
.dark-only .fc-unthemed tbody,
.dark-only .fc-unthemed .fc-divider,
.dark-only .fc-unthemed .fc-row,
.dark-only .fc-unthemed .fc-content,
.dark-only .fc-unthemed .fc-popover,
.dark-only .fc-unthemed .fc-list-view,
.dark-only .fc-unthemed .fc-list-heading td {
  border-color: rgb(35, 38, 38);
}
.dark-only .fc-unthemed td.fc-today {
  background: rgb(35, 38, 38);
}
.dark-only .fc-unthemed .fc-divider,
.dark-only .fc-unthemed .fc-popover,
.dark-only .fc-unthemed .fc-list-heading {
  background-color: rgb(35, 38, 38);
}
.dark-only .fc-unthemed .fc-divider .fc-header,
.dark-only .fc-unthemed .fc-popover .fc-header,
.dark-only .fc-unthemed .fc-list-heading .fc-header,
.dark-only .fc-unthemed .fc-divider td,
.dark-only .fc-unthemed .fc-popover td,
.dark-only .fc-unthemed .fc-list-heading td {
  background-color: rgb(35, 38, 38);
}
.dark-only .fc-theme-standard td,
.dark-only .fc-theme-standard th,
.dark-only .fc-theme-standard .fc-scrollgrid {
  border: 1px solid #374558;
}
.dark-only .rbt-aux .rbt-close {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .rbt-input-main {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only ol.progtrckr li span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .editor-toolbar {
  border-top: 1px solid #98a6ad;
  border-left: 1px solid #98a6ad;
  border-right: 1px solid #98a6ad;
}
.dark-only .editor-toolbar a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only .editor-toolbar a:hover,
.dark-only .editor-toolbar a.active {
  background: rgb(35, 38, 38);
}
.dark-only .editor-toolbar i.separator {
  border-left: 1px solid #98a6ad;
  border-right: 1px solid #98a6ad;
}
.dark-only .editor-toolbar button {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .editor-toolbar button:hover,
.dark-only .editor-toolbar button:focus,
.dark-only .editor-toolbar button.active {
  background: rgb(35, 38, 38);
  border-color: rgb(35, 38, 38) rgb(35, 38, 38);
}
.dark-only .editor-toolbar.fullscreen::before,
.dark-only .editor-toolbar.fullscreen::after {
  background: linear-gradient(
    to right,
    rgb(35, 38, 38) 0,
    rgb(35, 38, 38) 100%
  );
}
.dark-only .editor-toolbar.disabled-for-preview a:not(.no-disable) {
  background: rgb(35, 38, 38);
}
.dark-only .editor-preview {
  background-color: #262932;
}
.dark-only .editor-toolbar.fullscreen,
.dark-only .editor-preview-side {
  background: #262932;
  border: 1px solid #98a6ad;
}
.dark-only #reactgooglegraph-2 svg rect[x="0"],
.dark-only #reactgooglegraph-3 svg rect[x="0"],
.dark-only #reactgooglegraph-4 svg rect[x="0"],
.dark-only #reactgooglegraph-5 svg rect[x="0"],
.dark-only #reactgooglegraph-6 svg rect[x="0"],
.dark-only #reactgooglegraph-8 svg rect[x="0"],
.dark-only #reactgooglegraph-7 svg rect[x="0"],
.dark-only #reactgooglegraph-10 svg rect[x="0"] {
  fill: transparent;
}
.dark-only #reactgooglegraph-2 svg g text[x="429"],
.dark-only #reactgooglegraph-3 svg g text[x="429"],
.dark-only #reactgooglegraph-4 svg g text[x="429"],
.dark-only #reactgooglegraph-5 svg g text[x="429"],
.dark-only #reactgooglegraph-6 svg g text[x="429"],
.dark-only #reactgooglegraph-8 svg g text[x="429"],
.dark-only #reactgooglegraph-7 svg g text[x="429"],
.dark-only #reactgooglegraph-10 svg g text[x="429"],
.dark-only #reactgooglegraph-2 svg g text[x="126"],
.dark-only #reactgooglegraph-3 svg g text[x="126"],
.dark-only #reactgooglegraph-4 svg g text[x="126"],
.dark-only #reactgooglegraph-5 svg g text[x="126"],
.dark-only #reactgooglegraph-6 svg g text[x="126"],
.dark-only #reactgooglegraph-8 svg g text[x="126"],
.dark-only #reactgooglegraph-7 svg g text[x="126"],
.dark-only #reactgooglegraph-10 svg g text[x="126"],
.dark-only #reactgooglegraph-2 svg g text[x="170"],
.dark-only #reactgooglegraph-3 svg g text[x="170"],
.dark-only #reactgooglegraph-4 svg g text[x="170"],
.dark-only #reactgooglegraph-5 svg g text[x="170"],
.dark-only #reactgooglegraph-6 svg g text[x="170"],
.dark-only #reactgooglegraph-8 svg g text[x="170"],
.dark-only #reactgooglegraph-7 svg g text[x="170"],
.dark-only #reactgooglegraph-10 svg g text[x="170"],
.dark-only #reactgooglegraph-2 svg g text[x="157"],
.dark-only #reactgooglegraph-3 svg g text[x="157"],
.dark-only #reactgooglegraph-4 svg g text[x="157"],
.dark-only #reactgooglegraph-5 svg g text[x="157"],
.dark-only #reactgooglegraph-6 svg g text[x="157"],
.dark-only #reactgooglegraph-8 svg g text[x="157"],
.dark-only #reactgooglegraph-7 svg g text[x="157"],
.dark-only #reactgooglegraph-10 svg g text[x="157"],
.dark-only #reactgooglegraph-2 svg g text[x="92"],
.dark-only #reactgooglegraph-3 svg g text[x="92"],
.dark-only #reactgooglegraph-4 svg g text[x="92"],
.dark-only #reactgooglegraph-5 svg g text[x="92"],
.dark-only #reactgooglegraph-6 svg g text[x="92"],
.dark-only #reactgooglegraph-8 svg g text[x="92"],
.dark-only #reactgooglegraph-7 svg g text[x="92"],
.dark-only #reactgooglegraph-10 svg g text[x="92"],
.dark-only #reactgooglegraph-2 svg g text[x="433"],
.dark-only #reactgooglegraph-3 svg g text[x="433"],
.dark-only #reactgooglegraph-4 svg g text[x="433"],
.dark-only #reactgooglegraph-5 svg g text[x="433"],
.dark-only #reactgooglegraph-6 svg g text[x="433"],
.dark-only #reactgooglegraph-8 svg g text[x="433"],
.dark-only #reactgooglegraph-7 svg g text[x="433"],
.dark-only #reactgooglegraph-10 svg g text[x="433"] {
  fill: rgba(255, 255, 255, 0.7);
}
.dark-only #reactgooglegraph-2 svg g line[x1="32.5"],
.dark-only #reactgooglegraph-3 svg g line[x1="32.5"],
.dark-only #reactgooglegraph-4 svg g line[x1="32.5"],
.dark-only #reactgooglegraph-5 svg g line[x1="32.5"],
.dark-only #reactgooglegraph-6 svg g line[x1="32.5"],
.dark-only #reactgooglegraph-8 svg g line[x1="32.5"],
.dark-only #reactgooglegraph-7 svg g line[x1="32.5"],
.dark-only #reactgooglegraph-10 svg g line[x1="32.5"] {
  stroke: rgb(35, 38, 38);
}
.dark-only #reactgooglegraph-7 svg g text[x="170"],
.dark-only #reactgooglegraph-8 svg g text[x="170"],
.dark-only #reactgooglegraph-9 svg g text[x="170"],
.dark-only #reactgooglegraph-7 svg g text[y="343.05"],
.dark-only #reactgooglegraph-8 svg g text[y="343.05"],
.dark-only #reactgooglegraph-9 svg g text[y="343.05"],
.dark-only #reactgooglegraph-7 svg g text[x="553"],
.dark-only #reactgooglegraph-8 svg g text[x="553"],
.dark-only #reactgooglegraph-9 svg g text[x="553"],
.dark-only #reactgooglegraph-7 svg g text[x="157"],
.dark-only #reactgooglegraph-8 svg g text[x="157"],
.dark-only #reactgooglegraph-9 svg g text[x="157"],
.dark-only #reactgooglegraph-7 svg g text[y="377.05"],
.dark-only #reactgooglegraph-8 svg g text[y="377.05"],
.dark-only #reactgooglegraph-9 svg g text[y="377.05"] {
  fill: rgba(255, 255, 255, 0.7);
}
.dark-only #reactgooglegraph-7 svg g rect[x="170"],
.dark-only #reactgooglegraph-8 svg g rect[x="170"],
.dark-only #reactgooglegraph-9 svg g rect[x="170"],
.dark-only #reactgooglegraph-7 svg g rect[x="105"],
.dark-only #reactgooglegraph-8 svg g rect[x="105"],
.dark-only #reactgooglegraph-9 svg g rect[x="105"],
.dark-only #reactgooglegraph-7 svg g rect[y="77"],
.dark-only #reactgooglegraph-8 svg g rect[y="77"],
.dark-only #reactgooglegraph-9 svg g rect[y="77"] {
  fill: rgb(35, 38, 38);
}
.dark-only #reactgooglegraph-5 svg g rect[x="128"],
.dark-only #reactgooglegraph-6 svg g rect[x="128"] {
  fill: rgb(35, 38, 38);
}
.dark-only #reactgooglegraph-8 svg g text[x="95"],
.dark-only #reactgooglegraph-8 svg g text[y="37.05"],
.dark-only #reactgooglegraph-8 svg g text[y="262.05"] {
  fill: rgba(255, 255, 255, 0.7);
}
.dark-only #reactgooglegraph-8 svg g line[y1="75.5"] {
  stroke: rgb(35, 38, 38);
}
.dark-only #reactgooglegraph-9 svg g text[y="232"],
.dark-only #reactgooglegraph-9 svg g text[y="247"] {
  fill: rgba(255, 255, 255, 0.7);
}
.dark-only #reactgooglegraph-9 svg g rect[x="0"] {
  fill: rgb(35, 38, 38);
  stroke: rgb(35, 38, 38);
}
.dark-only #reactgooglegraph-9 svg g line[x1="0"] {
  stroke: rgb(35, 38, 38);
}
.dark-only #reactgooglegraph-12 svg g text[x="1185"] {
  fill: rgba(255, 255, 255, 0.7);
}
.dark-only #reactgooglegraph-12 svg g g g rect[width="1"],
.dark-only #reactgooglegraph-12 svg g g g rect[height="1"] {
  fill: rgb(35, 38, 38);
}
.dark-only #reactgooglegraph-13 svg g text[x="155"],
.dark-only #reactgooglegraph-13 svg g text[x="1361"],
.dark-only #reactgooglegraph-13 svg g text[y="264.75"],
.dark-only #reactgooglegraph-13 svg g text[x="140"] {
  fill: rgba(255, 255, 255, 0.7);
}
.dark-only #reactgooglegraph-13 svg g rect[x="0"] {
  fill: #262932;
}
.dark-only #reactgooglegraph-13 svg g rect[x="155"] {
  fill: rgb(35, 38, 38);
}
.dark-only #reactgooglegraph-14 svg g text[x="126"],
.dark-only #reactgooglegraph-14 svg g text[y="343.05"],
.dark-only #reactgooglegraph-14 svg g text[x="113"] {
  fill: rgba(255, 255, 255, 0.7);
}
.dark-only #reactgooglegraph-14 svg g rect[y="77"] {
  fill: rgb(35, 38, 38);
}
.dark-only #reactgooglegraph-15 svg g rect[y="0"] {
  fill: #262932;
}
.dark-only #reactgooglegraph-15 svg g text[text-anchor="start"] {
  fill: rgba(255, 255, 255, 0.7);
}
.dark-only #reactgooglegraph-11 svg g rect[x="0"] {
  fill: #262932;
}
.dark-only #reactgooglegraph-11 svg g rect[height="42"]:nth-child(odd) {
  fill: #262932;
}
.dark-only #reactgooglegraph-11 svg g rect[height="42"]:nth-child(even) {
  fill: rgb(35, 38, 38);
}
.dark-only #reactgooglegraph-11 svg g rect[x="204"] {
  fill: #262932;
}
.dark-only #reactgooglegraph-11 svg g text[y="232"],
.dark-only #reactgooglegraph-11 svg g text[y="247"] {
  fill: rgba(255, 255, 255, 0.7);
}
.dark-only #reactgooglegraph-11 svg g line[x2="1530"] {
  stroke: rgb(35, 38, 38);
}
.dark-only #reactgooglegraph-10 svg g line[x1="32.5"] {
  stroke: rgb(35, 38, 38);
}
.dark-only #reactgooglegraph-10 svg g rect[height="350"] {
  fill: rgb(35, 38, 38);
}
.dark-only #reactgooglegraph-10 svg g text[x="1243"] {
  fill: rgba(255, 255, 255, 0.7);
}
.dark-only .vertical-timeline-element-content {
  background-color: rgb(35, 38, 38);
}
.dark-only
  .vertical-timeline-element-content
  .vertical-timeline-element-content-arrow {
  border-right: 7px solid rgb(35, 38, 38);
}
.dark-only .vertical-timeline::before {
  background: rgb(35, 38, 38);
}
.dark-only .vertical-timeline-element-icon {
  box-shadow: 0 0 0 4px rgb(35, 38, 38), inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.dark-only .draggable-card > div > div div[data-testid="lane"] {
  background-color: rgb(35, 38, 38);
}
.dark-only .draggable-card > div > div div[data-testid="card"] > div > div {
  background-color: #262932;
}
.dark-only
  .draggable-card
  > div
  > div
  div[data-testid="card"]
  > div
  > div
  span
  > div {
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only .classic_theme_container .classic_time {
  color: #242934;
}
.dark-only .input-cropper {
  border: 1px solid rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .dzu-previewButton {
  filter: brightness(0.5) invert(1);
}
.dark-only .dzu-previewContainer {
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only .rdt_Table {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .rdt_TableHeadRow {
  background-color: #262932;
}
.dark-only .rdt_TableCol,
.dark-only .rdt_TableCol_Sortable,
.dark-only .___react-data-table-allow-propagation___ {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .rdt_TableRow:nth-of-type(odd) {
  color: rgba(255, 255, 255, 0.7);
  background-color: rgb(35, 38, 38);
}
.dark-only .rdt_TableRow:nth-of-type(even) {
  color: rgba(255, 255, 255, 0.7);
  background-color: #262932;
}
.dark-only .fpZHHx {
  fill: rgba(255, 255, 255, 0.7);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .rdt_Pagination {
  background-color: #262932;
  border-top-color: rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .rdt_Pagination button {
  color: rgba(255, 255, 255, 0.7);
  fill: rgba(255, 255, 255, 0.7);
}
.dark-only .rdt_Pagination button:disabled {
  color: #98a6ad;
  fill: #98a6ad;
}
.dark-only .rdt_TableCol_Sortable:hover {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .rdt_TableHeader {
  background-color: rgb(35, 38, 38) !important;
}
.dark-only .rdt_TableHeader > div:last-child {
  background-color: rgb(35, 38, 38) !important;
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .small-donut ~ svg path:nth-child(even),
.dark-only .donut-peity2 ~ svg path:nth-child(even),
.dark-only .pie-peity ~ svg path:nth-child(even),
.dark-only .small-donut svg path:nth-child(even),
.dark-only .donut-peity2 svg path:nth-child(even),
.dark-only .pie-peity svg path:nth-child(even) {
  fill: rgb(35, 38, 38);
}
.dark-only .earning-card.card .card-body .left_side_earning p {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .earning-card.card .card-body .earning-content {
  border-right: 1px solid #374558;
}
.dark-only .earning-card.card .card-body .inner-top-left ul li,
.dark-only .earning-card.card .card-body .inner-top-right ul li {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .earning-card.card .card-body .inner-top-left ul li.active,
.dark-only .earning-card.card .card-body .inner-top-right ul li.active {
  color: var(--blue);
}
.dark-only .earning-card.card .card-body .media .media-body p {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .earning-card.card .card-body .border-top {
  border-top: 1px solid #374558 !important;
}
.dark-only .chart_data_left .card-body .chart-main .media,
.dark-only .chart_data_right .card-body .chart-main .media {
  border-right: 1px solid #374558;
}
.dark-only .chart_data_left .card-body .chart-main .media.border-none,
.dark-only .chart_data_right .card-body .chart-main .media.border-none {
  border-right: none;
}
.dark-only .chart_data_left .card-body .media .right-chart-content span,
.dark-only .chart_data_right .card-body .media .right-chart-content span {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only .chart_data_right .card-body .knob-block input {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only .third-news-update .news-update,
.dark-only .news .news-update,
.dark-only .appointment-sec .news-update {
  border-bottom: 1px solid #374558;
}
.dark-only .third-news-update .news-update span,
.dark-only .news .news-update span,
.dark-only .appointment-sec .news-update span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .third-news-update .appointment .radar-chart .apexcharts-datalabel,
.dark-only .news .appointment .radar-chart .apexcharts-datalabel,
.dark-only .appointment-sec .appointment .radar-chart .apexcharts-datalabel {
  fill: rgba(255, 255, 255, 0.7);
}
.dark-only .chat-default .card-body.chat-box .input-group .form-control {
  background-color: #262932 !important;
  border: none !important;
  border-left: 1px solid var(--blue) !important;
}
.dark-only
  .chat-default
  .card-body.chat-box
  .input-group
  .form-control::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .appointment-table table tr td.img-content-box .font-roboto {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .notification .card .media .media-body p span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .notification .card .media .media-body span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .current-sale-container
  svg
  .apexcharts-graphical
  defs
  linearGradient
  stop[offset="0.8"],
.dark-only
  .current-sale-container
  svg
  .apexcharts-graphical
  defs
  linearGradient
  stop[offset="1"] {
  stop-color: #262932;
}
.dark-only .radar-chart svg .apexcharts-radar-series polygon {
  stroke: #374558;
}
.dark-only .radar-chart svg .apexcharts-radar-series polygon:nth-child(odd) {
  fill: #262932;
}
.dark-only .radar-chart svg .apexcharts-radar-series polygon:nth-child(even) {
  fill: rgba(115, 102, 255, 0.2);
}
.dark-only .radar-chart svg .apexcharts-radar-series line {
  stroke: #374558;
}
.dark-only .dash-chart .media .media-body p {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .dash-chart .media .media-body p .font-roboto {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .our-product table tr td p {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .activity-timeline .media .activity-line {
  background-color: rgba(255, 255, 255, 0.2);
}
.dark-only .activity-timeline .media .media-body p {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .total-users .goal-status ul:last-child li {
  border-top-color: #374558;
}
.dark-only .best-seller-table table tbody tr td .label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .best-seller-table table tbody tr td p {
  color: rgba(255, 255, 255, 0.2);
}
.dark-only .best-seller-table table tbody tr:hover td {
  color: var(--blue) !important;
}
.dark-only .best-seller-table table tbody tr:hover td span {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only .best-seller-table table tbody tr:hover td .label,
.dark-only .best-seller-table table tbody tr:hover td p {
  color: var(--blue) !important;
}
.dark-only .activity-dot-primary {
  background-color: #262932;
}
.dark-only .activity-dot-secondary {
  background-color: #262932;
}
.dark-only .activity-dot-success {
  background-color: #262932;
}
.dark-only .activity-dot-danger {
  background-color: #262932;
}
.dark-only .activity-dot-info {
  background-color: #262932;
}
.dark-only .activity-dot-light {
  background-color: #262932;
}
.dark-only .activity-dot-dark {
  background-color: #262932;
}
.dark-only .activity-dot-warning {
  background-color: #262932;
}
.dark-only .login-card .btn-showcase .btn {
  background-color: #262932 !important;
  color: #fff;
  border-color: #262932 !important;
}
.dark-only .login-card .login-main {
  box-shadow: 0 0 37px rgba(255, 255, 255, 0.05);
}
.dark-only .login-card .login-main .theme-form input {
  background-color: #262932 !important;
}
.dark-only .login-card .login-main .theme-form .or:before {
  background-color: #262932;
}
.dark-only .login-card .login-main .theme-form .checkbox label::before {
  background-color: #262932 !important;
  border-color: #262932 !important;
}
.dark-only .H_ui ~ div {
  background-color: rgb(35, 38, 38) !important;
}
.dark-only .swal-icon--success:after,
.dark-only .swal-icon--success:before {
  background: #262932;
}
.dark-only .swal-icon--success__hide-corners {
  background-color: #262932;
}
.dark-only .note-editor.note-frame .note-status-output {
  border-top: 1px solid #262932;
}
.dark-only .note-editor.note-frame .note-statusbar {
  border-top: 1px solid #262932;
}
.dark-only .note-editor.note-frame .note-statusbar .note-resizebar {
  background-color: #262932;
}
.dark-only .light-font {
  color: rgba(255, 255, 255, 70);
}
.dark-only .email-wrap .email-right-aside .radius-left {
  background-color: rgb(35, 38, 38);
}
.dark-only .page-link {
  background-color: rgb(35, 38, 38);
  border: 1px solid #374558;
}
.dark-only .history-details .media {
  border-top: 1px solid #374558;
}
.dark-only .modal-header {
  border-bottom: 1px solid #374558;
}
.dark-only #right-history {
  background-color: #262932;
  box-shadow: 0 0 9px rgb(35, 38, 38);
}
.dark-only .ProfileCard-realName a,
.dark-only .ProfileCard-realName span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .ProfileCard:hover {
  color: rgba(255, 255, 255, 0.7);
  background-color: #262932;
}
.dark-only .translate_wrapper.active .more_lang:before {
  border-bottom: 7px solid #262932;
}
.dark-only .translate_wrapper.active .more_lang .lang {
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only .toggle-sidebar svg {
  stroke: var(--blue);
}
.dark-only .page-wrapper .page-body-wrapper {
  background-color: rgb(35, 38, 38);
}
.dark-only .page-wrapper .sidebar-main-title p {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper {
  background-color: #262932;
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a
  span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a
  svg {
  stroke: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5 {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content.opensubmegamenu
  li
  a {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container::after {
  background-color: rgba(255, 255, 255, 0.2);
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link.active
  svg {
  stroke: var(--blue);
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #262932;
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  ul.sidebar-submenu
  li
  a
  span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  ul.sidebar-submenu
  li
  a.active {
  color: var(--blue);
}
.dark-only .page-wrapper.compact-wrapper .page-body-wrapper .according-menu i {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper {
  background: #262932;
  border-top: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  > a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu {
  background: #262932;
}
.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a.active,
.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a:hover {
  color: var(--blue);
}
.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  .nav-sub-childmenu {
  background: #262932;
}
.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container {
  background: #262932;
}
.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .left-arrow,
.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .right-arrow {
  background-color: #262932;
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper {
  background-color: #262932;
}
.dark-only
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  > div {
  background: #262932;
}
.dark-only
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-title {
  border-bottom: 1px solid #374558;
}
.dark-only
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a
  span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a
  svg {
  stroke: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-submenu,
.dark-only
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .mega-menu-container {
  background-color: #262932;
}
.dark-only
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-submenu
  li
  a,
.dark-only
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .mega-menu-container
  li
  a {
  border-bottom: 1px solid #374558;
}
.dark-only
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link.active {
  background-color: rgba(115, 102, 255, 0.2);
}
.dark-only
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link.active
  span {
  color: var(--blue);
}
.dark-only
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link.active
  svg {
  color: var(--blue);
  stroke: var(--blue);
}
.dark-only
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5 {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content.opensubmegamenu
  li
  a {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}
.dark-only
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container::after {
  background-color: rgba(255, 255, 255, 0.2);
}
.dark-only
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  ul.sidebar-submenu
  li
  a
  span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  ul.sidebar-submenu
  li
  a.active {
  color: var(--blue);
}
.dark-only .page-wrapper.compact-sidebar .page-body-wrapper .according-menu i {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .page-wrapper.material-type .page-header .header-wrapper {
  border-radius: 15px 15px 0 0;
}
.dark-only .page-wrapper.material-icon .page-header .header-wrapper {
  background-color: transparent;
}
.dark-only
  .page-wrapper.material-icon
  .page-header
  .header-wrapper
  .nav-right
  .language-nav
  .more_lang {
  color: #fff;
}
.dark-only
  .page-wrapper.material-icon
  .page-header
  .header-wrapper
  .nav-right
  .onhover-show-div {
  color: #fff;
}
.dark-only
  .page-wrapper.material-icon
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .back
  .flip-back-content
  input {
  border: 1px solid rgb(35, 38, 38);
  background: rgb(35, 38, 38);
  color: #fff;
}
.dark-only .page-wrapper.material-icon .page-header .header-logo-wrapper {
  background-color: transparent;
}
.dark-only .page-wrapper.material-icon .page-body-wrapper .page-body {
  background-color: transparent;
}
.dark-only .page-wrapper.advance-layout {
  background-color: rgb(35, 38, 38);
}
.dark-only .page-wrapper.advance-layout .page-header {
  background-color: #262932;
}
.dark-only .page-wrapper.advance-layout .page-header .header-wrapper {
  background: transparent;
}
.dark-only
  .page-wrapper.advance-layout
  .page-header
  .header-wrapper
  .header-logo-wrapper {
  background: transparent;
}
.dark-only .page-wrapper.advance-layout .page-body-wrapper {
  background: transparent;
}
.dark-only .page-wrapper.advance-layout .page-body-wrapper .page-body {
  background: transparent;
}
.dark-only .page-wrapper .page-header .header-wrapper .search-full {
  background-color: #262932;
}
.dark-only .page-wrapper .page-header .header-wrapper .search-full input {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .onhover-show-div:not(.profile-dropdown)
  li:first-child {
  background-color: #262932;
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .front {
  background: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .front
  svg {
  stroke: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .front
  .bookmark-dropdown
  li:last-child {
  border-top: 1px solid #374558;
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .back {
  background: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .back
  .flip-back-content
  input {
  background-color: rgb(35, 38, 38);
  border-color: #374558;
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .back
  li:last-child {
  border-top: 1px solid #374558;
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .profile-dropdown
  li
  span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.dark-only .left-header .mega-menu-container {
  background-color: #262932;
  border-top: 1px solid #262932;
}
.dark-only .left-header .mega-menu-container .mega-box + .mega-box {
  border-left: 1px solid #374558;
}
.dark-only .left-header .mega-menu div > div a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .left-header .level-menu .header-level-menu {
  background-color: #262932;
}
.dark-only
  .left-header
  .level-menu
  .header-level-menu
  > li
  .header-level-sub-menu {
  background-color: #262932;
}
.dark-only .left-header .level-menu .header-level-menu > li a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .left-header .level-menu .header-level-menu > li svg {
  stroke: rgba(255, 255, 255, 0.7);
}
.dark-only .kanban-board-header {
  background-color: rgb(35, 38, 38) !important;
  border-bottom: 1px solid #262932 !important;
}
.dark-only .kanban-board .kanban-drag {
  background: rgb(35, 38, 38) !important;
}
.dark-only .kanban-container .kanban-item .kanban-box {
  border: 1px solid rgba(115, 102, 255, 0.15);
  background-color: rgb(35, 38, 38);
}
.dark-only .kanban-container .kanban-item .kanban-box {
  background-color: #262932;
}
.dark-only .jkanban-container .kanban-container .kanban-item .kanban-box {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .project-box {
  border: 1px solid rgba(115, 102, 255, 0.15);
  background-color: rgb(35, 38, 38);
}
.dark-only .file-content .form-inline {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .file-content .form-inline input {
  background: transparent;
  border-color: transparent;
}
.dark-only .file-content .files .file-box {
  border: 1px solid rgba(115, 102, 255, 0.15);
  background-color: rgb(35, 38, 38);
}
.dark-only .file-content .files .file-box .file-top {
  background-color: #262932;
  border: 1px solid rgba(115, 102, 255, 0.15);
}
.dark-only .file-content .folder .folder-box {
  border: 1px solid rgba(115, 102, 255, 0.15);
  background-color: rgb(35, 38, 38);
}
.dark-only .file-sidebar .pricing-plan {
  border: 1px solid rgba(115, 102, 255, 0.15) !important;
}
.dark-only .file-sidebar .btn-light {
  background-color: rgb(35, 38, 38) !important;
  border: 1px solid rgba(115, 102, 255, 0.15) !important;
}
.dark-only #lnb {
  border-right: 1px solid #374558;
}
.dark-only .lnb-new-schedule,
.dark-only .lnb-calendars > div {
  border-bottom: 1px solid #374558;
}
.dark-only #menu .sidebar-list .btn-default {
  color: rgba(255, 255, 255, 0.2);
}
.dark-only .tui-full-calendar-timegrid-hour {
  background-color: #262932;
  color: #fff !important;
}
.dark-only span.tui-full-calendar-dayname-date-area {
  color: #fff !important;
}
.dark-only .tui-full-calendar-layout {
  background-color: #262932 !important;
}
.dark-only
  .chat-default
  .card-body.chat-box
  .right-side-chat
  .media-body
  .message-main
  span {
  background-color: var(--blue);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .chat-default
  .card-body.chat-box
  .right-side-chat
  .media-body
  .message-main
  span.loader-span
  .dot {
  background: rgba(255, 255, 255, 0.7);
}
.dark-only .blockquote {
  border-left: 4px solid #374558;
}
.dark-only .blockquote.text-right {
  border-right: 4px solid #374558;
  border-left: none;
}
.dark-only .contacts-tabs .nav-pills {
  border-right: 1px solid #374558;
}
.dark-only .contacts-tabs .nav-pills .nav-link + .nav-link {
  border-top: 1px solid #374558;
}
.dark-only .list-persons .profile-mail .media .media-body ul li + li {
  border-left: 2px solid #374558;
}
.dark-only
  .product-wrapper.sidebaron
  .product-sidebar
  .filter-section
  .card
  .left-filter {
  background-color: #262932;
}
.dark-only .apexcharts-gridline {
  stroke: rgb(35, 38, 38);
}
.dark-only .apexcharts-tooltip-title,
.dark-only .apexcharts-tooltip-series-group,
.dark-only .apexcharts-tooltip.light,
.dark-only .apexcharts-menu-item {
  color: #000;
}
.dark-only .small-donut ~ svg path:nth-child(even),
.dark-only .donut-peity2 ~ svg path:nth-child(even),
.dark-only .pie-peity ~ svg path:nth-child(even),
.dark-only .small-donut svg path:nth-child(even),
.dark-only .donut-peity2 svg path:nth-child(even),
.dark-only .pie-peity svg path:nth-child(even) {
  fill: rgb(35, 38, 38);
}
.dark-only .earning-card.card .card-body .left_side_earning p {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .earning-card.card .card-body .earning-content {
  border-right: 1px solid #374558;
}
.dark-only .earning-card.card .card-body .inner-top-left ul li,
.dark-only .earning-card.card .card-body .inner-top-right ul li {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .earning-card.card .card-body .inner-top-left ul li.active,
.dark-only .earning-card.card .card-body .inner-top-right ul li.active {
  color: var(--blue);
}
.dark-only .earning-card.card .card-body .media .media-body p {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .earning-card.card .card-body .border-top {
  border-top: 1px solid #374558 !important;
}
.dark-only .chart_data_left .card-body .chart-main .media,
.dark-only .chart_data_right .card-body .chart-main .media {
  border-right: 1px solid #374558;
}
.dark-only .chart_data_left .card-body .chart-main .media.border-none,
.dark-only .chart_data_right .card-body .chart-main .media.border-none {
  border-right: none;
}
.dark-only .chart_data_left .card-body .media .right-chart-content span,
.dark-only .chart_data_right .card-body .media .right-chart-content span {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only .chart_data_right .card-body .knob-block input {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only .third-news-update .news-update,
.dark-only .news .news-update,
.dark-only .appointment-sec .news-update {
  border-bottom: 1px solid #374558;
}
.dark-only .third-news-update .news-update span,
.dark-only .news .news-update span,
.dark-only .appointment-sec .news-update span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .third-news-update .appointment .radar-chart .apexcharts-datalabel,
.dark-only .news .appointment .radar-chart .apexcharts-datalabel,
.dark-only .appointment-sec .appointment .radar-chart .apexcharts-datalabel {
  fill: rgba(255, 255, 255, 0.7);
}
.dark-only .chat-default .card-body.chat-box .input-group .form-control {
  background-color: #262932 !important;
  border: none !important;
  border-left: 1px solid var(--blue) !important;
}
.dark-only
  .chat-default
  .card-body.chat-box
  .input-group
  .form-control::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .appointment-table table tr td.img-content-box .font-roboto {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .notification .card .media .media-body p span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .notification .card .media .media-body span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .default-datepicker .react-datepicker {
  background: transparent;
}
.dark-only .default-datepicker .react-datepicker__day-name,
.dark-only .default-datepicker .react-datepicker__day,
.dark-only .default-datepicker .react-datepicker__time-name {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .default-datepicker .react-datepicker__header {
  border-bottom: 1px solid #374558;
}
.dark-only .default-datepicker .react-datepicker__day:hover,
.dark-only .default-datepicker .react-datepicker__month-text:hover,
.dark-only .default-datepicker .react-datepicker__quarter-text:hover,
.dark-only .default-datepicker .react-datepicker__year-text:hover {
  background-color: rgb(35, 38, 38);
}
.dark-only .dash-chart .media .media-body p {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .dash-chart .media .media-body p .font-roboto {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .our-product table tr td p {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .activity-timeline .media .activity-line {
  background-color: rgba(255, 255, 255, 0.2);
}
.dark-only .activity-timeline .media .media-body p {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .total-users .goal-status ul:last-child li {
  border-top-color: #374558;
}
.dark-only .best-seller-table table tbody tr td .label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .best-seller-table table tbody tr td p {
  color: rgba(255, 255, 255, 0.2);
}
.dark-only .best-seller-table table tbody tr:hover td {
  color: var(--blue) !important;
}
.dark-only .best-seller-table table tbody tr:hover td span {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only .best-seller-table table tbody tr:hover td .label,
.dark-only .best-seller-table table tbody tr:hover td p {
  color: var(--blue) !important;
}
.dark-only [class*="activity-dot-"] {
  background-color: #262932;
}
.dark-only .page-header .header-wrapper .nav-right .chat-dropdown li:last-child,
.dark-only
  .page-header
  .header-wrapper
  .nav-right
  .profile-dropdown
  li:last-child {
  border-top: 1px solid #374558 !important;
}
.dark-only .alert-primary {
  background-color: rgba(115, 102, 255, 0.8) !important;
  border-color: rgba(115, 102, 255, 0.9) !important;
  color: #fff;
}
.dark-only .alert-primary .progress {
  height: 5px;
  background-color: #6e61ff;
  border-radius: 0;
}
.dark-only .alert-primary .progress-bar {
  background-color: #fff;
}
.dark-only .alert-primary.inverse {
  background-color: transparent !important;
}
.dark-only .alert-secondary {
  background-color: rgba(247, 49, 100, 0.8) !important;
  border-color: rgba(247, 49, 100, 0.9) !important;
  color: #fff;
}
.dark-only .alert-secondary .progress {
  height: 5px;
  background-color: #f72c60;
  border-radius: 0;
}
.dark-only .alert-secondary .progress-bar {
  background-color: #fff;
}
.dark-only .alert-secondary.inverse {
  background-color: transparent !important;
}
.dark-only .alert-success {
  background-color: rgba(81, 187, 37, 0.8) !important;
  border-color: rgba(81, 187, 37, 0.9) !important;
  color: #fff;
}
.dark-only .alert-success .progress {
  height: 5px;
  background-color: #4fb724;
  border-radius: 0;
}
.dark-only .alert-success .progress-bar {
  background-color: #ebfae5;
}
.dark-only .alert-success.inverse {
  background-color: transparent !important;
}
.dark-only .alert-danger {
  background-color: rgba(220, 53, 69, 0.8) !important;
  border-color: rgba(220, 53, 69, 0.9) !important;
  color: #fff;
}
.dark-only .alert-danger .progress {
  height: 5px;
  background-color: #db3141;
  border-radius: 0;
}
.dark-only .alert-danger .progress-bar {
  background-color: #fff;
}
.dark-only .alert-danger.inverse {
  background-color: transparent !important;
}
.dark-only .alert-warning {
  background-color: rgba(248, 214, 43, 0.8) !important;
  border-color: rgba(248, 214, 43, 0.9) !important;
  color: #fff;
}
.dark-only .alert-warning .progress {
  height: 5px;
  background-color: #f8d526;
  border-radius: 0;
}
.dark-only .alert-warning .progress-bar {
  background-color: #fff;
}
.dark-only .alert-warning.inverse {
  background-color: transparent !important;
}
.dark-only .alert-info {
  background-color: rgba(169, 39, 249, 0.8) !important;
  border-color: rgba(169, 39, 249, 0.9) !important;
  color: #fff;
}
.dark-only .alert-info .progress {
  height: 5px;
  background-color: #a722f9;
  border-radius: 0;
}
.dark-only .alert-info .progress-bar {
  background-color: #fff;
}
.dark-only .alert-info.inverse {
  background-color: transparent !important;
}
.dark-only .alert-light {
  background-color: rgba(244, 244, 244, 0.8) !important;
  border-color: rgba(244, 244, 244, 0.9) !important;
  color: #fff;
}
.dark-only .alert-light .progress {
  height: 5px;
  background-color: #f1f1f1;
  border-radius: 0;
}
.dark-only .alert-light .progress-bar {
  background-color: #fff;
}
.dark-only .alert-light.inverse {
  background-color: transparent !important;
}
.dark-only .alert-dark {
  background-color: rgba(44, 50, 63, 0.8) !important;
  border-color: rgba(44, 50, 63, 0.9) !important;
  color: #fff;
}
.dark-only .alert-dark .progress {
  height: 5px;
  background-color: #2a303c;
  border-radius: 0;
}
.dark-only .alert-dark .progress-bar {
  background-color: #a8b0c2;
}
.dark-only .alert-dark.inverse {
  background-color: transparent !important;
}
.dark-only .options > div {
  border: 1px solid #374558;
}
.dark-only
  .was-validated
  .custom-control-input:invalid
  ~ .custom-control-label::before {
  background-color: rgb(35, 38, 38);
}
.dark-only .pricing-simple {
  box-shadow: 1px 1px 2px 1px rgb(35, 38, 38) !important;
}
.dark-only .pricing-block {
  box-shadow: 0 0 10px 5px rgba(115, 102, 255, 0.05) !important;
}
.dark-only .search-page ul.search-info li + li {
  border-left: 1px solid rgb(35, 38, 38);
}
.dark-only .browse .browse-articles h6 {
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only
  .job-search
  .job-description
  .theme-form
  .row
  div[class^="col-"]
  .input-group
  .datepicker-here {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .calendar-wrap .fc-unthemed td,
.dark-only .calendar-wrap .fc-unthemed th {
  border-color: rgb(35, 38, 38);
}
.dark-only .mega-inline .card,
.dark-only .mega-horizontal .card,
.dark-only .mega-vertical .card {
  box-shadow: 1px 1px 7px 0 rgba(55, 69, 88, 0.5) !important;
}
.dark-only .mega-inline.plain-style .card,
.dark-only .mega-horizontal.plain-style .card,
.dark-only .mega-vertical.plain-style .card,
.dark-only .mega-inline.border-style .card,
.dark-only .mega-horizontal.border-style .card,
.dark-only .mega-vertical.border-style .card,
.dark-only .mega-inline.offer-style .card,
.dark-only .mega-horizontal.offer-style .card,
.dark-only .mega-vertical.offer-style .card {
  box-shadow: none !important;
}
.dark-only .mega-inline.border-style .card,
.dark-only .mega-horizontal.border-style .card,
.dark-only .mega-vertical.border-style .card {
  border: 1px solid #374558 !important;
}
.dark-only .mega-inline.offer-style .card,
.dark-only .mega-horizontal.offer-style .card,
.dark-only .mega-vertical.offer-style .card {
  border: 1px dashed #374558 !important;
}
.dark-only .setting-dot .setting-bg {
  background-color: var(--theme-secondary);
}
.dark-only .bookmark.pull-right {
  border: none;
}
.dark-only .popover code {
  background-color: unset;
}
.dark-only
  .apex-chart-container
  .apexcharts-legend
  .apexcharts-legend-series
  span {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only .apexcharts-canvas svg .apexcharts-title-text {
  fill: #fff;
}
.dark-only .apexcharts-canvas svg .apexcharts-subtitle-text {
  fill: #fff;
}
.dark-only
  .apexcharts-canvas
  svg
  .apexcharts-yaxis
  .apexcharts-yaxis-texts-g
  .apexcharts-yaxis-label {
  fill: #fff;
}
.dark-only
  .apexcharts-canvas
  svg
  .apexcharts-xaxis
  .apexcharts-xaxis-texts-g
  .apexcharts-xaxis-label {
  fill: #fff;
}
.dark-only .apexcharts-canvas svg .apexcharts-legend-series span {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only
  .apexcharts-canvas
  svg
  .apexcharts-datalabels-group
  .apexcharts-datalabel-label {
  fill: #fff;
}
.dark-only
  .apexcharts-canvas
  svg
  .apexcharts-datalabels-group
  .apexcharts-datalabel-value {
  fill: #fff;
}
.dark-only .Typeahead-menu {
  background-color: rgb(35, 38, 38);
}
.dark-only .ecommerce-widget {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .bookmark ul li .search-form .form-control-search input {
  background-color: rgb(35, 38, 38);
  border: 1px solid rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .bookmark ul li .search-form .form-control-search:before {
  background: rgb(35, 38, 38);
}
.dark-only .cart .qty-box .input-group .btn {
  background-color: #2e3648 !important;
  border-color: #374558 !important;
}
.dark-only .checkout .checkout-details {
  background-color: rgb(35, 38, 38);
  border: 1px solid rgb(35, 38, 38);
  padding: 40px;
}
.dark-only .checkout .checkout-details .animate-chk .radio_animated:after {
  border: 2px solid #262932;
}
.dark-only .order-box .title-box {
  color: #bfc2c6;
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only .order-box .qty {
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only .order-box .qty li {
  color: #bfc2c6;
}
.dark-only .order-box .qty li span {
  color: #bfc2c6;
}
.dark-only .order-box .sub-total li {
  color: #bfc2c6;
}
.dark-only .order-box .total li {
  color: #bfc2c6;
}
.dark-only .shopping-checkout-option .checkbox_animated:after {
  border: 2px solid #262932;
}
.dark-only .animate-chk .radio_animated:after {
  border: 2px solid rgb(35, 38, 38);
}
.dark-only .custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(41, 50, 64, 0.52);
}
.dark-only .product-box .product-img .product-hover ul li .btn {
  color: #98a6ad;
}
.dark-only .product-box .product-img .product-hover ul li:hover .btn {
  color: #fff;
}
.dark-only .radio_animated:after,
.dark-only .checkbox_animated:after {
  background: rgb(35, 38, 38);
  border: 2px solid rgb(35, 38, 38);
}
.dark-only .slider-product {
  border-top: 1px solid rgb(35, 38, 38);
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only .square-product-setting .icon-grid {
  background-color: #262932;
}
.dark-only .square-product-setting .icon-grid svg {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .active-order-table table thead tr th,
.dark-only .market-table table thead tr th {
  border-top: none !important;
}
.dark-only .active-order-table table tbody tr td,
.dark-only .market-table table tbody tr td {
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only .active-order-table table tbody tr:last-child td,
.dark-only .market-table table tbody tr:last-child td {
  border-bottom: none;
}
.dark-only .pricing-card-design-2 .pricing-block .pricing-inner ul li {
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only .flot-chart-container .legend .table tbody {
  background-color: #262932;
  color: #fff;
}
.dark-only .flot-chart-container .legend .table tbody .legendLabel {
  padding-left: 5px;
}
.dark-only .google-visualization-tooltip text {
  fill: rgba(0, 0, 0, 0.7) !important;
}
.dark-only .nav-dark .nav-link,
.dark-only .nav-pills.nav-dark .nav-link {
  color: #fff;
}
.dark-only .loader-wrapper {
  background-color: rgb(35, 38, 38);
}
.dark-only .loader-wrapper .loader {
  background-color: rgb(35, 38, 38) !important;
}
.dark-only .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav {
  background-color: #262932;
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  > li
  .nav-submenu
  li
  a:after {
  background: #262932;
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5 {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5:after {
  background-color: #262932;
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content.opensubmegamenu
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  > li
  .mega-menu-container::after {
  background-color: rgba(255, 255, 255, 0.2);
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  > li
  .nav-link.active {
  color: #fff;
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  > li
  > a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #262932;
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  .dropdown
  ul.nav-submenu
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  .dropdown
  ul.nav-submenu
  li
  a.active {
  color: var(--blue);
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .sidebar-title
  p {
  color: #98a6ad;
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu:after {
  background-color: rgba(255, 255, 255, 0.2);
}
.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  a
  svg {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only .page-wrapper.compact-wrapper .page-body-wrapper .according-menu i {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .page-wrapper {
  /* Main Header start */
  /* Main Header ends */
}
.dark-only .page-wrapper .page-header .translate_wrapper.active .more_lang {
  background-color: rgb(35, 38, 38);
}
.dark-only .page-wrapper .page-header .translate_wrapper.active .lang {
  background-color: #262932;
}
.dark-only .page-wrapper .page-header form {
  background-color: rgb(35, 38, 38);
}
.dark-only .page-wrapper .page-header .header-wrapper {
  background-color: #262932;
}
.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li svg {
  stroke: #fff;
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right.right-header
  > ul
  > li
  .profile-media
  .media-body
  p {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .nav-menus
  li
  .media
  .media-body
  .txt-dark {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .nav-menus
  li
  .onhover-show-div {
  background-color: rgb(35, 38, 38);
  box-shadow: 0 0 14px rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .nav-menus
  li
  .onhover-show-div
  li {
  border-color: #262932;
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .nav-menus
  li
  .onhover-show-div
  p {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .nav-menus
  li
  .onhover-show-div:before {
  border-bottom: 7px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .nav-menus
  li
  .onhover-show-div:after {
  border-bottom: 7px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .nav-menus
  li
  .notification-dropdown.onhover-show-div
  li
  span {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .nav-menus
  li
  .notification-dropdown.onhover-show-div
  .bg-light {
  background-color: #282e38 !important;
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .profile-dropdown
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .profile-dropdown
  li
  a
  svg
  path,
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .profile-dropdown
  li
  a
  svg
  line,
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .profile-dropdown
  li
  a
  svg
  circle {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .profile-dropdown
  li
  a:hover {
  color: var(--blue);
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .profile-dropdown
  li
  a:hover
  svg
  line,
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .profile-dropdown
  li
  a:hover
  svg
  path,
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .profile-dropdown
  li
  a:hover
  svg
  circle {
  color: var(--blue) !important;
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  > ul
  > li
  .media
  .media-body
  .txt-dark {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  > ul
  > li
  .onhover-show-div {
  background-color: rgb(35, 38, 38);
  box-shadow: 0 0 2px 2px #262932;
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  > ul
  > li
  .onhover-show-div:before {
  border-bottom: 7px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  > ul
  > li
  .onhover-show-div:after {
  border-bottom: 7px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  > ul
  > li
  .notification-dropdown.onhover-show-div
  li {
  border-color: #262932;
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  > ul
  > li
  .notification-dropdown.onhover-show-div
  li
  span {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .chat-dropdown
  li {
  color: #fff;
  border-color: #262932;
}
.dark-only
  .page-wrapper
  .page-header
  .header-wrapper
  .left-header
  ul
  li
  .search-form
  input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .page-wrapper .page-header .header-logo-wrapper {
  background-color: #262932;
}
.dark-only
  .page-wrapper
  .page-header
  .header-logo-wrapper
  .logo-wrapper
  a
  .image-dark {
  display: none;
}
.dark-only
  .page-wrapper
  .page-header
  .header-logo-wrapper
  .logo-wrapper
  a
  .image-light {
  display: block;
}
.dark-only .page-wrapper .page-body-wrapper {
  /* body start*/
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .topper-lists
  table
  tbody
  tr
  td
  p {
  color: #98a6ad;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .header-small {
  color: #98a6ad;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .statistics p {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .feather-main
  .media-body
  p,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .professor-table
  .media-body
  p,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .feather-main
  .professor-block
  p,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .professor-table
  .professor-block
  p {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .logs-element
  span
  + span {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .progress-block
  .progress-title
  span
  + span {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .ecommerce-icons
  div
  span {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .new-users
  .media
  .media-body
  p,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .recent-notification
  .media
  .media-body
  p {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .progress-media
  .media
  .media-body
  span {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .progress-media
  .progress-change
  span {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .topper-lists
  table
  tbody
  tr
  td {
  border-top: none !important;
  border-bottom: 1px solid #374558;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .topper-lists
  table
  tbody
  tr:last-child
  td {
  border-bottom: none;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .notifiaction-media
  .media
  .media-body
  .circle-left {
  border: 5px solid #334154;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .upcoming-event
  .upcoming-innner {
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .upcoming-event
  .upcoming-innner:last-child {
  border-bottom: none;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .professor-table
  table
  tbody
  tr
  td {
  border-top: none !important;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .number-widgets
  .media
  .media-body
  h6 {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .activity
  .media
  .gradient-round.gradient-line-1:after,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .activity
  .media
  .gradient-round.small-line:after,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .activity
  .media
  .gradient-round.medium-line:after {
  background-color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .activity
  .media
  .media-body
  h6
  span {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card.custom-card
  .card-header {
  border-bottom: none !important;
}
.dark-only .page-wrapper .page-body-wrapper .custom-card .card-header img {
  background-color: #000;
  opacity: 0.8;
}
.dark-only .page-wrapper .page-body-wrapper .page-title .row h3 small {
  color: #98a6ad;
}
.dark-only .page-wrapper .page-body-wrapper .page-title .breadcrumb li {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-title
  .breadcrumb-item.active {
  color: #98a6ad;
}
.dark-only .page-wrapper .page-body-wrapper .page-body {
  background-color: rgb(35, 38, 38);
  /* body end*/
}
.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) {
  background-color: #262932;
  box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
  border: 1px solid #374558;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-block
  #bar-chart2
  svg
  > rect {
  fill: #262932;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-block
  #bar-chart2
  svg
  > g
  text {
  fill: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-block
  .word-tree
  svg
  > g
  > rect {
  fill: #262932;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-block
  .word-tree
  svg
  > g
  > text {
  fill: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .card-header {
  background-color: transparent;
  border-bottom: 1px solid #374558;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .card-header
  > span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .card-header
  h1,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .card-header
  h2,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .card-header
  h3,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .card-header
  h4,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .card-header
  h5,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .card-header
  h6 {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .card-header
  .card-header-right {
  background-color: #262932;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .card-header
  .card-header-right
  i {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .card-header
  .card-header-right
  i.fa-cog {
  color: var(--blue);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .alert-dark {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .alert-dark
  a {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .alert-light.outline,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .alert-light.outline-2x,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .alert-light.inverse {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  #animation-box
  .animate-widget
  p {
  color: #98a6ad !important;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .grid-item
  img {
  background-color: #262932;
  border: 1px solid #374558;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .line {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  td {
  color: rgba(255, 255, 255, 0.7);
  border-top: 1px solid #374558;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  thead
  th {
  border-bottom: 1px solid #374558;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  thead
  .border-bottom-primary
  th {
  border-bottom: 1px solid var(--blue);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .table-primary
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .table-secondary
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .table-success
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .table-info
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .table-warning
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .table-light
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .table-danger
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .table-primary
  td,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .table-secondary
  td,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .table-success
  td,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .table-info
  td,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .table-warning
  td,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .table-light
  td,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .table-danger
  td {
  color: #262932;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .bg-primary,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .bg-secondary,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .bg-success,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .bg-info,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .bg-warning,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .bg-danger {
  color: #fff;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .bg-light {
  color: #000;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .thead-light
  th {
  color: #000;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .double
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .dotted
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .dashed
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .double
  td,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .dotted
  td,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  .dashed
  td {
  border-color: #374558;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  thead
  .double
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  thead
  .dotted
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  thead
  .dashed
  th {
  border-color: #374558 !important;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  tbody
  .border-bottom-primary
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table
  tbody
  .border-bottom-primary
  td {
  border-bottom: 1px solid var(--blue);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table[class*="bg-"]
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table[class*="bg-"]
  td {
  color: #fff;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table-striped
  tbody
  tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table-striped
  tbody
  tr:nth-of-type(odd):hover
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table-striped
  tbody
  tr:nth-of-type(odd):hover
  td {
  color: #fff;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table-bordered {
  border-color: #374558 !important;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table-bordered
  td,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table-bordered
  th {
  border-color: #374558 !important;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table-borderless
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table-borderless
  td {
  border: 0;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table-borderless
  thead
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table-borderless
  tbody
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table-borderless
  thead
  + tbody,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table-borderless
  tbody
  + tbody {
  border: 0;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table-inverse
  tbody
  tr:nth-of-type(odd):hover
  td {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table-border-vertical
  tr,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table-border-vertical
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table-border-vertical
  td {
  border-right: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table-styling
  thead
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table-styling
  tbody
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table-styling
  thead
  td,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .table-styling
  tbody
  td {
  color: #fff;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .card-footer {
  background-color: #262932;
  border-top: 1px solid #374558;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .switch
  .switch-state {
  background-color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .switch
  input:checked
  + .switch-state {
  background-color: var(--blue);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .bg-white {
  background-color: #fff !important;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .b-l-light {
  border-left: 1px solid rgb(35, 38, 38) !important;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .ct-grid {
  stroke: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .ct-label {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  hr {
  border-top: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .text-muted {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .calender-widget
  .cal-date {
  background-color: #262932;
  border: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .contact-form
  .theme-form {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .contact-form
  .theme-form
  .form-icon {
  background-color: #262932;
  border: 1px solid rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .border-right {
  border-right: 1px solid rgb(35, 38, 38) !important;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .flot-chart-placeholder
  .flot-text {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .flot-chart-placeholder
  svg
  text {
  fill: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-overflow#gantt_chart
  svg
  g
  rect:first-child {
  fill: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-overflow#gantt_chart
  rect:nth-child(6) {
  fill: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-overflow#line-chart
  rect,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-overflow#column-chart1
  rect,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-overflow#column-chart2
  rect {
  fill: #262932;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-overflow
  svg
  > rect {
  fill: #262932;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-overflow
  svg
  > g
  > g
  > g
  text {
  fill: #b1b1b2;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-overflow
  svg
  > g
  > text {
  fill: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .radial-bar:after {
  background-color: #262932;
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .bar-chart-widget
  .bottom-content
  .num {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .bar-chart-widget
  .bottom-content
  .num
  .color-bottom {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .b-r-light {
  border-right: 1px solid rgb(35, 38, 38) !important;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-container
  .live-products
  .ct-labels
  .ct-label,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-container
  .turnover
  .ct-labels
  .ct-label,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-container
  .monthly
  .ct-labels
  .ct-label,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-container
  .uses
  .ct-labels
  .ct-label {
  color: #fff;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-container
  .live-products
  .ct-grid,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-container
  .turnover
  .ct-grid,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-container
  .monthly
  .ct-grid,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-container
  .uses
  .ct-grid {
  stroke: #fff;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-container
  #browser-uses-chart
  svg
  text,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .chart-container
  #website-visiter-chart
  svg
  text {
  fill: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .status-details
  h4 {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .status-details
  h4
  span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .status-details
  span {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .clipboaard-container
  .form-control {
  background-color: rgb(35, 38, 38);
  color: #98a6ad;
  border: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .img-thumbnail {
  background-color: rgb(35, 38, 38);
  border: 1px solid #374558;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  button {
  color: #000;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper.no-footer
  .dataTables_scrollBody {
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  .btn-danger,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  .btn-success,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  .btn-primary {
  color: #fff;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  .dataTables_length
  select {
  background-color: rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  .dataTables_length,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  .dataTables_filter,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  .dataTables_info,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  .dataTables_processing,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  .dataTables_paginate {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  .dataTables_paginate {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  .dataTables_filter
  input[type="search"] {
  background-color: rgb(35, 38, 38);
  border: 1px solid rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr {
  background-color: #262932;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr:hover
  > .sorting_1,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr:hover
  > .sorting_1 {
  background-color: #1c212b;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr.odd,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr.odd {
  background-color: #1f232b;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr.odd
  > .sorting_1,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr.odd
  > .sorting_1 {
  background-color: #1c212b;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr.even
  > .sorting_1,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr.even
  > .sorting_1 {
  background-color: #22262f;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable
  thead
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable
  thead
  td {
  border-bottom: 2px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable
  input,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable
  select {
  background-color: rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable
  tbody
  tr {
  background-color: #262932;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable
  tbody
  td.select-checkbox:before,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable
  tbody
  th.select-checkbox:before {
  border: 1px solid #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  .table-striped
  tbody
  tr:nth-of-type(odd) {
  background-color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:active {
  border-color: var(--blue);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled {
  color: #98a6ad !important;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled:hover,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled
  :active {
  color: #98a6ad !important;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.row-border
  tbody
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.display
  tbody
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.row-border
  tbody
  td,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.display
  tbody
  td {
  border-top: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr.even:hover
  > .sorting_1,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.order-column.hover
  tbody
  tr.even:hover
  > .sorting_1 {
  background-color: #191e27;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.hover
  tbody
  tr:hover,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr:hover {
  background-color: #1c212b;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.cell-border
  th,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.cell-border
  td {
  border-top: 1px solid rgb(35, 38, 38);
  border-right: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.cell-border
  th:first-child,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.cell-border
  td:first-child {
  border-left: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.order-column
  tbody
  tr
  > .sorting_1,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr
  > .sorting_1,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.order-column
  tbody
  tr
  > .sorting_2,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr
  > .sorting_2,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.order-column
  tbody
  tr
  > .sorting_3,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr
  > .sorting_3 {
  background-color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  #example-style-3_wrapper
  #example-style-3
  tfoot {
  border-top: 2px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .page-link {
  border: 1px solid #374558;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .page-item.disabled
  .page-link {
  background-color: #262932;
  border-color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .page-link {
  color: rgba(255, 255, 255, 0.7);
  background-color: #262932;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .page-item:hover
  .page-link {
  background-color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .page-item.active
  .page-link {
  background-color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .ecommerce-widget
  .icon {
  color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .ecommerce-widget
  .total-num {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .ecommerce-widget
  .total-num
  span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .ecommerce-widget
  span {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .flot-chart-container-small {
  background-color: #262932;
  border-top: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .product-table
  #API-2_wrapper
  #API-2
  tbody
  td
  span,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .product-table
  #API-2_wrapper
  #API-2
  tbody
  td
  p {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .product-table
  h6 {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .border-tab.nav-tabs {
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .border-tab.nav-tabs
  .nav-item
  .nav-link {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .border-tab.nav-tabs
  .nav-link.active,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .border-tab.nav-tabs
  .nav-link:focus,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .border-tab.nav-tabs
  .nav-link:hover {
  color: var(--blue);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-bars-1to10
  .br-widget
  a,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-bars-movie
  .br-widget
  a,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-bars-pill
  .br-widget
  a,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-bars-reversed
  .br-widget
  a,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-bars-horizontal
  .br-widget
  a {
  background-color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-bars-1to10
  .br-widget
  a.br-active,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-bars-movie
  .br-widget
  a.br-active,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-bars-pill
  .br-widget
  a.br-active,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-bars-reversed
  .br-widget
  a.br-active,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-bars-horizontal
  .br-widget
  a.br-active,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-bars-1to10
  .br-widget
  a.br-selected,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-bars-movie
  .br-widget
  a.br-selected,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-bars-pill
  .br-widget
  a.br-selected,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-bars-reversed
  .br-widget
  a.br-selected,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-bars-horizontal
  .br-widget
  a.br-selected {
  background-color: var(--blue);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-bars-square
  .br-widget
  a {
  border: 2px solid rgb(35, 38, 38);
  background-color: #262932;
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-bars-square
  .br-widget
  a.br-active,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-bars-square
  .br-widget
  a.br-selected {
  border: 2px solid var(--blue);
  color: var(--blue);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-fontawesome-stars
  .br-widget
  a:after,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-fontawesome-stars-o
  .br-widget
  a:after {
  color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-fontawesome-stars
  .br-widget
  a.br-selected:after,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-fontawesome-stars-o
  .br-widget
  a.br-selected:after,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-fontawesome-stars
  .br-widget
  a.br-active:after,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .br-theme-fontawesome-stars-o
  .br-widget
  a.br-active:after {
  color: var(--blue);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .scroll-demo {
  border: 1px solid #374558;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .search-form
  input {
  border: 1px solid rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
  background-color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .search-form
  .form-group:before {
  background: #262932;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .cd-timeline-content {
  background-color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .cd-timeline-content::before {
  border-left: 7px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .cd-timeline-block:nth-child(even)
  .cd-timeline-content::before {
  border-right-color: rgb(35, 38, 38);
  border-left: transparent;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .breadcrumb.bg-white {
  background-color: #262932 !important;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .user-status
  table
  td,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  .user-status
  table
  th {
  border-top: none !important;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  #donut-color-chart-morris-daily
  svg,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  #donut-color-chart-morris
  svg,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  #browser-uses-chart
  svg,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card:not(.email-body)
  #website-visiter-chart
  svg {
  opacity: 0.5;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder-header-1,
.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder-2-header {
  background-color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .show
  > .nav-link {
  color: #fff;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-link {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-link.active {
  color: #fff;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-pills.nav-primary
  .show
  > .nav-link {
  color: #fff;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-pills.nav-primary
  .nav-link {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-pills.nav-primary
  .nav-link.active {
  color: #fff;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .drag-box
  fieldset {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .help-block {
  color: #98a6ad;
}
.dark-only .page-wrapper .page-body-wrapper .page-body #viewhtml .render {
  background-color: #262932;
  color: rgba(255, 255, 255, 0.7);
  border-color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .form-builder-column
  .drag-bx {
  border: 1px dotted rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .wizard-4
  ul.anchor
  li
  a.disabled {
  color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .gallery-with-description
  h4 {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .gallery-with-description
  a
  > div {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .gallery-with-description
  p {
  color: #98a6ad;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-grid-header {
  background-color: #262932;
  border: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-header-cell,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-header-cell,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-cell,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-cell {
  background: #262932;
  border: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-header-cell
  input,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-header-cell
  input,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-cell
  input,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-cell
  input {
  background-color: rgb(35, 38, 38);
  border-color: rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-header-cell
  input:focus,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-header-cell
  input:focus,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-cell
  input:focus,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-cell
  input:focus {
  outline: none;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  select,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  select {
  background-color: rgb(35, 38, 38);
  border-color: rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-row
  > .jsgrid-cell {
  background-color: #262932;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-alt-row
  > .jsgrid-cell {
  background-color: rgb(35, 38, 38);
}
.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-cell,
.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-grid-body {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-selected-row
  > .jsgrid-cell {
  background-color: #1c212b;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid
  .jsgrid-pager
  [class*="jsgrid-pager"] {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-pager-current-page {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 700;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .sub-title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.dark-only .page-wrapper .page-body-wrapper .page-body .navs-icon li a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .page-wrapper .page-body-wrapper .page-body .navs-icon li a:hover {
  color: var(--blue);
}
.dark-only .page-wrapper .page-body-wrapper .page-body .navs-icon .separator {
  border-bottom: 1px solid #374558;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .default-according
  li
  .text-muted {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .navs-icon.default-according.style-1
  li
  button:hover {
  color: var(--blue) !important;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .nav-list
  .nav-list-disc
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .nav-list
  .nav-list-disc
  li:hover {
  color: var(--blue);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .nav-list
  .nav-list-disc
  li:hover
  a {
  color: var(--blue);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div {
  background-color: rgb(35, 38, 38);
  box-shadow: 0 0 14px 0 rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  p {
  color: #fff;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  path,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  line,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  polyline,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  polygon,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  rect {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  path,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  line,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  polyline,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  polygon,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  rect {
  color: var(--blue) !important;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .onhover-dropdown:hover
  .onhover-show-div:before {
  border-bottom: 7px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .default-according
  .card
  .btn-link {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .default-according
  .card
  .card-body {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .default-according
  .card
  .card-body,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .default-according
  .card
  .card-header {
  border: 1px solid #374558;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .border {
  border: 1px solid #374558 !important;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-date {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .blog-social
  li,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .blog-social
  li {
  color: #98a6ad;
  border-right: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .blog-social
  li:first-child,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .blog-social
  li:first-child {
  border-right: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .blog-social
  li:last-child,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .blog-social
  li:last-child {
  border-right: none;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  p,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  p {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .single-blog-content-top,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .single-blog-content-top {
  border-top: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .single-blog-content-top
  p,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .single-blog-content-top
  p {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  .media
  h6
  span {
  color: #98a6ad;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .comment-box .media img {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .page-wrapper .page-body-wrapper .page-body .comment-box p {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  .comment-social
  li {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  .comment-social
  li:first-child {
  border-right: 1px solid rgb(35, 38, 38);
}
.dark-only .page-wrapper .page-body-wrapper .page-body .comment-box hr {
  border-top: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .table-hover
  tbody
  tr:hover {
  background-color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .table-hover
  tbody
  tr:hover
  td,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .table-hover
  tbody
  tr:hover
  th {
  color: #fff;
}
.dark-only .page-wrapper .page-body-wrapper .page-body ul.the-icons li {
  border: 1px dotted #374558;
  color: rgba(255, 255, 255, 0.7);
  display: inline-block;
  padding: 10px;
}
.dark-only .page-wrapper .page-body-wrapper .page-body ul.the-icons li:hover {
  background: #000;
  box-shadow: 0 0 3px #262932;
}
.dark-only .page-wrapper .page-body-wrapper .page-body ul.the-icons li em {
  display: none;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .button-builder-wrap
  .box {
  background-color: #262932;
  border: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .button-builder-wrap
  .button-preview
  h2 {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .button-builder-wrap
  pre.well {
  background-color: #262932 !important;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .crm-activity > li + li {
  border-top: 1px solid rgb(35, 38, 38);
}
.dark-only .page-wrapper .page-body-wrapper .page-body .crm-activity > li h6 {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .crm-activity
  ul.dates
  li {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  #donut-color-chart-morris
  svg
  text,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  #donut-color-chart-morris-daily
  svg
  text {
  fill: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .card-profile
  img {
  background-color: #262932;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .profile-details
  h6 {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .card-social
  li
  a {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .card-footer
  > div
  h6 {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .card-footer
  > div
  + div {
  border-left: 1px solid #323846;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .checkbox label::before,
.dark-only .page-wrapper .page-body-wrapper .page-body .radio label::before {
  background-color: rgb(35, 38, 38);
  border: 1px solid rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-basic
  .dropdown
  .dropdown-content {
  background-color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-basic
  .dropdown
  .dropdown-content
  a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-basic
  .dropdown
  .dropdown-content
  a:hover {
  background-color: #262932;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-basic
  .dropdown
  .dropdown-content
  .dropdown-divider {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .dropup-basic
  .dropup
  .dropup-content {
  background-color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .dropup-basic
  .dropup
  .dropup-content
  a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .dropup-basic
  .dropup
  .dropup-content
  a:hover,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .dropup-basic
  .dropup
  .dropup-content
  a.active {
  background-color: #262932;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .dropzone.dz-clickable
  .dz-message
  h6 {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .dropzone.dz-clickable
  .dz-message
  span {
  color: #98a6ad;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .dropzone .dz-preview {
  background-color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .dropzone
  .dz-preview
  .dz-details {
  background-color: #262932;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .browser-widget
  .media-body {
  column-rule: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .browser-widget
  .media-body
  h4 {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .browser-widget
  .media-body
  h4
  span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .browser-widget
  .media-body
  span {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-app-sidebar
  .media
  img {
  border: 2px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-app-sidebar
  .main-menu
  > li
  a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-app-sidebar
  .main-menu
  > li
  a:hover {
  background-color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-right-aside
  .email-body
  .inbox
  .media.active {
  background-color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .media-body
  h6 {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .media-body
  h6
  small {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .media-body
  h6
  small
  span {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .media-body
  p {
  color: #98a6ad;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-top {
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap p {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-content
  .email-top
  .user-emailid:after {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-menu {
  background-color: rgb(35, 38, 38);
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-menu a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-menu a:hover {
  background-color: #262932;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .icon-lists div i {
  color: #98a6ad;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .icon-lists div:hover {
  background-color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins
  .media
  .media-body
  > span {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins
  .media
  .details {
  border-left: 1px solid rgb(35, 38, 38);
}
.dark-only .page-wrapper .page-body-wrapper .page-body .widget-joins:before,
.dark-only .page-wrapper .page-body-wrapper .page-body .widget-joins:after {
  background-color: rgb(35, 38, 38);
}
.dark-only .page-wrapper .page-body-wrapper .page-body .redial-social-widget i {
  background-color: #262932;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .social-widget-card h5,
.dark-only .page-wrapper .page-body-wrapper .page-body .social-widget-card h4 {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .social-widget-card
  span {
  color: #98a6ad;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .b-b-light {
  border-bottom: 1px solid rgb(35, 38, 38) !important;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .b-r-dark {
  border-right: 1px solid #fff !important;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .testimonial i {
  color: rgb(35, 38, 38);
}
.dark-only .page-wrapper .page-body-wrapper .page-body .testimonial p {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .page-wrapper .page-body-wrapper .page-body .testimonial h5 {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .page-wrapper .page-body-wrapper .page-body .testimonial span {
  color: #98a6ad;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .grid-showcase span {
  background-color: #262932;
  border: 1px solid #374558;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .grid-align .row {
  background-color: rgb(35, 38, 38);
  border: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-html-output {
  background-color: rgb(35, 38, 38);
  color: #98a6ad;
  border: 1px solid #374558;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .btn-grid {
  background-color: #262932;
  color: #98a6ad !important;
  border-right: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-mainControls
  .ge-addRowGroup
  .ge-row-icon
  .column {
  border-left: 3px solid #262932;
}
.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .btn-code,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .btn-screen {
  color: #98a6ad;
  border-right: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-canvas.ge-editing
  .row {
  background-color: rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-canvas.ge-editing
  .column {
  border: 1px solid rgb(35, 38, 38);
  background-color: #262932;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-canvas.ge-editing
  .ge-tools-drawer
  .ge-details
  input {
  background-color: rgb(35, 38, 38);
  border-color: rgb(35, 38, 38);
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-canvas.ge-editing
  .ge-tools-drawer
  .ge-details
  .btn-group
  a {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-content-type-ckeditor {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .search-page
  .info-block
  + .info-block {
  border-top: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card-absolute
  .bg-primary
  h5,
.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-body
  .card-absolute
  .bg-secondary
  h5 {
  color: #fff;
}
.dark-only .page-wrapper .page-body-wrapper .footer {
  border-top: 1px solid rgb(35, 38, 38);
  background-color: #262932;
}
.dark-only .page-wrapper .page-body-wrapper .footer p {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .page-wrapper .page-body-wrapper .custom-select,
.dark-only .page-wrapper .page-body-wrapper .custom-file-label {
  background: rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .page-wrapper .page-body-wrapper .footer-fix {
  background-color: #262932;
  border-top: 1px solid rgb(35, 38, 38);
}
.dark-only .page-wrapper .dt-button-info {
  background-color: #262932;
  border: 1px solid #98a6ad;
}
.dark-only .page-wrapper .dt-button-info h2 {
  background-color: #262932;
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only .page-wrapper .chat-box .about .name {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .page-wrapper .chat-box .chat-menu {
  border-left: 1px solid rgb(35, 38, 38);
}
.dark-only .page-wrapper .chat-box .chat-menu .nav-tabs {
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .chat-box
  .chat-menu
  .nav-tabs
  .nav-item
  .nav-link.active {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only
  .page-wrapper
  .chat-box
  .chat-menu
  .user-profile
  .image
  .icon-wrapper {
  background-color: #262932;
  box-shadow: 1px 1px 3px 1px rgb(35, 38, 38);
}
.dark-only .page-wrapper .chat-box .chat-menu .user-profile .image .avatar img {
  border: 5px solid rgb(35, 38, 38);
}
.dark-only .page-wrapper .chat-box .chat-menu .user-profile .social-media a {
  color: #98a6ad;
}
.dark-only .page-wrapper .chat-box .chat-menu .user-profile .follow span {
  color: #98a6ad;
}
.dark-only
  .page-wrapper
  .chat-box
  .chat-menu
  .user-profile
  .follow
  .follow-num {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .page-wrapper .chat-box .status {
  color: #98a6ad;
}
.dark-only .page-wrapper .chat-box .status p {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-header {
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-header
  .chat-menu-icons
  li
  a
  i {
  color: #98a6ad;
}
.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-header img {
  box-shadow: 1px 1px 4px 1px rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-msg-box
  .my-message {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-msg-box
  .message {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-msg-box
  .other-message {
  background-color: rgb(35, 38, 38);
}
.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-message {
  background-color: #262932;
  border-top: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-message
  .input-group
  .form-control {
  background-color: #262932;
}
.dark-only .page-wrapper .chat-box .chat-history .call-icons ul li {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .page-wrapper .chat-box .chat-history .call-icons ul li a {
  color: #98a6ad;
}
.dark-only .page-wrapper pre {
  background-color: rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .page-wrapper .scorlled {
  background-color: rgb(35, 38, 38);
}
.dark-only .page-wrapper .input-group-air {
  box-shadow: 0 3px 20px 0 rgb(35, 38, 38);
}
.dark-only .page-wrapper .input-group-solid .input-group-text,
.dark-only .page-wrapper .input-group-solid .form-control {
  background: rgb(35, 38, 38);
  border: 1px solid rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .page-wrapper .semilight-bg-color,
.dark-only .page-wrapper .header-bg-color {
  background-color: #fff;
}
.dark-only .page-wrapper .list-group-item {
  background-color: #262932;
  border: 1px solid #374558;
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .page-wrapper .list-group-item + .list-group-item {
  border-top-width: 0;
}
.dark-only .page-wrapper .list-group-item.active {
  background-color: var(--blue) !important;
  border-color: var(--blue);
  color: #fff;
}
.dark-only .page-wrapper .list-group-item:hover,
.dark-only .page-wrapper .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}
.dark-only .page-wrapper .list-group-item-action:hover:not(.active),
.dark-only .page-wrapper .list-group-item-action:focus {
  background-color: rgb(35, 38, 38);
}
.dark-only .page-wrapper .list-group-flush .list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}
.dark-only .page-wrapper .list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.dark-only .page-wrapper .list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.dark-only .page-wrapper .list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.dark-only .page-wrapper .list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.dark-only .page-wrapper .list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.dark-only .page-wrapper .list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.dark-only .page-wrapper .list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.dark-only .page-wrapper .list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.dark-only .page-wrapper .auth-bg {
  background-blend-mode: overlay;
  background-color: rgba(54, 64, 74, 0.9);
}
.dark-only .page-wrapper .auth-bg .authentication-box .card {
  background-color: #262932;
}
.dark-only
  .page-wrapper
  .auth-bg
  .authentication-box
  .card
  .theme-form
  .form-group
  input {
  color: rgba(255, 255, 255, 0.7);
  background-color: rgb(35, 38, 38);
  border: 1px solid rgb(35, 38, 38);
}
.dark-only
  .page-wrapper
  .auth-bg
  .authentication-box
  .card
  .theme-form
  .form-group
  label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .auth-bg
  .authentication-box
  .card
  .theme-form
  .checkbox
  label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .auth-bg
  .authentication-box
  .card
  .theme-form
  .checkbox
  label::before {
  background-color: rgb(35, 38, 38);
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .page-wrapper .auth-bg .authentication-box h3,
.dark-only .page-wrapper .auth-bg .authentication-box h4,
.dark-only .page-wrapper .auth-bg .authentication-box h6 {
  color: #fff;
}
.dark-only .page-wrapper .auth-bg-video {
  background-blend-mode: overlay;
  background-color: rgba(54, 64, 74, 0.7);
}
.dark-only .page-wrapper .auth-bg-video .authentication-box h4,
.dark-only .page-wrapper .auth-bg-video .authentication-box h3 {
  color: #fff;
}
.dark-only .page-wrapper .auth-bg-video .authentication-box h6 {
  color: #98a6ad;
}
.dark-only .page-wrapper .auth-bg-video .authentication-box .card {
  background-color: #262932;
}
.dark-only
  .page-wrapper
  .auth-bg-video
  .authentication-box
  .card
  .theme-form
  .form-group
  input[type="text"],
.dark-only
  .page-wrapper
  .auth-bg-video
  .authentication-box
  .card
  .theme-form
  .form-group
  input[type="password"] {
  background-color: rgb(35, 38, 38);
  border: 1px solid rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .page-wrapper
  .auth-bg-video
  .authentication-box
  .card
  .theme-form
  .checkbox
  label::before {
  background-color: rgb(35, 38, 38);
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .page-wrapper .error-wrapper .maintenance-icons li i {
  color: rgb(35, 38, 38);
}
.dark-only .page-wrapper .error-wrapper .maintenance-icons li:nth-child(3) i {
  color: rgb(35, 38, 38);
}
@media screen and (max-width: 1660px) {
  .dark-only .page-wrapper .chat-box .chat-history .call-icons ul li {
    border: 1px solid rgb(35, 38, 38);
  }
  .dark-only .page-wrapper .chat-box .chat-history .total-time h2 {
    color: rgba(255, 255, 255, 0.7);
  }
  .dark-only .page-wrapper .caller-img img {
    opacity: 0.7;
  }
  .dark-only .page-wrapper .chat-box .chat-history .call-content > div {
    background-color: rgba(0, 0, 0, 0.75);
    background-blend-mode: overlay;
  }
}
@media (max-width: 1199px) {
  .dark-only .page-wrapper .chat-menu {
    border-top: 1px solid rgb(35, 38, 38);
    background-color: #262932;
  }
  .dark-only .page-wrapper .error-wrapper .maintenance-icons li i {
    color: #2c323f;
  }
  .dark-only .page-wrapper .left-menu-header .mega-menu-container {
    border: 1px solid #374558;
  }
  .dark-only .page-wrapper .mobile-title.d-none {
    border-bottom: 1px solid #374558;
  }
}
@media (max-width: 575.98px) {
  .dark-only .page-wrapper .user-profile .hovercard .info .user-designation {
    border-top: 1px solid rgb(35, 38, 38);
    border-bottom: 1px solid rgb(35, 38, 38);
  }
  .dark-only .page-wrapper .widget-joins .border-after-xs:after {
    background-color: rgb(35, 38, 38);
  }
  .dark-only
    .page-wrapper
    .page-header
    .header-wrapper
    .left-header
    ul
    li
    .search-form
    .form-control-plaintext {
    background-color: rgb(35, 38, 38);
  }
  .dark-only
    .page-wrapper
    .page-wrapper.compact-wrapper
    .page-header
    .header-wrapper
    .toggle-sidebar,
  .dark-only
    .page-wrapper
    .page-wrapper.compact-sidebar
    .page-header
    .header-wrapper
    .toggle-sidebar {
    border-right: 1px solid #374558;
  }
}
.dark-only .lg-backdrop {
  background-color: rgb(35, 38, 38);
}
.dark-only .lg-outer .lg-thumb-outer {
  background-color: #262932;
}
.dark-only .drag {
  background-color: #262932;
}
.dark-only .drag form {
  background: #262932;
  box-shadow: 0 0 30px rgb(35, 38, 38);
  border: 1px dotted rgb(35, 38, 38);
}
.dark-only .drag form input {
  background-color: rgb(35, 38, 38);
  border: 1px solid rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .drag form .help-block {
  text-transform: capitalize;
  color: #98a6ad;
}
.dark-only .draggable input {
  background-color: rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .draggable p {
  color: #98a6ad;
}
.dark-only .draggable select {
  background-color: rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .draggable .radio label::before,
.dark-only .draggable .checkbox label::before {
  background-color: rgb(35, 38, 38);
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .select2-container--default .select2-selection--multiple,
.dark-only .select2-container--default .select2-selection--single {
  background-color: rgb(35, 38, 38);
  border: 1px solid rgb(35, 38, 38) !important;
}
.dark-only
  .select2-container--default
  .select2-search--inline
  .select2-search__field {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .select2-container--default
  .select2-search--dropdown
  .select2-search__field {
  background-color: rgb(35, 38, 38);
  border: 1px solid rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #262932 !important;
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only .select2-dropdown {
  background-color: rgb(35, 38, 38);
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .select2-drpdwn .form-control-primary {
  border-color: var(--blue) !important;
  color: var(--blue) !important;
}
.dark-only .select2-drpdwn .form-control-secondary {
  border-color: var(--theme-secondary) !important;
  color: var(--theme-secondary) !important;
}
.dark-only .select2-drpdwn .form-control-success {
  border-color: #51bb25 !important;
  color: #51bb25 !important;
}
.dark-only .select2-drpdwn .form-control-info {
  border-color: #a927f9 !important;
  color: #a927f9 !important;
}
.dark-only .select2-drpdwn .form-control-warning {
  border-color: #f8d62b !important;
  color: #f8d62b !important;
}
.dark-only .select2-drpdwn .form-control-danger {
  border-color: #dc3545 !important;
  color: #dc3545 !important;
}
.dark-only .select2-drpdwn .form-control-inverse {
  border-color: #fff !important;
  color: #fff !important;
}
.dark-only .select2-drpdwn .form-control-primary-fill {
  background-color: var(--blue) !important;
  color: #fff !important;
}
.dark-only .select2-drpdwn .form-control-secondary-fill {
  background-color: var(--theme-secondary) !important;
  color: #fff !important;
}
.dark-only .select2-drpdwn .form-control-success-fill {
  background-color: #51bb25 !important;
  color: #fff !important;
}
.dark-only .select2-drpdwn .form-control-info-fill {
  background-color: #a927f9 !important;
  color: #fff !important;
}
.dark-only .select2-drpdwn .form-control-warning-fill {
  background-color: #f8d62b !important;
  color: #fff !important;
}
.dark-only .select2-drpdwn .form-control-danger-fill {
  background-color: #dc3545 !important;
  color: #fff !important;
}
.dark-only .select2-drpdwn .form-control-inverse-fill {
  background-color: #fff !important;
  color: #000 !important;
}
.dark-only
  .select2-container--default.select2-container--disabled
  .select2-selection--single {
  background-color: rgb(35, 38, 38);
}
.dark-only
  .theme-form
  .form-group
  select.form-control:not([size]):not([multiple]) {
  background-color: rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
  border-color: rgb(35, 38, 38);
}
.dark-only .theme-form .form-group input[type="text"],
.dark-only .theme-form .form-group input[type="email"],
.dark-only .theme-form .form-group input[type="search"],
.dark-only .theme-form .form-group input[type="password"],
.dark-only .theme-form .form-group input[type="number"],
.dark-only .theme-form .form-group input[type="tel"],
.dark-only .theme-form .form-group input[type="date"],
.dark-only .theme-form .form-group input[type="datetime-local"],
.dark-only .theme-form .form-group input[type="time"],
.dark-only .theme-form .form-group input[type="month"],
.dark-only .theme-form .form-group input[type="week"],
.dark-only .theme-form .form-group input[type="url"],
.dark-only .theme-form .form-group input[type="file"],
.dark-only .theme-form .form-group select {
  border-color: rgb(35, 38, 38);
  background-color: rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .theme-form
  .form-group
  input[type="text"]::-webkit-input-placeholder,
.dark-only
  .theme-form
  .form-group
  input[type="email"]::-webkit-input-placeholder,
.dark-only
  .theme-form
  .form-group
  input[type="search"]::-webkit-input-placeholder,
.dark-only
  .theme-form
  .form-group
  input[type="password"]::-webkit-input-placeholder,
.dark-only
  .theme-form
  .form-group
  input[type="number"]::-webkit-input-placeholder,
.dark-only .theme-form .form-group input[type="tel"]::-webkit-input-placeholder,
.dark-only
  .theme-form
  .form-group
  input[type="date"]::-webkit-input-placeholder,
.dark-only
  .theme-form
  .form-group
  input[type="datetime-local"]::-webkit-input-placeholder,
.dark-only
  .theme-form
  .form-group
  input[type="time"]::-webkit-input-placeholder,
.dark-only
  .theme-form
  .form-group
  input[type="month"]::-webkit-input-placeholder,
.dark-only
  .theme-form
  .form-group
  input[type="week"]::-webkit-input-placeholder,
.dark-only .theme-form .form-group input[type="url"]::-webkit-input-placeholder,
.dark-only
  .theme-form
  .form-group
  input[type="file"]::-webkit-input-placeholder,
.dark-only .theme-form .form-group select::-webkit-input-placeholder {
  color: #98a6ad;
}
.dark-only .theme-form .form-group .form-control {
  background-color: rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .theme-form .form-group textarea {
  border-color: rgb(35, 38, 38);
}
.dark-only .theme-form .form-divider {
  border-top: 1px solid rgb(35, 38, 38);
}
.dark-only .theme-form .form-divider::before {
  background: #262932;
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .CodeMirror {
  background: #262932;
  border: 1px solid #98a6ad;
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .CodeMirror .CodeMirror-code pre {
  background: #262932;
}
.dark-only .editor-toolbar {
  border-top: 1px solid #98a6ad;
  border-left: 1px solid #98a6ad;
  border-right: 1px solid #98a6ad;
}
.dark-only .editor-toolbar a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-only .editor-toolbar a:hover,
.dark-only .editor-toolbar a.active {
  background: rgb(35, 38, 38);
}
.dark-only .editor-toolbar i.separator {
  border-left: 1px solid #98a6ad;
  border-right: 1px solid #98a6ad;
}
.dark-only .editor-toolbar.disabled-for-preview a:not(.no-disable) {
  background: rgb(35, 38, 38);
}
.dark-only .editor-preview {
  background-color: #262932;
}
.dark-only .editor-toolbar.fullscreen,
.dark-only .editor-preview-side {
  background: #262932;
  border: 1px solid #98a6ad;
}
.dark-only .u-step {
  background: rgb(35, 38, 38);
}
.dark-only .u-step.active,
.dark-only .u-step.current {
  background: var(--blue);
  color: #fff;
}
.dark-only .u-step-title,
.dark-only .u-pearl-title {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .u-step-number {
  background-color: #262932;
}
.dark-only .u-pearl:before {
  background-color: rgb(35, 38, 38);
}
.dark-only .u-pearl-number,
.dark-only .u-pearl-icon {
  background: rgb(35, 38, 38);
  border: 2px solid rgb(35, 38, 38);
}
.dark-only .u-pearl.disabled .u-pearl-icon,
.dark-only .u-pearl.disabled .u-pearl-number {
  background: rgb(35, 38, 38);
  border: 2px solid rgb(35, 38, 38);
}
.dark-only .u-pearl.disabled:after {
  background-color: #334053;
}
.dark-only .u-pearl.error:after {
  background-color: rgb(35, 38, 38);
}
.dark-only .note-editor.note-frame {
  border-color: rgb(35, 38, 38);
}
.dark-only .note-editor.note-frame .note-editing-area .note-editable {
  background-color: #262932;
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .swal-modal {
  background-color: #262932;
}
.dark-only .swal-modal .swal-title {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .swal-modal .swal-text {
  color: #98a6ad;
}
.dark-only .swal-modal .swal-content__input {
  background-color: rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .nav-tabs {
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only .nav-tabs .nav-link.active {
  background-color: #262932;
  color: rgba(255, 255, 255, 0.7);
  border-color: rgb(35, 38, 38) rgb(35, 38, 38) #262932;
}
.dark-only .nav-tabs .nav-link:hover,
.dark-only .nav-tabs .nav-link:focus {
  border-color: rgb(35, 38, 38) rgb(35, 38, 38) rgb(35, 38, 38);
}
.dark-only .nav-tabs .nav-item.show .nav-link {
  background-color: #262932;
  color: rgba(255, 255, 255, 0.7);
  border-color: rgb(35, 38, 38) rgb(35, 38, 38) #262932;
}
.dark-only .nav-tabs.nav-bottom .nav-item .nav-link.active,
.dark-only .nav-tabs.nav-bottom .nav-item .nav-link.focus,
.dark-only .nav-tabs.nav-bottom .nav-item .nav-link.hover {
  border-color: #262932 rgb(35, 38, 38) rgb(35, 38, 38);
}
.dark-only .border-tab.nav-left .nav-link,
.dark-only .border-tab.nav-right .nav-link {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .border-tab.nav-left .nav-link.active,
.dark-only .border-tab.nav-right .nav-link.active {
  color: var(--blue);
}
.dark-only .border-tab.nav-left .show > .nav-link,
.dark-only .border-tab.nav-right .show > .nav-link {
  color: var(--blue);
}
.dark-only .border-tab.nav-left.nav-secondary .nav-link.active,
.dark-only .border-tab.nav-left.nav-secondary .nav-link:focus,
.dark-only .border-tab.nav-left.nav-secondary .nav-link:hover {
  border-left-color: var(--theme-secondary);
  color: var(--theme-secondary) !important;
}
.dark-only .border-tab.nav-left.nav-secondary .show > .nav-link {
  border-left-color: var(--theme-secondary);
  color: var(--theme-secondary) !important;
}
.dark-only .border-tab.nav-left.nav-secondary .nav-item.show {
  color: var(--theme-secondary) !important;
  border-left-color: var(--theme-secondary);
}
.dark-only .border-tab.nav-left.nav-success .nav-link.active,
.dark-only .border-tab.nav-left.nav-success .nav-link:focus,
.dark-only .border-tab.nav-left.nav-success .nav-link:hover {
  color: #51bb25 !important;
}
.dark-only .border-tab.nav-left.nav-success .show > .nav-link {
  border-left-color: #51bb25;
  color: #51bb25 !important;
}
.dark-only .border-tab.nav-left.nav-success .nav-item.show {
  color: #51bb25 !important;
  border-left-color: #51bb25;
}
.dark-only .border-tab.nav-right.nav-info .nav-link.active,
.dark-only .border-tab.nav-right.nav-info .nav-link:focus,
.dark-only .border-tab.nav-right.nav-info .nav-link:hover {
  color: #a927f9 !important;
}
.dark-only .border-tab.nav-right.nav-info .show > .nav-link {
  border-left-color: #a927f9;
  color: #a927f9 !important;
}
.dark-only .border-tab.nav-right.nav-info .nav-item.show {
  color: #a927f9 !important;
  border-left-color: #a927f9;
}
.dark-only .border-tab.nav-secondary .nav-link.active,
.dark-only .border-tab.nav-secondary .nav-link:focus,
.dark-only .border-tab.nav-secondary .nav-link:hover {
  color: var(--theme-secondary) !important;
}
.dark-only .border-tab.nav-secondary .nav-item.show {
  color: var(--theme-secondary) !important;
}
.dark-only .border-tab.nav-success .nav-link.active,
.dark-only .border-tab.nav-success .nav-link:focus,
.dark-only .border-tab.nav-success .nav-link:hover {
  color: #51bb25 !important;
}
.dark-only .border-tab.nav-success .nav-item.show {
  color: #51bb25 !important;
}
.dark-only .dropdown-divider {
  border-top: 1px solid rgb(35, 38, 38);
}
.dark-only .icon-hover-bottom {
  background-color: #262932;
  box-shadow: 0px 0px 1px 1px rgb(35, 38, 38);
}
.dark-only .icon-hover-bottom .icon-title {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .icon-hover-bottom span {
  color: #98a6ad;
}
.dark-only .icon-hover-bottom .form-group input {
  background-color: rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .icon-hover-bottom .icon-first i {
  color: #98a6ad;
}
.dark-only code {
  background-color: rgb(35, 38, 38);
  border-radius: 2px;
}
.dark-only #cd-timeline::before {
  background: rgb(35, 38, 38);
}
.dark-only .timeliny {
  border-top: 1px solid rgb(35, 38, 38);
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only .timeliny::before {
  background-color: #fff;
}
.dark-only .timeliny .timeliny-dot {
  background-color: #262932;
  border: 1px solid #fff;
}
.dark-only .timeliny .timeliny-dot::before {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .timeliny
  .timeliny-timeline
  .timeliny-timeblock.active
  .timeliny-dot::before {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .timeliny
  .timeliny-timeline
  .timeliny-timeblock.active
  .timeliny-dot::after {
  background-color: rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
  border: none;
}
.dark-only
  .timeliny
  .timeliny-timeline
  .timeliny-timeblock:not(.inactive):not(.active)
  .timeliny-dot:hover {
  background-color: #fff;
}
.dark-only
  .timeliny
  .timeliny-timeline
  .timeliny-timeblock:not(.inactive):not(.active)
  .timeliny-dot:hover::before {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  .timeliny
  .timeliny-timeline
  .timeliny-timeblock
  .timeliny-dot:hover::after {
  background-color: #262932;
  border: 1px solid rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .todo .todo-list-wrapper #todo-list li .task-container {
  background: #262932;
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only .todo .todo-list-wrapper #todo-list li .task-container .task-label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .todo .todo-list-wrapper #todo-list li .task-container:hover h4 {
  color: #fff;
}
.dark-only
  .todo
  .todo-list-wrapper
  #todo-list
  li
  .task-container
  .task-action-btn
  .action-box:hover:not(.active) {
  background: #262932;
  border: 1px solid rgb(35, 38, 38);
}
.dark-only
  .todo
  .todo-list-wrapper
  #todo-list
  li.completed
  .task-container
  .task-label {
  color: var(--blue);
}
.dark-only
  .todo
  .todo-list-wrapper
  #todo-list
  li.completed
  .task-container
  .complete-btn:hover {
  border: 1px solid #51bb25 !important;
}
.dark-only
  .todo
  .todo-list-wrapper
  .todo-list-footer
  .new-task-wrapper
  textarea {
  background-color: #262932;
  border: 1px solid rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .user-profile .ttl-info h6 {
  color: #98a6ad;
}
.dark-only .user-profile .ttl-info span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .user-profile .hovercard .info .title a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .user-profile .hovercard .user-image .avatar img {
  border: 10px solid #262932;
}
.dark-only .user-profile .hovercard .user-image .icon-wrapper {
  background-color: #262932;
}
.dark-only .user-profile .hovercard .tabs-scoial {
  border-bottom: none !important;
}
.dark-only .user-profile .follow .follow-num {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .user-profile .profile-img-style .user-name {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .user-profile .profile-img-style p {
  color: #98a6ad;
}
.dark-only .introjs-helperLayer {
  background-color: rgba(54, 64, 74, 0.9);
  border: rgba(255, 255, 255, 0.5);
}
.dark-only .jstree-default .jstree-node,
.dark-only .jstree-default .jstree-icon {
  color: #455869 !important;
}
.dark-only .jstree-default .jstree-anchor {
  color: #98a6ad;
}
.dark-only .jstree-default .jstree-clicked {
  color: #fff;
  background-color: transparent;
}
.dark-only .jstree-default .jstree-hovered {
  background-color: rgb(35, 38, 38);
  border: 1px solid rgb(35, 38, 38);
}
.dark-only span.twitter-typeahead .tt-menu {
  background-color: rgb(35, 38, 38);
  color: rgba(255, 255, 255, 0.7);
}
.dark-only span.twitter-typeahead .tt-suggestion {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only span.twitter-typeahead .tt-suggestion:hover,
.dark-only span.twitter-typeahead .tt-suggestion:focus {
  background-color: #262932;
}
.dark-only .typography small {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .code-box-copy pre {
  background-color: rgb(35, 38, 38);
}
.dark-only .code-box-copy pre code {
  background-color: rgb(35, 38, 38);
}
.dark-only .code-box-copy pre[class*="language-"] {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .code-box-copy .code-box-copy__btn {
  background-color: rgb(35, 38, 38);
  border: 1px solid rgb(35, 38, 38);
  color: #fff;
}
.dark-only .code-box-copy code[class*="language-"],
.dark-only .code-box-copy pre[class*="language-"] {
  text-shadow: 0px 1px #000;
}
.dark-only .code-box-copy code[class*="language-"] ::selection,
.dark-only .code-box-copy pre[class*="language-"] ::selection {
  text-shadow: none;
  background: #262932;
}
.dark-only table.fixedHeader-floating {
  background-color: rgb(35, 38, 38);
}
.dark-only pre {
  background-color: rgb(35, 38, 38);
}
.dark-only #keytable_wrapper .dataTables_paginate,
.dark-only #column-selector_wrapper .dataTables_paginate,
.dark-only #auto-fill_wrapper .dataTables_paginate,
.dark-only #custom-button_wrapper .dataTables_paginate,
.dark-only #class-button_wrapper .dataTables_paginate,
.dark-only #keyboard-btn_wrapper .dataTables_paginate,
.dark-only #multilevel-btn_wrapper .dataTables_paginate,
.dark-only #pagelength-btn_wrapper .dataTables_paginate,
.dark-only #basic-colreorder_wrapper .dataTables_paginate,
.dark-only #state-saving_wrapper .dataTables_paginate,
.dark-only #real-time_wrapper .dataTables_paginate,
.dark-only #basic-fixed-header_wrapper .dataTables_paginate,
.dark-only #fixed-header-footer_wrapper .dataTables_paginate,
.dark-only #export-button_wrapper .dataTables_paginate,
.dark-only #excel-cust-bolder_wrapper .dataTables_paginate,
.dark-only #cust-json_wrapper .dataTables_paginate,
.dark-only #basic-key-table_wrapper .dataTables_paginate,
.dark-only #focus-cell_wrapper .dataTables_paginate,
.dark-only #responsive_wrapper .dataTables_paginate,
.dark-only #new-cons_wrapper .dataTables_paginate,
.dark-only #show-hidden-row_wrapper .dataTables_paginate,
.dark-only #basic-row-reorder_wrapper .dataTables_paginate,
.dark-only #full-row_wrapper .dataTables_paginate,
.dark-only #rest-column_wrapper .dataTables_paginate {
  border: none;
}
.dark-only #example-style-8_wrapper table.dataTable.stripe tbody tr.odd,
.dark-only #example-style-8_wrapper table.dataTable.display tbody tr.odd {
  background-color: rgb(35, 38, 38);
}
.dark-only .error-wrapper {
  background-color: rgba(54, 64, 74, 0.8);
}
.dark-only .error-wrapper .sub-content {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .b-light {
  border: 1px solid rgb(35, 38, 38) !important;
}
.dark-only .modal-content {
  background-color: #262932;
}
.dark-only .modal-content .modal-header {
  border-bottom: 1px solid #374558;
}
.dark-only .modal-content .modal-header .close {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}
.dark-only .modal-content .ui-front .form-control {
  background-color: #262932;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid #374558;
}
.dark-only .stepwizard .stepwizard-row:before {
  background-color: #374558;
}
.dark-only .modal .theme-close {
  background-color: #262932 !important;
  color: rgba(255, 255, 255, 0.2);
}
.dark-only .token.atrule,
.dark-only .token.attr-value,
.dark-only .token.keyword {
  color: #a927f9;
}
.dark-only .token.boolean,
.dark-only .token.constant,
.dark-only .token.deleted,
.dark-only .token.number,
.dark-only .token.property,
.dark-only .token.symbol,
.dark-only .token.tag {
  color: #dc3545;
}
.dark-only .loader-box.card-loader {
  background-color: #262932;
}
.dark-only .my-gallery.gallery-with-description img {
  border: 1px solid rgb(35, 38, 38) !important;
  border-bottom: none !important;
}
.dark-only .alert-primary.inverse {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .alert-secondary.inverse {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .alert-success.inverse {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .alert-danger.inverse {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .alert-warning.inverse {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .alert-info.inverse {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .alert-light.inverse {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .alert-dark.inverse {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .alert-theme span + span + span {
  border-left: 5px solid var(--blue);
  background-color: rgb(35, 38, 38);
  border-radius: 4px;
}
.dark-only .alert-theme i {
  color: #fff;
  padding: 20px;
  margin-right: 20px !important;
}
.dark-only .user-card .user-deatils h6 {
  color: #98a6ad;
}
.dark-only .user-card .card-footer > div:first-child {
  border-right: 1px solid rgb(35, 38, 38);
}
.dark-only .user-card .card-footer .user-footer h6 {
  color: #98a6ad;
}
.dark-only .user-card .card-footer .user-footer svg path,
.dark-only .user-card .card-footer .user-footer svg rect {
  color: #98a6ad;
}
.dark-only .order-history table th {
  background-color: rgb(35, 38, 38);
  border: 1px solid #374558;
}
.dark-only .order-history table tr td {
  border-top: none !important;
}
.dark-only .order-history .title-orders {
  background-color: rgb(35, 38, 38);
}
.dark-only .navigation-option ul li a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .navigation-option ul li:hover {
  background-color: rgb(35, 38, 38);
}
.dark-only .product-box .product-details h6 {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .product-box .product-details span {
  color: #98a6ad;
}
.dark-only .product-box .product-img .product-hover ul li {
  background-color: #262932;
}
.dark-only .product-box .product-img .product-hover ul li:hover {
  background-color: #000;
  color: #fff;
}
.dark-only .browse .browse-articles ul li a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .browse .browse-articles ul li:hover {
  background-color: rgb(35, 38, 38);
}
.dark-only .radio-primary input[type="radio"] + label::before {
  border-color: var(--blue) !important;
}
.dark-only .radio-primary input[type="radio"] + label::after {
  background-color: var(--blue);
}
.dark-only .radio-primary input[type="radio"]:checked + label::before {
  border-color: var(--blue) !important;
}
.dark-only .radio-primary input[type="radio"]:checked + label::after {
  background-color: var(--blue);
}
.dark-only .radio-secondary input[type="radio"] + label::before {
  border-color: #f73164 !important;
}
.dark-only .radio-secondary input[type="radio"] + label::after {
  background-color: #f73164;
}
.dark-only .radio-secondary input[type="radio"]:checked + label::before {
  border-color: #f73164 !important;
}
.dark-only .radio-secondary input[type="radio"]:checked + label::after {
  background-color: #f73164;
}
.dark-only .radio-success input[type="radio"] + label::before {
  border-color: #51bb25 !important;
}
.dark-only .radio-success input[type="radio"] + label::after {
  background-color: #51bb25;
}
.dark-only .radio-success input[type="radio"]:checked + label::before {
  border-color: #51bb25 !important;
}
.dark-only .radio-success input[type="radio"]:checked + label::after {
  background-color: #51bb25;
}
.dark-only .radio-danger input[type="radio"] + label::before {
  border-color: #dc3545 !important;
}
.dark-only .radio-danger input[type="radio"] + label::after {
  background-color: #dc3545;
}
.dark-only .radio-danger input[type="radio"]:checked + label::before {
  border-color: #dc3545 !important;
}
.dark-only .radio-danger input[type="radio"]:checked + label::after {
  background-color: #dc3545;
}
.dark-only .radio-info input[type="radio"] + label::before {
  border-color: #a927f9 !important;
}
.dark-only .radio-info input[type="radio"] + label::after {
  background-color: #a927f9;
}
.dark-only .radio-info input[type="radio"]:checked + label::before {
  border-color: #a927f9 !important;
}
.dark-only .radio-info input[type="radio"]:checked + label::after {
  background-color: #a927f9;
}
.dark-only .radio-light input[type="radio"] + label::before {
  border-color: #f4f4f4 !important;
}
.dark-only .radio-light input[type="radio"] + label::after {
  background-color: #f4f4f4;
}
.dark-only .radio-light input[type="radio"]:checked + label::before {
  border-color: #f4f4f4 !important;
}
.dark-only .radio-light input[type="radio"]:checked + label::after {
  background-color: #f4f4f4;
}
.dark-only .radio-dark input[type="radio"] + label::before {
  border-color: #2c323f !important;
}
.dark-only .radio-dark input[type="radio"] + label::after {
  background-color: #2c323f;
}
.dark-only .radio-dark input[type="radio"]:checked + label::before {
  border-color: #2c323f !important;
}
.dark-only .radio-dark input[type="radio"]:checked + label::after {
  background-color: #2c323f;
}
.dark-only .radio-warning input[type="radio"] + label::before {
  border-color: #f8d62b !important;
}
.dark-only .radio-warning input[type="radio"] + label::after {
  background-color: #f8d62b;
}
.dark-only .radio-warning input[type="radio"]:checked + label::before {
  border-color: #f8d62b !important;
}
.dark-only .radio-warning input[type="radio"]:checked + label::after {
  background-color: #f8d62b;
}
.dark-only .checkbox-primary label::before {
  border-color: var(--blue) !important;
}
.dark-only .checkbox-secondary label::before {
  border-color: #f73164 !important;
}
.dark-only .checkbox-success label::before {
  border-color: #51bb25 !important;
}
.dark-only .checkbox-danger label::before {
  border-color: #dc3545 !important;
}
.dark-only .checkbox-info label::before {
  border-color: #a927f9 !important;
}
.dark-only .checkbox-light label::before {
  border-color: #f4f4f4 !important;
}
.dark-only .checkbox-dark label::before {
  border-color: #2c323f !important;
}
.dark-only .checkbox-warning label::before {
  border-color: #f8d62b !important;
}
.dark-only .checkbox-solid-primary label:before {
  background-color: var(--blue) !important;
  border-color: var(--blue) !important;
  color: #fff !important;
}
.dark-only
  .checkbox-solid-primary
  input[type="checkbox"]:checked
  + label::before {
  background-color: var(--blue) !important;
  border-color: var(--blue) !important;
  color: #fff !important;
}
.dark-only
  .checkbox-solid-primary
  input[type="checkbox"]:checked
  + label::after {
  color: #fff !important;
}
.dark-only .checkbox-solid-secondary label:before {
  background-color: #f73164 !important;
  border-color: #f73164 !important;
  color: #fff !important;
}
.dark-only
  .checkbox-solid-secondary
  input[type="checkbox"]:checked
  + label::before {
  background-color: #f73164 !important;
  border-color: #f73164 !important;
  color: #fff !important;
}
.dark-only
  .checkbox-solid-secondary
  input[type="checkbox"]:checked
  + label::after {
  color: #fff !important;
}
.dark-only .checkbox-solid-success label:before {
  background-color: #51bb25 !important;
  border-color: #51bb25 !important;
  color: #fff !important;
}
.dark-only
  .checkbox-solid-success
  input[type="checkbox"]:checked
  + label::before {
  background-color: #51bb25 !important;
  border-color: #51bb25 !important;
  color: #fff !important;
}
.dark-only
  .checkbox-solid-success
  input[type="checkbox"]:checked
  + label::after {
  color: #fff !important;
}
.dark-only .checkbox-solid-danger label:before {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
  color: #fff !important;
}
.dark-only
  .checkbox-solid-danger
  input[type="checkbox"]:checked
  + label::before {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
  color: #fff !important;
}
.dark-only
  .checkbox-solid-danger
  input[type="checkbox"]:checked
  + label::after {
  color: #fff !important;
}
.dark-only .checkbox-solid-info label:before {
  background-color: #a927f9 !important;
  border-color: #a927f9 !important;
  color: #fff !important;
}
.dark-only .checkbox-solid-info input[type="checkbox"]:checked + label::before {
  background-color: #a927f9 !important;
  border-color: #a927f9 !important;
  color: #fff !important;
}
.dark-only .checkbox-solid-info input[type="checkbox"]:checked + label::after {
  color: #fff !important;
}
.dark-only .checkbox-solid-light label:before {
  background-color: #f4f4f4 !important;
  border-color: #f4f4f4 !important;
  color: #fff !important;
}
.dark-only
  .checkbox-solid-light
  input[type="checkbox"]:checked
  + label::before {
  background-color: #f4f4f4 !important;
  border-color: #f4f4f4 !important;
  color: #fff !important;
}
.dark-only .checkbox-solid-light input[type="checkbox"]:checked + label::after {
  color: #fff !important;
}
.dark-only .checkbox-solid-dark label:before {
  background-color: #2c323f !important;
  border-color: #2c323f !important;
  color: #fff !important;
}
.dark-only .checkbox-solid-dark input[type="checkbox"]:checked + label::before {
  background-color: #2c323f !important;
  border-color: #2c323f !important;
  color: #fff !important;
}
.dark-only .checkbox-solid-dark input[type="checkbox"]:checked + label::after {
  color: #fff !important;
}
.dark-only .checkbox-solid-warning label:before {
  background-color: #f8d62b !important;
  border-color: #f8d62b !important;
  color: #fff !important;
}
.dark-only
  .checkbox-solid-warning
  input[type="checkbox"]:checked
  + label::before {
  background-color: #f8d62b !important;
  border-color: #f8d62b !important;
  color: #fff !important;
}
.dark-only
  .checkbox-solid-warning
  input[type="checkbox"]:checked
  + label::after {
  color: #fff !important;
}
.dark-only .bootstrap-datetimepicker-widget ul li {
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid rgb(35, 38, 38);
}
.dark-only .bootstrap-datetimepicker-widget tr th:hover,
.dark-only .bootstrap-datetimepicker-widget tr td:hover {
  background-color: #262932;
}
.dark-only .bootstrap-datetimepicker-widget tr th span:hover,
.dark-only .bootstrap-datetimepicker-widget tr td span:hover {
  background-color: #262932;
}
.dark-only .bootstrap-datetimepicker-widget tr th.prev:hover,
.dark-only .bootstrap-datetimepicker-widget tr td.prev:hover,
.dark-only .bootstrap-datetimepicker-widget tr th.next:hover,
.dark-only .bootstrap-datetimepicker-widget tr td.next:hover {
  background-color: #262932;
}
.dark-only .bootstrap-datetimepicker-widget tr:first-child th:hover {
  background-color: #262932;
}
.dark-only .btn-transparent {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only
  #cal-basic
  .fc-toolbar
  .fc-left
  .fc-next-button
  .fc-icon-right-single-arrow:after,
.dark-only
  #cal-basic
  .fc-toolbar
  .fc-right
  .fc-next-button
  .fc-icon-right-single-arrow:after {
  color: #98a6ad;
}
.dark-only
  #cal-basic
  .fc-toolbar
  .fc-left
  .fc-prev-button
  .fc-icon-left-single-arrow:after,
.dark-only
  #cal-basic
  .fc-toolbar
  .fc-right
  .fc-prev-button
  .fc-icon-left-single-arrow:after {
  color: #98a6ad;
}
.dark-only #cal-basic .fc-toolbar .fc-left .fc-button-group .fc-month-button {
  color: #fff !important;
}
.dark-only .fc-button-group .fc-basicWeek-button,
.dark-only .fc-button-group .fc-basicDay-button,
.dark-only .fc-button-group .fc-agendaWeek-button,
.dark-only .fc-button-group .fc-agendaDay-button {
  color: #000 !important;
}
.dark-only .fc-button-group .fc-month-button {
  color: #fff !important;
}
.dark-only
  #cal-agenda-view
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-month-button,
.dark-only #cal-agenda-view .fc-toolbar .fc-right .fc-button-group .btn-light {
  color: #000 !important;
}
.dark-only
  #cal-agenda-view
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-agendaWeek-button {
  color: #fff !important;
}
.dark-only
  #cal-agenda-view
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-agendaWeek-button:hover {
  color: #000 !important;
}
.dark-only
  .basic-calendar
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-basicWeek-button,
.dark-only
  #cal-bg-events
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-basicWeek-button,
.dark-only
  #cal-event-colors
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-basicWeek-button,
.dark-only .basic-calendar .fc-toolbar .fc-right .fc-button-group .btn-light,
.dark-only #cal-bg-events .fc-toolbar .fc-right .fc-button-group .btn-light,
.dark-only #cal-event-colors .fc-toolbar .fc-right .fc-button-group .btn-light {
  color: #000 !important;
}
.dark-only
  .basic-calendar
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-month-button,
.dark-only
  #cal-bg-events
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-month-button,
.dark-only
  #cal-event-colors
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-month-button {
  color: #fff !important;
}
.dark-only
  .basic-calendar
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-month-button:hover,
.dark-only
  #cal-bg-events
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-month-button:hover,
.dark-only
  #cal-event-colors
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-month-button:hover {
  color: #000 !important;
}
.dark-only .categories ul li a {
  color: #98a6ad;
}
.dark-only .categories ul li:hover {
  background-color: rgb(35, 38, 38);
}
.dark-only .main .langChoice {
  background: rgb(35, 38, 38);
  color: #fff;
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .socialprofile .likes-profile h5 span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-only .socialprofile span {
  color: #98a6ad;
}
.dark-only .social-status .media .media-body span + span {
  color: #98a6ad;
}
.dark-only .social-status .media .media-body p,
.dark-only .social-status .media .media-body .light-span {
  color: #98a6ad;
}
.dark-only .filter-cards-view p,
.dark-only .timeline-content p {
  color: #98a6ad;
}
.dark-only .filter-cards-view .comment-number i,
.dark-only .timeline-content .comment-number i {
  color: #98a6ad;
}
.dark-only .filter-cards-view .comments-box .input-group .form-control,
.dark-only .timeline-content .comments-box .input-group .form-control {
  border: none !important;
}
.dark-only .filter-cards-view .comments-box .input-group .input-group-append,
.dark-only .timeline-content .comments-box .input-group .input-group-append {
  background-color: rgb(35, 38, 38);
}
.dark-only .social-chat .media-body {
  border: 1px solid rgb(35, 38, 38);
}
.dark-only .social-chat .media-body:after {
  border-right: 7px solid #262932;
}
.dark-only .social-chat span span {
  color: #98a6ad;
}
.dark-only .details-about .your-details p {
  color: #98a6ad;
}
.dark-only .activity-log .my-activity p {
  color: #98a6ad;
}
.dark-only .bookmark ul li a {
  color: rgba(255, 255, 255, 0.7);
}
@media screen and (max-width: 1366px) {
  .dark-only .earning-card.card .card-body .earning-content {
    border-bottom: 1px solid #374558;
  }
}
@media (max-width: 991.98px) {
  .dark-only
    .page-wrapper.compact-wrapper
    .page-header
    .header-wrapper
    .toggle-sidebar {
    border-right: 1px solid #374558;
  }
  .dark-only .page-wrapper.compact-wrapper .left-header .mega-menu svg,
  .dark-only .page-wrapper.compact-sidebar .left-header .mega-menu svg {
    stroke: rgba(255, 255, 255, 0.7);
  }
}
@media (max-width: 767.98px) {
  .dark-only .page-wrapper.compact-wrapper .left-header .mega-menu svg,
  .dark-only .page-wrapper.compact-sidebar .left-header .mega-menu svg {
    stroke: #fff;
  }
  .dark-only .page-wrapper.compact-wrapper .left-header .mega-menu .nav-link,
  .dark-only .page-wrapper.compact-sidebar .left-header .mega-menu .nav-link {
    border-left: 1px solid #374558;
  }
  .dark-only .page-wrapper.material-icon .page-header {
    background-color: #262932;
  }
  .dark-only .chart_data_left .card-body .chart-main > div:first-child {
    border-right: 1px solid #374558;
  }
  .dark-only .chart_data_left .card-body .chart-main > div:nth-child(3) {
    border-right: 1px solid #374558;
  }
}
.dark-sidebar .toggle-sidebar svg {
  stroke: #fff;
}
.dark-sidebar .page-main-header .logo-wrapper .for-light {
  display: block;
}
.dark-sidebar .page-main-header .logo-wrapper .for-dark {
  display: none;
}
.dark-sidebar .page-wrapper .sidebar-main-title p {
  color: rgba(255, 255, 255, 0.7);
}
.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper {
  background-color: #262932;
}
.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a
  span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a
  svg {
  stroke: rgba(255, 255, 255, 0.7);
}
.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5 {
  color: rgba(255, 255, 255, 0.7);
}
.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content.opensubmegamenu
  li
  a {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}
.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container::after {
  background-color: rgba(255, 255, 255, 0.2);
}
.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link.active
  svg {
  stroke: var(--blue);
}
.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #262932;
}
.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  ul.sidebar-submenu
  li
  a
  span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  ul.sidebar-submenu
  li
  a.active {
  color: var(--blue);
}
.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  .according-menu
  i {
  color: rgba(255, 255, 255, 0.7);
}
.dark-sidebar
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper {
  background: #262932;
}
.dark-sidebar
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main {
  background-color: #262932;
}
.dark-sidebar
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  > a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-sidebar
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu {
  background: #262932;
}
.dark-sidebar
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-sidebar
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a.active,
.dark-sidebar
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a:hover {
  color: var(--blue);
}
.dark-sidebar
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  .nav-sub-childmenu {
  background: #262932;
}
.dark-sidebar
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container {
  background: #262932;
}
.dark-sidebar
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-sidebar
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .left-arrow,
.dark-sidebar
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .right-arrow {
  background-color: #262932;
  color: rgba(255, 255, 255, 0.7);
}
.dark-sidebar
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper {
  background-color: #262932;
}
.dark-sidebar
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  > div {
  background: #262932;
}
.dark-sidebar
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-title {
  border-bottom: 1px solid #374558;
}
.dark-sidebar
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a
  span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-sidebar
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a
  svg {
  stroke: rgba(255, 255, 255, 0.7);
}
.dark-sidebar
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-submenu,
.dark-sidebar
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .mega-menu-container {
  background-color: #262932;
}
.dark-sidebar
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-submenu
  li
  a,
.dark-sidebar
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .mega-menu-container
  li
  a {
  border-bottom: 1px solid #374558;
}
.dark-sidebar
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link.active {
  background-color: rgba(115, 102, 255, 0.2);
}
.dark-sidebar
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link.active
  span {
  color: var(--blue);
}
.dark-sidebar
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link.active
  svg {
  color: var(--blue);
  stroke: var(--blue);
}
.dark-sidebar
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5 {
  color: rgba(255, 255, 255, 0.7);
}
.dark-sidebar
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content.opensubmegamenu
  li
  a {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}
.dark-sidebar
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container::after {
  background-color: rgba(255, 255, 255, 0.2);
}
.dark-sidebar
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}
.dark-sidebar
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  ul.sidebar-submenu
  li
  a
  span {
  color: rgba(255, 255, 255, 0.7);
}
.dark-sidebar
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  ul.sidebar-submenu
  li
  a.active {
  color: var(--blue);
}
.dark-sidebar
  .page-wrapper.compact-sidebar
  .page-body-wrapper
  .according-menu
  i {
  color: rgba(255, 255, 255, 0.7);
}
body .for-dark {
  display: none;
}
body[class*="dark-"] .for-dark {
  display: block;
}
body[class*="dark-"] .for-light {
  display: none;
}
/**===================== 55. theme layout CSS ends ==========================**/
/**===================== 53. Dashboard CSS Start ==========================**/
.texts-light {
  color: rgba(43, 43, 43, 0.7);
}
.form-control-plaintext {
  width: auto !important;
}
.badge-light-primary {
  background-color: #fff;
  color: #7366ff;
}
.badge-light-secondary {
  background-color: #feecf0;
  color: #f73164;
}
.badge-light-success {
  background-color: #c4f0b2;
  color: #51bb25;
}
.badge-light-danger {
  background-color: #f9dadd;
  color: #dc3545;
}
.badge-light-info {
  background-color: #f4e4fe;
  color: #a927f9;
}
.badge-light-light {
  background-color: #fff;
  color: #f4f4f4;
}
.badge-light-dark {
  background-color: #8490a9;
  color: #2c323f;
}
.badge-light-warning {
  background-color: #fefae7;
  color: #f8d62b;
}
.badge-light-primary {
  background-color: rgba(115, 102, 255, 0.1);
}
.badge-light-success {
  background-color: rgba(81, 187, 37, 0.2);
}
.table thead th {
  border-bottom: 2px solid #ecf3fa;
}
.profile-greeting {
  /* background-image: url(../../images/dashboard/bg.jpg); */
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}
.profile-greeting .card-body {
  padding: 40px 20px;
}
.profile-greeting .badge-groups .badge.f-12 svg {
  width: 16px;
  height: 16px;
}
.profile-greeting .media .media-body p {
  font-weight: 500;
  font-size: 10px;
}
.profile-greeting .media .badge-groups {
  display: flex;
  justify-content: space-between;
}
.profile-greeting .media .badge-groups .badge {
  color: #fff !important;
  padding: 10px 14px !important;
}
.profile-greeting .media .badge-groups .badge:first-child {
  display: flex;
  align-items: center;
  background: linear-gradient(
    90deg,
    transparent 28%,
    rgba(255, 255, 255, 0.1) 100%
  );
  border-radius: 5px;
}
.profile-greeting .media .badge-groups .badge:last-child {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1) 20%,
    transparent 100%
  );
  border-radius: 5px;
  width: 56px;
  text-align: left;
}
.profile-greeting .greeting-user {
  margin: 42px 0;
}
.profile-greeting .greeting-user h4 {
  margin-top: 30px;
  margin-bottom: 20px;
  color: #fff;
}
.profile-greeting .greeting-user p {
  font-weight: 500;
  padding: 0 50px;
  margin-bottom: 25px;
}
.profile-greeting .greeting-user p span {
  color: rgba(255, 255, 255, 0.8);
}
.profile-greeting .greeting-user .profile-vector {
  filter: drop-shadow(15px 17px 12px rgba(0, 0, 0, 0.1));
}
.profile-greeting .greeting-user .right-circle i {
  padding: 5px 6px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
}
.profile-greeting .greeting-user .left-icon {
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px 20px 20px 10px;
  position: absolute;
  left: 4%;
  bottom: 4%;
}
.profile-greeting .greeting-user .left-icon i {
  color: #fff;
  margin-top: 38%;
  transform: translateY(-50%);
  animation: tada 1.5s ease infinite;
}
.profile-greeting .whatsnew-btn .btn {
  background-color: #fff !important;
  border-radius: 5px;
  color: var(--blue);
  font-weight: 500;
  padding: 6px 18px;
}
.earning-card.card .card-body {
  overflow: hidden;
}
.earning-card.card .card-body .chart-left {
  padding: 40px 0 40px 40px;
}
.earning-card.card .card-body .chart-right {
  padding: 0 40px;
}
.earning-card.card .card-body .chart-right .weekly-data {
  padding-bottom: 40px;
}
.earning-card.card .card-body .chart-right .p-tb {
  padding: 40px 0;
}
.earning-card.card .card-body .left_side_earning {
  margin-bottom: 30px;
}
.earning-card.card .card-body .left_side_earning:last-child {
  margin-bottom: 0;
}
.earning-card.card .card-body .left_side_earning h5 {
  line-height: 36px;
  font-weight: 500;
  margin: 0;
}
.earning-card.card .card-body .left_side_earning p {
  font-size: 14px;
  color: rgba(43, 43, 43, 0.7);
}
.earning-card.card .card-body .left-btn a.btn {
  padding: 10px 16px;
}
.earning-card.card .card-body .inner-top-left ul li,
.earning-card.card .card-body .inner-top-right ul li {
  line-height: 22px;
  color: rgba(43, 43, 43, 0.7);
  font-weight: 500;
  margin-left: 35px;
  letter-spacing: 1px;
}
.earning-card.card .card-body .inner-top-left ul li.active,
.earning-card.card .card-body .inner-top-right ul li.active {
  color: var(--blue);
}
.earning-card.card .card-body .inner-top-left ul li:first-child {
  margin-left: 0;
}
.earning-card.card .card-body .inner-top-right ul li {
  position: relative;
}
.earning-card.card .card-body .inner-top-right ul li:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--blue);
  left: -40%;
  top: 27%;
}
.earning-card.card .card-body .inner-top-right ul li:last-child:before {
  background-color: #dc3545;
}
.earning-card.card .card-body .border-top {
  border-top: 1px solid #ecf3fa !important;
  padding: 40px;
}
.earning-card.card .card-body .earning-content {
  border-right: 1px solid #ecf3fa;
}
.earning-card.card .card-body .media .media-left {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: var(--blue);
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}
.earning-card.card .card-body .media .media-left i {
  font-size: 18px;
  transition: 0.3s all linear;
}
.earning-card.card .card-body .media .media-left:hover {
  animation: tada 1.5s ease infinite;
}
.earning-card.card .card-body .media .media-body h6 {
  margin-bottom: 2px;
}
.earning-card.card .card-body .media .media-body p {
  font-size: 14px;
  color: rgba(43, 43, 43, 0.7);
}
.chart_data_left .card-body .chart-main .media,
.chart_data_right .card-body .chart-main .media {
  padding: 45px 40px;
  border-right: 1px solid #ecf3fa;
}
.chart_data_left .card-body .chart-main .media .hospital-small-chart,
.chart_data_right .card-body .chart-main .media .hospital-small-chart {
  align-items: center;
  margin-bottom: -25px;
}
.chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar {
  position: relative;
}
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container {
  height: 74px;
  width: 75px;
}
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  .chartist-tooltip,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  .chartist-tooltip {
  position: absolute;
  opacity: 0;
}
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  .chartist-tooltip
  .chartist-tooltip-value,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  .chartist-tooltip
  .chartist-tooltip-value {
  font-size: 10px;
  padding: 5px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  .chartist-tooltip.tooltip-show,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  .chartist-tooltip.tooltip-show {
  opacity: 1;
}
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  svg,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  svg {
  position: absolute;
  bottom: 23px;
  width: 100%;
  height: 95%;
}
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-slice-donut,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-slice-donut {
  stroke: var(--blue);
}
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-slice-donut,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-slice-donut {
  stroke: rgba(115, 102, 255, 0.1);
}
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  .chartist-tooltip
  .chartist-tooltip-value,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  .chartist-tooltip
  .chartist-tooltip-value,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  .chartist-tooltip
  .chartist-tooltip-value,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  .chartist-tooltip
  .chartist-tooltip-value {
  background-color: var(--blue);
  box-shadow: 1px 1.732px 8px 0px rgba(115, 102, 255, 0.5);
}
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  .chartist-tooltip
  .chartist-tooltip-value,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  .chartist-tooltip
  .chartist-tooltip-value,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  .chartist-tooltip
  .chartist-tooltip-value,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  .chartist-tooltip
  .chartist-tooltip-value {
  background-color: var(--theme-secondary);
  box-shadow: 1px 1.732px 8px 0px rgba(247, 49, 100, 0.5);
}
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-slice-donut,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-slice-donut {
  stroke: var(--theme-secondary);
}
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-slice-donut,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-slice-donut {
  stroke: rgba(247, 49, 100, 0.1);
}
.chart_data_left .card-body .chart-main .media .media-body .right-chart-content,
.chart_data_right
  .card-body
  .chart-main
  .media
  .media-body
  .right-chart-content {
  margin-left: 30px;
}
.chart_data_left
  .card-body
  .chart-main
  .media
  .media-body
  .right-chart-content
  h4,
.chart_data_right
  .card-body
  .chart-main
  .media
  .media-body
  .right-chart-content
  h4 {
  line-height: 1;
  font-weight: 500;
}
.chart_data_left
  .card-body
  .chart-main
  .media
  .media-body
  .right-chart-content
  span,
.chart_data_right
  .card-body
  .chart-main
  .media
  .media-body
  .right-chart-content
  span {
  color: rgba(43, 43, 43, 0.7);
}
.chart_data_left .card-body .chart-main .media.border-none,
.chart_data_right .card-body .chart-main .media.border-none {
  border-right: none;
}
.chart_data_right .card-body .media .right-chart-content {
  margin-left: 0 !important;
}
.chart_data_right .card-body .media .right-chart-content h4 {
  display: flex;
  align-items: center;
}
.chart_data_right .card-body .media .right-chart-content h4 .new-box {
  padding: 3px 5px;
  background-color: var(--blue);
  font-size: 10px;
  color: #fff;
  margin-left: 10px;
}
.chart_data_right .card-body .media .right-chart-content span {
  color: rgba(43, 43, 43, 0.7);
}
.chart_data_right .card-body .knob-block {
  margin-bottom: -5px;
  margin-top: -17px;
}
.chart_data_right .card-body .knob-block > div,
.chart_data_right .card-body .knob-block input {
  height: 40% !important;
  width: 40% !important;
  left: 50% !important;
  top: 2% !important;
  transform: translate(-50%) !important;
  margin-left: 0 !important;
  margin-top: 17px !important;
  color: #242934 !important;
}
.chart_data_right .card-body .knob-block canvas {
  width: 65px !important;
  height: 65px !important;
  position: relative !important;
}
.chart_data_right .card-body .knob-block .chart-overflow {
  overflow: hidden;
  text-align: center;
}
.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.third-news-update span,
.news span,
.appointment-sec span {
  font-weight: 500;
}
.third-news-update .card-header-right-icon select,
.news .card-header-right-icon select,
.appointment-sec .card-header-right-icon select {
  background-color: rgba(115, 102, 255, 0.08) !important;
  border: none;
  color: var(--blue) !important;
  width: auto !important;
  height: 32px !important;
  border-radius: 10px;
  padding: 5px 10px;
  line-height: 50px !important;
  font-weight: 500;
  border: none;
  outline: none !important;
}
.third-news-update .card-header-right-icon select:hover,
.news .card-header-right-icon select:hover,
.appointment-sec .card-header-right-icon select:hover {
  background-color: rgba(115, 102, 255, 0.08) !important;
  color: var(--blue) !important;
}
.third-news-update .news-update,
.news .news-update,
.appointment-sec .news-update {
  padding: 30px 40px;
  border-bottom: 1px solid #ecf3fa;
}
.third-news-update .news-update span,
.news .news-update span,
.appointment-sec .news-update span {
  color: rgba(43, 43, 43, 0.7);
  font-family: Roboto, sans-serif;
  font-weight: 400;
}
.third-news-update .news-update:last-child,
.news .news-update:last-child,
.appointment-sec .news-update:last-child {
  border: none !important;
}
.third-news-update .bottom-btn,
.news .bottom-btn,
.appointment-sec .bottom-btn {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
.third-news-update .news .card .card-footer,
.news .news .card .card-footer,
.appointment-sec .news .card .card-footer {
  padding-top: 0;
  border-top: none !important;
}
.third-news-update .appointment .radar-chart .apexcharts-datalabel,
.news .appointment .radar-chart .apexcharts-datalabel,
.appointment-sec .appointment .radar-chart .apexcharts-datalabel {
  font-size: 13px;
  font-weight: 600;
  fill: #2b2b2b;
}
.third-news-update .alert-sec .card.bg-img,
.news .alert-sec .card.bg-img,
.appointment-sec .alert-sec .card.bg-img {
  /* background-image: url(../../images/dashboard/bg.jpg); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.third-news-update .alert-sec .card .card-header,
.news .alert-sec .card .card-header,
.appointment-sec .alert-sec .card .card-header {
  background-color: transparent;
  border-bottom: none !important;
  padding-bottom: 0;
}
.third-news-update .alert-sec .card .card-header .header-top h5,
.news .alert-sec .card .card-header .header-top h5,
.appointment-sec .alert-sec .card .card-header .header-top h5 {
  color: #fff;
}
.third-news-update .alert-sec .card .card-header .header-top p,
.news .alert-sec .card .card-header .header-top p,
.appointment-sec .alert-sec .card .card-header .header-top p {
  opacity: 35%;
  color: #fff;
}
.third-news-update .alert-sec .card .card-header .header-top .dot-right-icon,
.news .alert-sec .card .card-header .header-top .dot-right-icon,
.appointment-sec .alert-sec .card .card-header .header-top .dot-right-icon {
  color: #fff;
  width: 50px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.third-news-update .alert-sec .card .card-body .body-bottom h6,
.news .alert-sec .card .card-body .body-bottom h6,
.appointment-sec .alert-sec .card .card-body .body-bottom h6 {
  color: #fff;
  margin-bottom: 8px;
}
.third-news-update .alert-sec .card .card-body .body-bottom span,
.news .alert-sec .card .card-body .body-bottom span,
.appointment-sec .alert-sec .card .card-body .body-bottom span {
  color: rgba(255, 255, 255, 0.8);
}
.notification .card .media {
  margin-bottom: 34px;
}
.notification .card .media .media-body p {
  color: var(--theme-secondary);
}
.notification .card .media .media-body p span {
  color: #2b2b2b;
  font-weight: 400;
}
.notification .card .media .media-body p .badge {
  color: #fff;
  margin-left: 10px;
  font-weight: 500;
}
.notification .card .media .media-body h6 {
  margin-top: 16px;
  margin-bottom: 5px;
  position: relative;
}
.notification .card .media .media-body h6 .dot-notification {
  float: right;
  width: 15px;
  height: 15px;
  background-color: var(--theme-secondary);
  border: 5px solid #fdc4d3;
  border-radius: 50%;
  animation: round 1.3s ease-in-out infinite;
}
@keyframes round {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.notification .card .media .media-body span {
  color: rgba(43, 43, 43, 0.7);
}
.notification .card .media .media-body .inner-img {
  width: 110px;
  height: 72px;
  background-color: #fff;
  border-radius: 5px;
}
.notification .card .media .media-body .inner-img:first-child {
  margin-right: 20px;
}
.notification .card .media .media-body .inner-img img {
  border-radius: 5px;
  transition: 0.3s all linear;
}
.notification .card .media .media-body .inner-img:hover img {
  animation: tada 1.5s ease-out infinite;
  transition: 0.3s all linear;
}
.notification .card .media:nth-child(2) .media-body h6 .dot-notification {
  background-color: #f8d62b;
  border: 5px solid #fdf3bf;
}
.notification .card .media:last-child {
  margin-bottom: 0 !important;
}
.appointment-table table tr td {
  position: relative;
  border-top: none;
  padding: 0;
}
.appointment-table table tr td .status-circle {
  top: 1px;
  left: 35px;
  border-width: 3px;
}
.appointment-table table tr td p,
.appointment-table table tr td .btn {
  font-weight: 500;
  font-size: 12px;
}
.appointment-table table tr td .btn {
  padding: 6px 15px;
}
.appointment-table table tr td.img-content-box .font-roboto {
  color: rgba(43, 43, 43, 0.7);
}
.chat-default .media-dashboard {
  align-items: center;
}
.chat-default .media-dashboard .icon-box {
  width: 50px;
  height: 32px;
  background-color: rgba(115, 102, 255, 0.08);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-default .media-dashboard .icon-box i {
  color: var(--blue);
  font-size: 22px;
}
.chat-default .card-body {
  padding-top: 0;
}
.chat-default .card-body.chat-box .left-side-chat .media-body .img-profile {
  width: 40px;
  height: 40px;
}
.chat-default .card-body.chat-box .left-side-chat .media-body .img-profile img {
  border-radius: 100%;
}
.chat-default .card-body.chat-box .left-side-chat .media-body .main-chat {
  margin-left: 15px;
}
.chat-default
  .card-body.chat-box
  .left-side-chat
  .media-body
  .main-chat
  .message-main
  span {
  background-color: var(--blue);
  border-radius: 30px;
  border-bottom-left-radius: 0;
  color: #fff;
}
.chat-default
  .card-body.chat-box
  .left-side-chat
  .media-body
  .main-chat
  .sub-message {
  margin-top: 10px;
}
.chat-default
  .card-body.chat-box
  .left-side-chat
  .media-body
  .main-chat
  .sub-message
  span {
  border-bottom-left-radius: 30px;
  border-top-left-radius: 0;
}
.chat-default
  .card-body.chat-box
  .left-side-chat
  .media-body
  .main-chat:last-child
  .sub-message
  span {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 0;
}
.chat-default .card-body.chat-box .right-side-chat {
  margin: 15px 0;
}
.chat-default
  .card-body.chat-box
  .right-side-chat
  .media-body
  .message-main
  span {
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(25, 124, 207, 0.1);
  border-radius: 30px;
  color: var(--blue);
  opacity: 100%;
  border-bottom-right-radius: 0;
  text-align: center;
}
.chat-default
  .card-body.chat-box
  .right-side-chat
  .media-body
  .message-main
  span.loader-span
  .dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 4px;
  background: var(--blue);
  animation: wave 1.3s linear infinite;
  padding: 0;
}
.chat-default
  .card-body.chat-box
  .right-side-chat
  .media-body
  .message-main
  span.loader-span
  .dot:nth-child(2) {
  animation-delay: -1.1s;
}
.chat-default
  .card-body.chat-box
  .right-side-chat
  .media-body
  .message-main
  span.loader-span
  .dot:nth-child(3) {
  animation-delay: -0.9s;
}
@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }
  30% {
    transform: translateY(-9px);
  }
}
.chat-default .card-body.chat-box .media .media-body .message-main span {
  display: inline-block;
  padding: 12px 18px;
  font-weight: 400;
}
.chat-default .card-body.chat-box .media p {
  font-size: 10px;
  margin-top: 14px;
  color: var(--blue);
}
.chat-default .card-body.chat-box .input-group {
  margin: 30px 0;
  margin-bottom: 0;
  align-items: center;
}
.chat-default .card-body.chat-box .input-group .form-control {
  border: none;
  border-radius: 0;
  border-left: 1px solid var(--blue);
  font-size: 16px;
}
.chat-default .card-body.chat-box .input-group .form-control::placeholder {
  color: #2b2b2b;
  font-weight: 500;
  font-size: 16px;
}
.chat-default .card-body.chat-box .input-group .form-control:focus {
  box-shadow: none;
}
.chat-default .card-body.chat-box .input-group .send-msg {
  width: 50px;
  height: 45px;
  background-color: rgba(115, 102, 255, 0.1);
  border-radius: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-default .card-body.chat-box .input-group .send-msg svg {
  width: 20px;
  color: var(--blue);
  vertical-align: middle;
  transform: rotate(45deg);
  margin-left: -3px;
}
.date-picker-setting {
  background-color: rgba(115, 102, 255, 0.1);
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}
.date-picker-setting i {
  color: var(--blue);
}
.default-datepicker .react-datepicker__month-container {
  width: 100%;
}
.default-datepicker .react-datepicker {
  box-shadow: none;
  width: 100%;
}
.default-datepicker .react-datepicker .react-datepicker__month {
  margin: 0;
}
.default-datepicker .react-datepicker .react-datepicker__day-name,
.default-datepicker .react-datepicker .react-datepicker__day,
.default-datepicker .react-datepicker .react-datepicker__time-name {
  line-height: 50px !important;
  width: 55px !important;
  font-weight: 500;
  font-size: 16px;
  border-radius: 20px;
  font-family: Rubik, sans-serif;
}
.default-datepicker .react-datepicker .react-datepicker__day-name:focus,
.default-datepicker .react-datepicker .react-datepicker__day:focus,
.default-datepicker .react-datepicker .react-datepicker__time-name:focus {
  outline: none;
}
.default-datepicker .react-datepicker .react-datepicker__current-month,
.default-datepicker .react-datepicker .react-datepicker-time__header,
.default-datepicker .react-datepicker .react-datepicker-year-header {
  font-size: 22px;
  font-weight: 600;
}
.default-datepicker .react-datepicker__navigation {
  top: 17px;
}
@media (max-width: 360px) {
  .footer {
    padding-left: 0;
    padding-right: 0 !important;
  }
  .footer p {
    font-size: 12px;
  }
  .appointment-table table tr td {
    min-width: 102px;
  }
  .appointment-table table tr td:first-child {
    min-width: 63px;
  }
  .appointment-table table tr td:nth-child(2) {
    min-width: 120px;
  }
  .default-datepicker .react-datepicker .react-datepicker__day-name,
  .default-datepicker .react-datepicker .react-datepicker__day,
  .default-datepicker .react-datepicker .react-datepicker__time-name {
    line-height: 30px !important;
    width: 30px !important;
    font-size: 14px;
  }
}
.btn-light-secondary {
  background-color: rgba(0, 0, 0);
  font-size: 12px;
  padding: 6px 12px;
  font-weight: 600;
}
.crm-activity > li + li {
  border-top: 1px solid #efefef;
  margin-top: 15px;
  padding-top: 15px;
}
.crm-activity > li + li h6 {
  font-size: 14px;
}
.crm-activity > li h6 {
  font-size: 14px;
}
.crm-activity span {
  font-size: 40px;
}
.crm-activity ul.dates h6 {
  color: #2b2b2b;
}
.crm-activity ul.dates li {
  color: rgba(43, 43, 43, 0.7);
  font-size: 12px;
  display: inline-block;
  line-height: 1;
}
.crm-activity ul.dates li + li {
  border-left: 1px solid #efefef;
  padding-left: 10px;
  margin-left: 5px;
}
.ecommerce-widget {
  box-shadow: none;
  border: 1px solid #f1f3ff !important;
  border-radius: 5px;
}
.ecommerce-widget .total-num {
  color: #2b2b2b;
  margin: 0;
  letter-spacing: 1px;
}
.ecommerce-widget .total-num span {
  color: #000;
}
.ecommerce-widget .row {
  align-items: center;
}
.ecommerce-widget .progress-showcase {
  margin-top: 30px;
}
.ecommerce-widget span {
  color: rgba(43, 43, 43, 0.7);
  margin: 0;
}
.ecommerce-widget .icon {
  color: #efefef;
  font-size: 40px;
}
.ecommerce-widget .flot-chart-container {
  height: 100px;
  padding: 0;
  margin: 0;
  border: transparent;
}
.ecommerce-widget .morris-default-style {
  display: none !important;
}
.ecommerce-widget svg {
  -webkit-filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.3));
}
.static-top-widget:hover .icon-bg {
  transform: rotate(-5deg) scale(1.1);
  transition: all 0.3s ease;
}
.static-top-widget div.align-self-center svg {
  width: 30px;
  height: 30px;
}
.static-top-widget .media-body {
  align-self: center !important;
  padding-left: 30px;
}
.static-top-widget .media-body .icon-bg {
  position: absolute;
  right: -14px;
  top: 6px;
  opacity: 0.2;
  transition: all 0.3s ease;
  width: 100px;
  height: 100px;
}
.bg-info .media.static-top-widget .align-self-center {
  background-color: #a927f9;
}
.bg-primary .media.static-top-widget .align-self-center {
  background-color: var(--blue);
}
.bg-secondary .media.static-top-widget .align-self-center {
  background-color: var(--theme-secondary);
}
.bg-danger .media.static-top-widget .align-self-center {
  background-color: #dc3545;
}
.widget-joins:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: #efefef;
  left: calc(50% - 1px);
}
.widget-joins:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: #efefef;
  left: 0;
  top: 50%;
}
.widget-joins .media {
  padding: 30px;
  text-align: center;
  align-items: center;
}
.widget-joins .media .details {
  border-left: 1px solid #efefef;
  padding: 1px 0;
}
.widget-joins .media .media-body {
  text-align: left;
}
.widget-joins .media .media-body > span {
  /* color: rgba(43, 43, 43, 0.7); */
  color: var(--light);
}
.widget-joins .media .media-body svg {
  width: 40px;
  height: 40px;
}
.widget-joins .media .media-body h6 {
  font-weight: 600;
}
.widget-joins .media .media-body h6 span {
  color: #000;
}
.redial-social-widget {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  margin: 0 auto;
  background-image: linear-gradient(
      90deg,
      #f4f4f4 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(90deg, #ecf3fa 50%, #f4f4f4 50%, #f4f4f4);
}
.redial-social-widget i {
  background-color: #fff;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.redial-social-widget.radial-bar-70 {
  background-image: linear-gradient(
      342deg,
      var(--blue) 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(270deg, var(--blue) 50%, #f4f4f4 50%, #f4f4f4);
}
.social-widget-card span {
  color: rgba(43, 43, 43, 0.7);
  text-transform: uppercase;
  margin-bottom: 0;
}
.social-widget-card h5 {
  color: rgba(43, 43, 43, 0.7);
  text-transform: uppercase;
  text-align: center;
  padding: 30px 0;
  font-weight: 600;
  margin-bottom: 30px;
}
.browser-widget:hover img {
  /* -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  transition: all 0.3s ease;
  transform: scale(1.1) rotate(5deg); */
}
.browser-widget img {
  /* height: 80px; */
  /* filter: grayscale(100%);
  transform: scale(1) rotate(0deg);
  transition: all 0.3s ease; */
}
.browser-widget .media-body {
  text-align: center;
  column-count: 3;
  column-rule: 1px solid #f4f4f4;
}
.browser-widget .media-body span {
  margin-bottom: 0;
  color: #ffffff;
}
.browser-widget .media-body h4 {
  color: #2b2b2b;
  margin-bottom: 0;
}
/* .browser-widget .media-body h4 span {
  color: #333;
} */
.testimonial
  #owl-carousel-testimonial
  .owl-stage-outer
  .owl-stage
  .owl-item
  .item
  img,
.testimonial
  #owl-carousel-testimonial-rtl
  .owl-stage-outer
  .owl-stage
  .owl-item
  .item
  img {
  border-radius: 100%;
}
.testimonial i {
  font-size: 60px;
  color: #f4f4f4;
}
.testimonial p {
  color: rgba(43, 43, 43, 0.7);
  font-style: italic;
  margin-bottom: 30px;
  margin-top: 30px;
}
.testimonial h5 {
  color: #2b2b2b;
  padding-top: 10px;
}
.testimonial span {
  color: rgba(43, 43, 43, 0.7);
}
.testimonial img {
  margin: 0 auto;
}
.min,
.hour {
  position: absolute;
  width: 24px;
  height: 234px;
  top: 0;
  left: 102px;
  margin-top: -30px;
  margin-left: -25px;
}
.cal-date-widget .datepicker {
  padding: 20px;
  border-radius: 20px;
}
.cal-date-widget .cal-info h2 {
  font-size: 100px;
  color: #ecf3fa;
}
.weather-widget-two {
  position: relative;
  background-color: var(--blue);
  height: 346px;
  border-radius: 20px;
  overflow: hidden;
}
.weather-widget-two svg path.climacon_component-stroke {
  fill: #fff;
}
.weather-widget-two .top-bg-whether {
  position: absolute;
  top: -50px;
  right: -28px;
  opacity: 0.1;
}
.weather-widget-two .top-bg-whether svg {
  width: 200px;
  height: 200px;
}
.weather-widget-two .num {
  font-weight: 600;
}
.weather-widget-two .climacon_component-stroke {
  stroke: #fff;
}
.weather-widget-two .bottom-whetherinfo svg {
  width: 150px;
  height: 150px;
  opacity: 0.1;
  color: #fff;
  position: relative;
  top: 0;
  left: -35px;
}
.weather-widget-two .bottom-whetherinfo .whether-content {
  text-align: right;
  position: relative;
  top: 5px;
  color: #fff;
}
.mobile-clock-widget {
  /* background: url(../../images/other-images/mobile-clock-wallpaper.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 346px;
  border-radius: 20px;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-blend-mode: overlay;
  background-color: #102680;
  position: relative;
}
.mobile-clock-widget .bg-svg {
  position: absolute;
  top: -4px;
  left: -25px;
}
.mobile-clock-widget .bg-svg svg {
  width: 150px;
  height: 150px;
  opacity: 0.08;
}
.mobile-clock-widget .clock {
  padding: 0;
  position: relative;
  list-style: none;
  margin: 0 auto;
  height: 175px;
  width: 175px;
  display: block;
}
.mobile-clock-widget #date {
  margin-top: 30px;
}
.mobile-clock-widget #date {
  margin-top: 30px;
}
.flot-chart-container {
  height: 250px;
}
.calender-widget .cal-img {
  /* background: url(../../images/other-images/calender-bg.png); */
  background-size: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 300px;
}
.calender-widget .cal-date {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  margin-top: -45px;
  border: 1px solid #ecf3fa;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calender-widget .cal-date h5 {
  margin-bottom: 0;
  line-height: 1.5;
  padding: 17px;
  font-weight: 600;
}
.calender-widget .cal-desc h6 {
  padding-bottom: 6px;
}
.calender-widget .cal-desc span {
  line-height: 1.6;
}
.contact-form .theme-form {
  border: 1px solid #f4f4f4;
  padding: 30px;
  border-radius: 4px;
}
.contact-form .theme-form .form-icon {
  width: 53px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #efefef;
  border-radius: 100%;
  font-size: 21px;
  background-color: #fff;
  color: #d7e2e9;
  margin-top: -55px;
  margin-bottom: 15px;
}
.contact-form .theme-form .form-group {
  margin-bottom: 10px;
}
.contact-form .theme-form .form-group label {
  font-weight: 600;
}
.contact-form .btn {
  padding: 10px 30px;
}
.chart-widget-top #chart-widget1,
.chart-widget-top #chart-widget2,
.chart-widget-top #chart-widget3 {
  margin-bottom: -30px;
}
.chart-widget-top #chart-widget1 .apexcharts-xaxistooltip,
.chart-widget-top #chart-widget2 .apexcharts-xaxistooltip,
.chart-widget-top #chart-widget3 .apexcharts-xaxistooltip {
  display: none;
}
.bar-chart-widget .top-content {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.bar-chart-widget .earning-details {
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  letter-spacing: 1px;
}
.bar-chart-widget .earning-details i {
  font-size: 230px;
  position: absolute;
  opacity: 0.1;
  right: -30px;
  top: 0;
}
.bar-chart-widget .earning-details i:hover {
  transform: rotate(-5deg) scale(1.1);
  transition: all 0.3s ease;
}
.bar-chart-widget .num {
  font-weight: 600;
}
.bar-chart-widget .num .color-bottom {
  color: #000;
}
.bottom-content span {
  color: rgba(43, 43, 43, 0.7);
}
.bottom-content .block-bottom {
  display: block;
}
.user-status table tbody tr td {
  vertical-align: middle;
}
.user-status table tbody tr td .d-inline-block {
  margin-top: 11px;
}
.user-status table tbody tr td .image-sm-size img {
  width: 41px;
}
.user-status table tbody tr:last-child td {
  padding-bottom: 0;
}
.user-status table thead tr th {
  border-top: 0;
  font-size: 16px;
  color: #2c323f;
  font-weight: 600;
  padding-top: 0;
}
@keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -30px;
  }
}
@-webkit-keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0;
  }
  100% {
    stroke-opacity: 0;
    stroke-width: 50px;
  }
}
@keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0;
  }
  100% {
    stroke-opacity: 0;
    stroke-width: 50px;
  }
}
@-webkit-keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.serial-chart .chart-container {
  width: 100%;
  height: 500px;
}
.serial-chart .chart-container .lastBullet {
  animation: am-pulsating 1s ease-out infinite;
}
.serial-chart .chart-container .amcharts-graph-column-front {
  transition: all 0.3s 0.3s ease-out;
}
.serial-chart .chart-container .amcharts-graph-column-front:hover {
  fill: var(--theme-secondary);
  stroke: var(--theme-secondary);
  transition: all 0.3s ease-out;
}
.serial-chart .chart-container .amcharts-graph-g3 {
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
  animation: am-draw 40s;
}
.serial-chart .chart-container .amcharts-graph-g2 .amcharts-graph-stroke {
  stroke-dasharray: 3px 3px;
  stroke-linejoin: round;
  stroke-linecap: round;
  animation: am-moving-dashes 1s linear infinite;
}
.speed-chart .chart-container {
  width: 100%;
  height: 530px;
}
.speed-chart .content {
  margin-top: -200px;
  margin-bottom: 20px;
}
.speed-chart .content h4 {
  font-weight: 600;
  padding-top: 2px;
}
.status-widget svg {
  width: 20px;
  height: 20px;
  vertical-align: text-top;
}
.status-widget .card .card-header h5 {
  line-height: 1.38;
}
.status-widget .status-chart {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}
.small-chart-widget .chart-container {
  height: 311px;
  padding: 0;
  margin: 0;
  border: none;
}
.small-chart-widget .card .card-body {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.donut-chart-widget .chart-container {
  height: 300px;
  border: 0;
  margin: 0;
  padding: 0;
}
.status-details h4 {
  font-weight: 600;
}
.status-details h4 span {
  color: inherit;
}
.status-details span {
  color: rgba(43, 43, 43, 0.7);
}
.status-chart .chart-container {
  height: 200px;
  border: 0;
  padding: 0;
  margin: 0;
}
.map-chart .chart-container {
  width: 100%;
  height: 500px;
}
.serial-chart {
  width: 100%;
}
.live-products,
.turnover,
.uses,
.monthly {
  height: 300px;
}
.live-products .ct-series-a .ct-area,
.live-products .ct-series-a .ct-point,
.live-products .ct-series-a .ct-line,
.live-products .ct-series-a .ct-bar,
.live-products .ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.77);
}
.live-products .ct-label {
  fill: #fff;
  color: #fff;
}
.live-products .ct-grid {
  stroke: rgba(255, 255, 255, 0.7);
}
.turnover .ct-series-a .ct-area,
.uses .ct-series-a .ct-area,
.monthly .ct-series-a .ct-area,
.turnover .ct-series-a .ct-point,
.uses .ct-series-a .ct-point,
.monthly .ct-series-a .ct-point,
.turnover .ct-series-a .ct-line,
.uses .ct-series-a .ct-line,
.monthly .ct-series-a .ct-line,
.turnover .ct-series-a .ct-bar,
.uses .ct-series-a .ct-bar,
.monthly .ct-series-a .ct-bar,
.turnover .ct-series-a .ct-slice-donut,
.uses .ct-series-a .ct-slice-donut,
.monthly .ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 1);
  fill: transparent;
}
.turnover .ct-series-b .ct-area,
.uses .ct-series-b .ct-area,
.monthly .ct-series-b .ct-area,
.turnover .ct-series-b .ct-point,
.uses .ct-series-b .ct-point,
.monthly .ct-series-b .ct-point,
.turnover .ct-series-b .ct-line,
.uses .ct-series-b .ct-line,
.monthly .ct-series-b .ct-line,
.turnover .ct-series-b .ct-bar,
.uses .ct-series-b .ct-bar,
.monthly .ct-series-b .ct-bar,
.turnover .ct-series-b .ct-slice-donut,
.uses .ct-series-b .ct-slice-donut,
.monthly .ct-series-b .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.7);
  fill: transparent;
}
.turnover .ct-series-c .ct-area,
.uses .ct-series-c .ct-area,
.monthly .ct-series-c .ct-area,
.turnover .ct-series-c .ct-point,
.uses .ct-series-c .ct-point,
.monthly .ct-series-c .ct-point,
.turnover .ct-series-c .ct-line,
.uses .ct-series-c .ct-line,
.monthly .ct-series-c .ct-line,
.turnover .ct-series-c .ct-bar,
.uses .ct-series-c .ct-bar,
.monthly .ct-series-c .ct-bar,
.turnover .ct-series-c .ct-slice-donut,
.uses .ct-series-c .ct-slice-donut,
.monthly .ct-series-c .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.4);
  fill: transparent;
}
.turnover .ct-label,
.uses .ct-label,
.monthly .ct-label {
  fill: #fff;
  color: #fff;
}
.turnover .ct-grid,
.uses .ct-grid,
.monthly .ct-grid {
  stroke: rgba(255, 255, 255, 0.7);
}
#draggableMultiple .ui-sortable-handle .card {
  cursor: move;
}
.tilt-showcase ul li .line {
  padding-top: 10px;
}
.tilt-showcase .pre-mt {
  margin-top: 5px;
}
.wow-title h5 {
  display: flex;
  align-items: center;
  font-size: 18px;
}
.wow-title h5 .badge {
  -webkit-text-fill-color: #fff;
}
.alert-center {
  display: flex !important;
  align-items: center;
}
.box-shadow-title .sub-title {
  margin: 30px 0;
}
.hovercard .info .social-media ul li {
  padding-top: 3px;
}
.hovercard .cardheader {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.sticky-header-main .card .card-header h5 {
  line-height: 35px;
}
.sticky-header-main .card .card-header h5 a {
  color: #fff;
  -webkit-text-fill-color: #fff;
}
.daterangepicker .ltr tr td.active {
  color: #fff;
}
#scrollable-dropdown-menu .twitter-typeahead .tt-menu .tt-dataset {
  max-height: 100px;
  overflow-y: auto;
}
.current-sale-container .apexcharts-xaxistooltip {
  color: var(--blue);
  background: rgba(115, 102, 255, 0.1);
  border: 1px solid var(--blue);
}
.current-sale-container .apexcharts-xaxistooltip-bottom:before {
  border-bottom-color: var(--blue);
}
.current-sale-container .apexcharts-tooltip.light .apexcharts-tooltip-title {
  background: rgba(115, 102, 255, 0.1);
  color: var(--blue);
}
.card-block .table-responsive .table caption {
  padding-left: 10px;
}
.card-block .table-responsive .table-bordered td {
  vertical-align: middle;
}
.card-block .table-border-radius {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.card-block .default-checkbox-align #checkbox1 {
  margin-right: 10px;
}
.card-block .default-checkbox-align #radio {
  margin-right: 5px;
}
#example-style-3_wrapper #example-style-3 tfoot {
  border-top: 2px solid #efefef;
}
.chart-vertical-center {
  display: flex;
  justify-content: center;
}
.chart-vertical-center #myDoughnutGraph,
.chart-vertical-center #myPolarGraph {
  width: auto !important;
}
.cke_focus {
  padding: 15px;
  margin-top: 13px;
}
#editor .ace_scroller .ace_content .ace_layer .ace_print-margin {
  visibility: hidden !important;
}
.helper-classes {
  padding: 30px;
  margin-bottom: 0;
  white-space: pre-line;
}
.starter-kit-fix .page-body {
  margin-bottom: 52px;
}
.starter-main .card-body p {
  font-size: 14px;
}
.starter-main .card-body ul {
  padding-left: 30px;
  list-style-type: disc;
  margin-bottom: 15px;
}
.starter-main .card-body h5 {
  font-size: 18px;
}
.starter-main .card-body pre {
  white-space: pre-line;
  padding: 30px;
}
.starter-main .card-body .alert-primary.inverse:before {
  top: 32px;
}
.starter-main .alert {
  background-color: rgba(68, 102, 242, 0.2) !important;
}
.starter-main .alert i {
  display: flex;
  align-items: center;
}
footer.blockquote-footer {
  bottom: unset;
}
@media (max-width: 1800px) {
  .default-datepicker .react-datepicker .react-datepicker__day-name,
  .default-datepicker .react-datepicker .react-datepicker__day,
  .default-datepicker .react-datepicker .react-datepicker__time-name {
    line-height: 45px !important;
    width: 45px !important;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1660px) {
  .morning-sec {
    order: -2;
  }
  .calendar-sec {
    order: -1;
  }
  .chart_data_right.second {
    display: block !important;
  }
}
@media (min-width: 1367px) and (max-width: 1439px) {
  .morning-sec {
    order: -2;
  }
  .calendar-sec {
    order: -1;
  }
  .chart_data_right.second {
    display: block !important;
  }
  .chart_data_left .row > div .media .right-chart-content {
    margin-left: 10px !important;
  }
  .chart_data_left .chart_data_right.second {
    display: block !important;
  }
}
@media screen and (max-width: 1366px) {
  .chart_data_left .row > div:first-child .media {
    padding-left: 40px;
  }
  .chart_data_left .row > div:last-child .media {
    padding-right: 40px;
  }
  .chart_data_left .card-body .chart-main .media {
    padding: 40px 10px;
  }
  .chart_data_left
    .card-body
    .chart-main
    .media
    .media-body
    .right-chart-content {
    margin-left: 4px;
  }
  .chart_data_right.second {
    display: block !important;
  }
  .earning-card {
    overflow: hidden;
  }
  .earning-card .card-body > .row > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .earning-card .card-body > .row > div .chart-left {
    padding: 40px;
  }
  .earning-card .card-body > .row > div .chart-left .left_side_earning {
    margin-bottom: 0;
    display: inline-block;
  }
  .earning-card
    .card-body
    > .row
    > div
    .chart-left
    .left_side_earning
    + .left_side_earning {
    flex: 0 0 33%;
    max-width: 33%;
  }
  .earning-card
    .card-body
    > .row
    > div
    .chart-left
    .left_side_earning:first-child {
    flex: 0 0 50%;
    max-width: 50%;
    order: -2;
    margin-bottom: 30px;
  }
  .earning-card .card-body > .row > div .chart-left .left-btn {
    flex: 0 0 50%;
    max-width: 50%;
    order: -1;
    text-align: right;
  }
  .earning-card .card-body > .row .chart-right {
    padding-bottom: 40px;
  }
  .earning-card.card .card-body .earning-content {
    border-right: none;
    border-bottom: 1px solid #ecf3fa;
  }
  .morning-sec {
    order: -2;
  }
  .morning-sec .greeting-user {
    margin: 33px 0;
  }
  .calendar-sec {
    order: -1;
  }
  .calendar-sec
    .default-datepicker
    .react-datepicker
    .react-datepicker__day-name,
  .calendar-sec .default-datepicker .react-datepicker .react-datepicker__day,
  .calendar-sec
    .default-datepicker
    .react-datepicker
    .react-datepicker__time-name {
    line-height: 42px !important;
    width: 42px !important;
  }
  .appointment .radar-chart .apexcharts-datalabel {
    font-size: 10px !important;
  }
}
@media (max-width: 1199px) {
  .morning-sec {
    order: unset;
  }
  .profile-greeting .greeting-user .left-icon {
    left: 2%;
  }
  .calendar-sec {
    order: unset;
  }
  .earning-card {
    margin-top: 0;
  }
  .earning-card .card-body > .row > div .row > div:last-child {
    padding-left: 0;
  }
  .earning-card .card-body > .row > div .row > div .current-sale-container {
    padding-left: 15px;
  }
  .earning-card
    .card-body
    > .row
    > div
    .chart-left
    .left_side_earning
    + .left_side_earning {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .earning-card
    .card-body
    > .row
    > div
    .chart-left
    .left_side_earning:nth-child(3) {
    text-align: right;
    margin-bottom: 30px;
  }
  .earning-card
    .card-body
    > .row
    > div
    .chart-left
    .left_side_earning:nth-child(4) {
    margin-bottom: 0;
  }
  .earning-card .card-body > .row > div .media {
    margin-left: 0;
  }
  .earning-card .card-body > .row .border-top > div:last-child {
    margin-top: 40px;
  }
  .chart_data_left .card-body .chart-main .media {
    padding: 40px;
  }
  .chart_data_left
    .card-body
    .chart-main
    .media
    .media-body
    .right-chart-content {
    margin-left: 30px;
  }
  .chart_data_left .row > div + div + div .media {
    padding-top: 0 !important;
  }
  .appointment-sec .alert-sec {
    margin-top: 0;
  }
  .chart_data_right.second {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .earning-card {
    margin-top: 0;
  }
  .earning-card.card .card-body .border-top {
    padding: 20px;
  }
  .earning-card.card .card-body .chart-right {
    padding: 0 20px;
  }
  .earning-card.card .card-body .chart-right .p-tb {
    padding: 20px 0;
  }
  .earning-card .card-body > .row .border-top > div:last-child {
    margin-top: 20px;
  }
  .earning-card .card-body > .row .chart-right {
    padding-bottom: 20px;
  }
  .earning-card .card-body > .row > div .chart-left {
    padding: 20px;
  }
  .earning-card .card-body > .row > div .chart-left .left_side_earning {
    margin-bottom: 20px;
  }
  .earning-card
    .card-body
    > .row
    > div
    .chart-left
    .left_side_earning:nth-child(3) {
    margin-bottom: 20px;
  }
  .chart_data_left .card-body .chart-main .media {
    padding: 20px;
  }
  .chart_data_left
    .card-body
    .chart-main
    .media
    .media-body
    .right-chart-content {
    margin-left: 20px;
  }
  .chart_data_left .row > div .media:first-child {
    padding-left: 20px;
  }
  .chart_data_left .row > div .media:last-child {
    padding-right: 20px;
  }
  .news .card .card-body .news-update {
    padding: 20px;
  }
  .notification .card .card-body .media {
    margin-bottom: 20px;
  }
  .notification .card .card-body .media .media-body p {
    margin: 0;
  }
  .notification .card .card-body .media .media-body h6 {
    margin-top: 10px;
  }
  .chat-default .card-body.chat-box .input-group {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .morning-sec .profile-greeting .greeting-user .profile-vector {
    width: 70px;
    margin: 0 auto;
  }
  .morning-sec .profile-greeting .greeting-user h4 {
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .morning-sec .profile-greeting .greeting-user .left-icon {
    left: 20px;
    bottom: 20px;
  }
  .dashboard-sec
    .earning-card
    .card-body
    > .row
    > div
    .chart-right
    > .row
    ul
    li
    + li {
    margin-left: 25px;
  }
  .dashboard-sec
    .earning-card
    .card-body
    > .row
    > div
    .chart-right
    > .row
    .inner-top-right
    ul
    li {
    margin-left: 30px;
  }
  .chart_data_left .card-body .chart-main .media {
    border-right: none;
  }
  .chart_data_left .card-body .chart-main > div:nth-child(3) {
    border-right: 1px solid #ecf3fa;
  }
  .chart_data_left .card-body .chart-main > div:first-child {
    border-right: 1px solid #ecf3fa;
  }
  .footer-fix .pull-right {
    float: none !important;
    text-align: center !important;
  }
}
@media (max-width: 575px) {
  .morning-sec .card-body .greeting-user p {
    padding: 0 15px;
  }
  .morning-sec .card-body .greeting-user .left-icon {
    left: 15px;
    bottom: 15px;
    width: 40px;
    height: 40px;
    border-radius: 15px 15px 15px 5px;
  }
  .chart_data_left .card .card-body .row > div .media {
    padding: 15px;
  }
  .chart_data_left .card .card-body .row > div:first-child .media {
    padding-top: 15px !important;
  }
  .chart_data_left .card .card-body .row:last-child .media {
    padding-top: 0 !important;
  }
  .earning-card .card-body > .row > div .chart-left {
    padding: 15px;
  }
  .earning-card .card-body > .row > div .chart-left .left_side_earning {
    margin-bottom: 15px;
  }
  .earning-card
    .card-body
    > .row
    > div
    .chart-left
    .left_side_earning
    + .left_side_earning,
  .earning-card
    .card-body
    > .row
    > div
    .chart-left
    .left_side_earning:first-child {
    flex: 0 0 100%;
    max-width: 100%;
    order: unset;
  }
  .earning-card
    .card-body
    > .row
    > div
    .chart-left
    .left_side_earning:first-child {
    margin-bottom: 15px;
  }
  .earning-card
    .card-body
    > .row
    > div
    .chart-left
    .left_side_earning:nth-child(4),
  .earning-card
    .card-body
    > .row
    > div
    .chart-left
    .left_side_earning:last-child {
    margin-bottom: 0;
  }
  .earning-card
    .card-body
    > .row
    > div
    .chart-left
    .left_side_earning:nth-child(3) {
    text-align: left;
  }
  .earning-card .card-body > .row > div .chart-left .left-btn {
    order: unset;
    text-align: left;
    margin-top: 15px;
  }
  .earning-card .card-body > .row .chart-right {
    padding: 15px;
  }
  .earning-card .card-body > .row .chart-right .p-tb {
    padding-top: 0;
    padding-bottom: 15px;
  }
  .earning-card .card-body > .row .border-top > div + div {
    margin-top: 15px;
    padding: 0;
  }
  .earning-card .card-body > .row .border-top > div + div:last-child {
    margin-top: 15px;
  }
  .earning-card .card-body .inner-top-right {
    margin-top: 15px;
  }
  .earning-card.card .card-body .border-top {
    padding: 15px;
  }
  .dashboard-sec
    .earning-card
    .card-body
    > .row
    > div
    .chart-right
    > .row
    ul
    li
    + li {
    margin-left: 15px;
  }
  .dashboard-sec
    .earning-card
    .card-body
    > .row
    > div
    .chart-right
    > .row
    .inner-top-right
    ul
    li
    + li {
    margin-left: 28px;
  }
  .notification .card .card-body .media {
    margin-bottom: 15px;
  }
  .news .card .card-body > .news-update {
    padding: 15px;
  }
  .news .card .card-footer {
    padding-top: 0 !important;
  }
  .appointment-sec .alert-sec .card-body {
    padding-top: 0 !important;
  }
  .media-dropdown {
    display: block;
  }
  .media-dropdown .media-body {
    margin-bottom: 15px;
  }
  .chat-sec
    .chat-default
    .card-body.chat-box
    .media
    .media-body
    .message-main
    span {
    font-size: 13px;
    width: 95%;
  }
  .chat-sec
    .chat-default
    .card-body.chat-box
    .media.right-side-chat
    .media-body
    .message-main
    span {
    width: 100%;
  }
  .chat-sec
    .chat-default
    .card-body.chat-box
    .media.right-side-chat
    .media-body
    .message-main
    span
    span {
    width: 5px;
  }
  .chat-sec .chat-default .card-body.chat-box .media.right-side-chat p {
    margin-top: 10px;
  }
  .chat-default .card-body.chat-box .input-group .form-control {
    font-size: 14px;
  }
  .default-datepicker .datepicker-inline .datepicker .datepicker--nav-title {
    font-size: 17px;
  }
  .default-datepicker .datepicker-inline .datepicker .datepicker--nav-title i {
    font-size: 17px;
    margin-left: 10px;
  }
  .default-datepicker
    .datepicker-inline
    .datepicker
    .datepicker--content
    .datepicker--days
    .datepicker--cells
    .datepicker--cell {
    font-size: 14px;
  }
  .calendar-sec
    .default-datepicker
    .datepicker-inline
    .datepicker
    .datepicker--content
    .datepicker--days
    .datepicker--days-names {
    margin: 10px 0 10px;
    padding: 10px 0;
  }
}
@media (max-width: 360px) {
  .footer {
    padding-left: 0;
    padding-right: 0 !important;
  }
  .footer p {
    font-size: 12px;
  }
  .appointment-table table tr td {
    min-width: 83px;
  }
  .appointment-table table tr td:first-child {
    min-width: 63px;
  }
  .appointment-table table tr td:nth-child(2) {
    min-width: 120px;
  }
}
.o-hidden {
  overflow: hidden;
}

/**===================== 53. Dashboard CSS Ends ==========================**/

/**===================== 78. Sidebar CSS Start ==========================**/
@keyframes pagebody {
  from {
    opacity: 0;
    transform: scale3d(0.95, 0.95, 0.95) translate(-20px, -100px);
  }
  50% {
    opacity: 1;
  }
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a:after,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5:after {
  position: absolute;
  content: "";
  top: 48%;
  width: 6px;
  border-top: 2px solid #595c60;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a:after {
  position: absolute;
  content: "";
  top: 49%;
  width: 5px;
  height: 1px;
  border-radius: 100%;
  background-color: #59667a;
}
.page-wrapper {
  position: relative;
}
.page-wrapper .page-header {
  max-width: 100vw;
  position: fixed;
  top: 0;
  z-index: 8;
  transition: 0.5s;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
}
.page-wrapper .page-header .header-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 22px 30px;
  position: relative;
}
.page-wrapper .page-header .header-wrapper .search-full {
  background-color: #fff;
  position: absolute;
  right: 0;
  z-index: 1;
  height: 0;
  width: 0;
  transform: scale(0);
  transition: all 0.3s ease;
}
.page-wrapper .page-header .header-wrapper .search-full .ProfileCard {
  padding: 10px;
  border: 1px solid #ccc !important;
  margin-bottom: 10px;
  border-radius: 10px;
}
.page-wrapper .page-header .header-wrapper .search-full.open {
  height: 100%;
  width: 100%;
  animation: zoomIn 0.5s ease-in-out;
  transform: scale(1);
  transition: all 0.3s ease;
}
.page-wrapper .page-header .header-wrapper .search-full input {
  line-height: 65px;
  padding-left: 60px;
  width: 100% !important;
}
.page-wrapper .page-header .header-wrapper .search-full input:focus {
  outline: none !important;
}
.page-wrapper
  .page-header
  .header-wrapper
  .search-full
  .form-group
  .close-search {
  position: absolute;
  font-size: 15px;
  right: 30px;
  top: 30px;
  color: #898989;
  cursor: pointer;
}
.page-wrapper .page-header .header-wrapper .search-full .form-group:before {
  position: absolute;
  left: 30px;
  top: 30px;
  content: "\f002";
  font-size: 16px;
  color: #898989;
  font-family: FontAwesome;
}
.page-wrapper .page-header .header-wrapper .nav-right {
  justify-content: flex-end;
}
.page-wrapper .page-header .header-wrapper .nav-right ul li svg {
  vertical-align: middle;
  width: 20px;
}
.page-wrapper .page-header .header-wrapper .nav-right ul li svg path {
  color: #242934;
}
.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .mode {
  cursor: pointer;
  width: 20px;
  text-align: center;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right.right-header
  ul
  li
  .mode
  i {
  font-size: 20px;
  font-weight: 600;
  transition: all 0.3s ease;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right.right-header
  ul
  li
  .mode
  i.fa-lightbulb-o {
  font-weight: 500;
  font-size: 22px;
  transition: all 0.3s ease;
  animation: zoomIn 300ms ease-in-out;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right.right-header
  ul
  li
  .header-search {
  cursor: pointer;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right.right-header
  ul
  li
  .profile-media
  img {
  width: 35px;
  height: 35px;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right.right-header
  ul
  li
  .profile-media
  .media-body {
  margin-left: 15px;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right.right-header
  ul
  li
  .profile-media
  .media-body
  span {
  font-weight: 500;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right.right-header
  ul
  li
  .profile-media
  .media-body
  p {
  font-size: 12px;
  line-height: 1;
  color: rgba(43, 43, 43, 0.7);
}
.page-wrapper .page-header .header-wrapper .nav-right > ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li {
  padding: 6px 10px;
  margin-right: 5px;
  display: inline-block;
  position: relative;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li .badge {
  position: absolute;
  right: -5px;
  top: -6px;
  padding: 2px 4px;
  font-size: 11px;
  font-weight: 700;
  font-family: work-Sans, sans-serif;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul {
  left: inherit;
  right: -10px;
  width: 130px;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul:before,
.page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul:after {
  left: inherit;
  right: 10px;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul li {
  display: block;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul li a {
  font-size: 14px;
  color: #2c323f;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul li a i {
  margin-left: 10px;
  font-size: 13px;
  color: #2c323f;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul .flag-icon {
  font-size: 16px;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div {
  width: 330px;
  top: 50px;
  right: 0;
  left: unset;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li {
  padding: 0 20px;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li p {
  opacity: 0.6;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .onhover-show-div
  li
  + li {
  padding-top: 15px;
  padding-bottom: 0px;
  border-top: 1px solid #f5f5f5;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .onhover-show-div
  li:last-child {
  padding-bottom: 15px;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .onhover-show-div
  li
  .media {
  position: relative;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .onhover-show-div
  li
  .media
  img {
  width: 40px;
  position: relative;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .onhover-show-div
  li
  .media
  .status-circle {
  left: 0;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .onhover-show-div
  li
  .media
  .media-body
  > span {
  font-weight: 500;
  font-family: roboto;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .onhover-show-div
  li
  .media
  .media-body
  p {
  margin-bottom: 15px;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .onhover-show-div:not(.profile-dropdown)
  li:first-child {
  padding: 20px;
  background-color: #eaeaea;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .onhover-show-div:not(.profile-dropdown)
  li:first-child
  svg {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  right: 0;
  opacity: 0.05;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div:before,
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div:after {
  right: 10px !important;
  left: unset !important;
}
.page-wrapper .page-header .header-wrapper .nav-right .bookmark-flip {
  width: unset !important;
  box-shadow: unset !important;
  background-color: transparent !important;
  overflow: initial;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .bookmark-flip
  .Typeahead-menu {
  top: 33%;
  background: transparent;
  box-shadow: none;
  left: -1px;
  overflow: auto;
  max-height: 100px;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .bookmark-flip
  .back
  li:first-child
  svg {
  position: relative;
  width: 16px;
  height: 16px;
  top: 0;
  right: 0;
  opacity: 1;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .bookmark-flip
  .ProfileCard-realName {
  text-align: left;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .bookmark-flip
  .ProfileCard-avatar
  svg {
  color: #2b2b2b !important;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card {
  width: 220px;
  height: 245px;
  background-color: transparent;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner {
  position: relative;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .front,
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .back {
  position: absolute;
  backface-visibility: hidden;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  width: 220px;
  height: 240px;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .front
  button,
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .back
  button {
  background-color: transparent !important;
  color: var(--theme-deafult);
  padding: 0;
  border: none;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .front
  button:focus,
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .back
  button:focus {
  outline: transparent;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .front {
  background-color: #fff;
  transition: 0.3s;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .front
  .bookmark-dropdown {
  height: 100%;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .front
  .bookmark-dropdown
  li:nth-child(2) {
  max-height: 126px;
  overflow: auto;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .front
  .bookmark-dropdown
  li:last-child {
  border-top: 1px solid #efefef;
  position: static;
  width: 100%;
  bottom: -101px;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .front
  svg {
  margin-bottom: 15px;
  stroke: #2c323f;
  transition: all 0.3s ease;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .front
  svg:hover {
  stroke: var(--theme-deafult);
  transition: all 0.3s ease;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .back {
  background-color: #fff;
  transform: rotateY(180deg);
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .back
  li:first-child {
  margin-top: 20px;
  height: 160px !important;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .back
  li:last-child {
  padding: 20px 0;
  border-top: 1px solid #ddd;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .back
  button {
  width: 100%;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner
  .back
  .flip-back-content
  input {
  border-radius: 25px;
  padding: 6px 12px;
  width: 100%;
  border: 1px solid #ddd;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner.flipped {
  transform: rotateY(180deg);
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .flip-card
  .flip-card-inner.flipped
  .front {
  opacity: 0;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-box {
  position: relative;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-box svg {
  animation: tada 1.5s ease infinite;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-box {
  position: relative;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-box svg {
  animation: swing 1.5s ease infinite;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li .media {
  align-items: center;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li + li {
  padding-bottom: 10px;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .cart-dropdown
  .qty-box
  .input-group {
  width: 78px;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown p {
  line-height: 1.3;
  margin-top: 3px;
  opacity: 0.6;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .cart-dropdown
  .close-circle {
  position: absolute;
  right: 0;
  top: 0;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .cart-dropdown
  .close-circle
  svg {
  width: 14px;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown {
  top: 52px;
  width: 300px;
  right: -20px !important;
  left: unset;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .notification-dropdown
  li
  + li {
  padding-top: 8px;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .notification-dropdown
  li
  + li
  p {
  margin-bottom: 8px;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .notification-dropdown
  li:last-child {
  text-align: center;
  border-top: 1px solid #ecf3fa;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .notification-dropdown
  li
  p
  span {
  color: rgba(27, 49, 85, 0.4);
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .notification-dropdown:before,
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .notification-dropdown:after {
  right: 28px !important;
  left: unset !important;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown {
  width: 160px;
  top: 57px;
  left: -12px;
  padding: 0 10px;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li {
  padding: 10px !important;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .profile-dropdown
  li:hover
  a
  span {
  color: var(--theme-deafult);
  transition: color 0.3s ease;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .profile-dropdown
  li:hover
  a
  svg {
  stroke: var(--theme-deafult);
  transition: stroke 0.3s ease;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .profile-dropdown
  li:hover
  a
  svg
  path {
  stroke: var(--theme-deafult);
  transition: stroke 0.3s ease;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .profile-dropdown
  li:last-child {
  padding-top: 10px !important;
}
.page-wrapper
  .page-header
  .header-wrapper
  .nav-right
  .profile-dropdown
  li
  span {
  color: #2c323f;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li svg {
  width: 16px;
  vertical-align: bottom;
  margin-right: 10px;
  stroke: #2c323f;
}
.page-wrapper .page-body-wrapper {
  background-color: #f8f8f8;
}
.page-wrapper .page-body-wrapper .page-body {
  min-height: calc(100vh - 136px);
  margin-top: 136px;
  padding: 0 15px 0 15px;
  position: relative;
  transition: 0.5s;
}
.page-wrapper .page-body-wrapper .page-body > div > div.zoomout-enter-done {
  animation: zoomOut 1000ms ease-in-out;
}
.page-wrapper .page-body-wrapper .page-body > div > div.slidefade-enter-done {
  animation: slideInLeft 1000ms ease-in-out;
}
.page-wrapper .page-body-wrapper .page-body > div > div.fade-enter-done {
  animation: fadeIn 1000ms ease-in-out;
}
.page-wrapper .page-body-wrapper .page-body > div > div.fadebottom-enter-done {
  animation: fadeInDown 1000ms ease-in-out;
}
.page-wrapper .page-body-wrapper .page-body > div > div.slidefade-enter,
.page-wrapper .page-body-wrapper .page-body > div > div.slidefade-exit,
.page-wrapper .page-body-wrapper .page-body > div > div.fade-enter,
.page-wrapper .page-body-wrapper .page-body > div > div.fade-exit,
.page-wrapper .page-body-wrapper .page-body > div > div.zoomout-enter,
.page-wrapper .page-body-wrapper .page-body > div > div.zoomout-exit,
.page-wrapper .page-body-wrapper .page-body > div > div.fadebottom-enter,
.page-wrapper .page-body-wrapper .page-body > div > div.fadebottom-exit,
.page-wrapper .page-body-wrapper .page-body > div > div.none-enter-done,
.page-wrapper .page-body-wrapper .page-body > div > div.none-enter,
.page-wrapper .page-body-wrapper .page-body > div > div.none-exit {
  animation: unset;
}
.page-wrapper .page-body-wrapper .page-body:before {
  animation: fadeIn 0.5s ease-in-out;
}
.page-wrapper .page-body-wrapper .page-title {
  padding-top: 30px;
  padding-bottom: 30px;
}
.page-wrapper .page-body-wrapper .page-title > .row .col-6:first-child {
  display: flex;
}
.page-wrapper .page-body-wrapper .page-title > .row .col-6:first-child h3 {
  padding-right: 20px;
}
.page-wrapper .page-body-wrapper .page-title .row {
  align-items: center;
}
.page-wrapper .page-body-wrapper .page-title .row h3 {
  font-size: 24px;
  margin-bottom: 0;
  text-transform: capitalize;
}
.page-wrapper .page-body-wrapper .page-title .row h3 small {
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
  text-transform: capitalize;
  color: rgba(43, 43, 43, 0.7);
}
.page-wrapper .page-body-wrapper .page-title .breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-top: 5px;
  margin-bottom: 0;
  justify-content: flex-end;
}
.page-wrapper .page-body-wrapper .page-title .breadcrumb li {
  font-size: 13px;
  letter-spacing: 1px;
  font-family: Roboto, sans-serif;
}
.page-wrapper
  .page-body-wrapper
  .page-title
  .breadcrumb
  .breadcrumb-item
  + .breadcrumb-item:before {
  content: "/";
}
.page-wrapper .page-body-wrapper .page-title .breadcrumb .breadcrumb-item a {
  color: var(--theme-deafult);
}
.page-wrapper
  .page-body-wrapper
  .page-title
  .breadcrumb
  .breadcrumb-item
  a
  svg {
  width: 14px;
  height: 14px;
  vertical-align: text-top;
}
.page-wrapper.horizontal-wrapper .header-logo-wrapper {
  width: 150px;
}
.page-wrapper.horizontal-wrapper .header-logo-wrapper .toggle-sidebar {
  display: none;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper {
  height: 54px;
  line-height: inherit;
  top: 81px;
  background: #fff;
  box-shadow: 0px 9px 24px rgba(89, 102, 122, 0.05);
  overflow-x: hidden;
  z-index: 1;
  overflow-y: visible;
  border-radius: 0;
  position: fixed;
  width: calc(100vw - 0px);
  border-top: 1px solid #efefef;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .badge {
  position: absolute;
  top: 0;
  left: 48px;
  z-index: 1;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .logo-wrapper,
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .logo-icon-wrapper {
  display: none;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main {
  text-align: left;
  position: relative;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  #sidebar-menu {
  transition: 0.5s;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .left-arrow,
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .right-arrow {
  position: absolute;
  top: 0px;
  padding: 15px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .left-arrow.disabled,
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .right-arrow.disabled {
  display: none;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .left-arrow {
  left: 0;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .right-arrow {
  right: 0;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left;
  display: -webkit-box !important;
  padding: 0 5px;
  transition: all 0.5s ease;
  white-space: nowrap;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li.sidebar-list:hover
  a.active
  svg {
  stroke: var(--theme-deafult);
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li.sidebar-list:hover
  .sidebar-submenu {
  display: block !important;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li.sidebar-list
  a.active
  .according-menu
  i:before {
  content: "-";
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li.sidebar-list
  a
  .according-menu {
  display: none;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li.sidebar-list
  ul.sidebar-submenu
  li
  a.active {
  color: var(--theme-deafult);
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li.sidebar-list
  ul.sidebar-submenu
  li
  a.active:after {
  background-color: var(--theme-deafult);
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li {
  position: relative;
  float: left;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li:hover
  > a
  > span {
  color: var(--theme-deafult);
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li:hover
  a {
  background-color: rgba(115, 102, 255, 0.15);
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li:hover
  a
  svg {
  fill: rgba(115, 102, 255, 0.1);
  stroke: var(--theme-deafult);
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li:first-child {
  display: none !important;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  > a {
  display: block;
  color: #2b2b2b;
  line-height: 23px;
  text-decoration: none;
  text-transform: capitalize;
  padding: 5px 15px;
  margin: 0 3px;
  letter-spacing: 0.04em;
  position: relative;
  border-radius: 5px;
  font-weight: 500;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  > a.active {
  background-color: #dad6ff;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  > a.active
  svg,
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  > a.active
  span {
  color: var(--theme-deafult);
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  > a
  .badge {
  position: absolute;
  top: -8px;
  margin-left: 1px;
  right: 10px;
  display: inline-table;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  > a
  svg {
  width: 16px;
  vertical-align: bottom;
  margin-right: 10px;
  stroke-width: 2.5px;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu {
  position: fixed;
  z-index: 4;
  box-shadow: 0 0 1px 0 #898989;
  padding: 15px 0px 15px 0px;
  background: white;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li {
  border: 0;
  float: none;
  position: relative;
  display: block;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a {
  background: transparent;
  padding: 4px 30px;
  display: block;
  transition: all 0.5s ease;
  line-height: 1.9;
  color: rgba(43, 43, 43, 0.6);
  letter-spacing: 0.06em;
  font-weight: 500;
  font-family: Roboto;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a:after {
  left: 20px;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a:hover {
  color: var(--theme-deafult);
  transform: translate(5px, 0px);
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a:hover:after {
  background-color: var(--theme-deafult);
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container {
  position: absolute;
  right: -24%;
  z-index: 4;
  box-shadow: 0 0 1px 0 #898989;
  padding: 30px;
  background: white;
  width: 95%;
  border-radius: 4px;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box {
  width: 20%;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5 {
  text-transform: uppercase;
  font-size: 14px;
  margin-left: -13px;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content {
  box-shadow: none;
  background-color: transparent;
  position: relative;
  display: block;
  padding: 0;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content
  li {
  border: 0;
  float: none;
  position: relative;
  display: block;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content
  li
  a {
  border: 0 !important;
  background: transparent;
  color: #2b2b2b;
  border-radius: 0 !important;
  text-transform: capitalize;
  padding: 3px 0;
  font-size: 13px;
  display: block;
  letter-spacing: 0.07em;
  line-height: 1.9;
  position: relative;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content
  li
  a:hover {
  color: var(--theme-deafult);
  transform: translate(5px, 0px);
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content
  li
  a:hover:after {
  color: var(--theme-deafult);
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content
  li
  a:after {
  position: absolute;
  content: "";
  left: -12px;
  top: 50%;
  width: 5px;
  height: 1px;
  background-color: #59667a;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li:hover
  > a:after {
  left: 0;
  right: 0;
  opacity: 1;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li.mega-menu {
  position: unset;
}
.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .sidebar-wrapper.overlay-white {
  background-color: rgba(255, 255, 255, 0.08);
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .toggle-nav {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .toggle-nav i {
  font-size: 24px;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .page-body {
  margin-top: 135px;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main-title {
  display: none !important;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper.scorlled .sidebar-wrapper {
  display: none;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-header {
  width: 100%;
  box-shadow: none;
}
.page-wrapper.horizontal-wrapper
  .page-header
  .header-wrapper
  .form-control-plaintext {
  width: auto !important;
}
.page-wrapper .sidebar-main-title > div {
  background-color: rgba(115, 102, 255, 0.06);
  padding: 15px 17px;
  border-radius: 10px;
}
.page-wrapper .sidebar-main-title h6 {
  color: var(--theme-deafult);
  margin-bottom: 5px;
  letter-spacing: 0.4px;
}
.page-wrapper .sidebar-main-title p {
  color: #2c323f;
  font-size: 11px;
  margin-bottom: 0;
  text-transform: capitalize;
  line-height: 1;
}
.page-wrapper.compact-wrapper .page-header {
  margin-left: 280px;
  width: calc(100% - 280px);
}
.page-wrapper.compact-wrapper .page-header.close_icon {
  margin-left: 90px;
  width: calc(100% - 90px);
}
.page-wrapper.compact-wrapper .page-header .header-wrapper .logo-wrapper {
  display: none;
}
.page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
  position: fixed;
  top: 0;
  z-index: 9;
  height: auto;
  line-height: inherit;
  background: #fff;
  width: 280px;
  text-align: left;
  transition: 0.3s;
  box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .logo-wrapper,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .logo-icon-wrapper {
  padding: 27px 30px;
  box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .logo-icon-wrapper {
  display: none;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon {
  width: auto;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  .badge {
  opacity: 0;
  width: 0;
  padding: 0;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  .sidebar-main
  .sidebar-links
  > li {
  max-width: 86px;
  margin: 0;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  a {
  display: none;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  .sidebar-main
  .sidebar-links
  > li
  a {
  padding: 12px 14px;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  .sidebar-main-title {
  display: none !important;
  opacity: 0;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  .logo-wrapper {
  display: none;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  .logo-icon-wrapper {
  text-align: right;
  display: block;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon:hover {
  width: 280px;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon:hover
  .badge {
  opacity: 1;
  width: auto;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon:hover
  .sidebar-main
  .sidebar-links
  > li {
  max-width: unset;
  margin: initial;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon:hover
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  a {
  display: block;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon:hover
  .sidebar-main
  .sidebar-links
  > li
  > a {
  padding: 12px 15px;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon:hover
  .sidebar-main
  .sidebar-links
  > li
  > a
  span {
  display: inline-block;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon:hover
  .sidebar-main-title {
  opacity: 1;
  display: block !important;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon:hover
  .logo-wrapper {
  display: block;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon:hover
  .logo-icon-wrapper {
  display: none;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  .mobile-sidebar {
  transform: translate(183px);
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  ~ .page-body {
  margin-left: 100px;
  transition: 0.5s;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  ~ footer {
  margin-left: 92px;
  transition: 0.5s;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  ~ .footer-fix {
  width: calc(100% - 90px);
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  .sidebar-main
  .sidebar-links {
  height: calc(100vh - 118px);
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link::before {
  right: 20px;
  left: unset;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  .sidebar-main
  .sidebar-links
  > li
  a
  span {
  display: none;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  .sidebar-main
  .sidebar-links
  .sidebar-list
  .sidebar-title.active
  ~ .sidebar-submenu {
  display: none;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  .according-menu {
  display: none;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .left-arrow,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .right-arrow {
  display: none;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links {
  height: calc(100vh - 115px);
  left: -300px;
  z-index: 99;
  transition: color 1s ease;
  overflow: auto;
  color: rgba(0, 0, 0, 0);
  margin-top: 20px;
  margin-bottom: 30px;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links.custom-scrollbar::-webkit-scrollbar-thumb,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  border-radius: 13px;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links.custom-scrollbar::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
  background-color: #fff;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links:hover.custom-scrollbar {
  color: rgba(68, 102, 242, 0.1);
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  ul.sidebar-submenu
  li
  a.active {
  color: var(--theme-deafult);
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  ul.sidebar-submenu
  li
  a.active:after {
  border-color: var(--theme-deafult);
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  ul.sidebar-submenu
  li
  a
  .according-menu {
  top: 7px;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a {
  font-weight: 500;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a
  span {
  letter-spacing: 0.7px;
  font-family: Roboto;
  text-transform: capitalize;
  color: #2c323f;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a.link-nav
  .according-menu {
  display: none;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li:last-child {
  border-bottom: none;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links.opennav {
  left: 0;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .back-btn {
  display: block;
  width: 100%;
  padding: 0;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .back-btn
  .mobile-back {
  padding: 20px;
  color: #242934;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li {
  display: block;
  width: 100%;
  padding: 0 20px;
  position: relative;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li.sidebar-main-title {
  padding-top: 15px;
  padding-bottom: 13px;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li.sidebar-list
  .badge {
  position: absolute;
  right: 32px;
  top: 14.5px;
  padding: 0.44em 0.7em 0.32em;
  z-index: 1;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li.sidebar-list:hover
  > a:hover {
  background-color: rgba(115, 102, 255, 0.12);
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li:hover
  .sidebar-link:not(.active):hover
  svg {
  fill: rgba(115, 102, 255, 0.1);
  stroke: var(--theme-deafult);
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li:hover
  .sidebar-link:not(.active):hover
  span {
  color: var(--theme-deafult);
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li:hover
  .sidebar-link:not(.active):hover
  .according-menu
  i {
  color: var(--theme-deafult);
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  a {
  padding: 12px 15px;
  position: relative;
  color: #222;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  a
  svg {
  width: 18px;
  margin-right: 10px;
  vertical-align: bottom;
  float: none;
  stroke-width: 2.3px;
  color: #2c323f;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  a
  .sub-arrow {
  right: 20px;
  position: absolute;
  top: 10px;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  a
  .sub-arrow
  i {
  display: none;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  a
  .sub-arrow:before {
  display: none;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li:first-child {
  display: none !important;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu {
  width: 100%;
  padding: 0;
  position: relative !important;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  > li
  a {
  padding-left: 40px !important;
  font-weight: 400;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  .submenu-title
  .according-menu {
  top: 8px;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu.opensubmenu {
  display: block;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li:hover
  > a {
  color: var(--theme-deafult);
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li:hover
  > a:after {
  border-top: 2px solid var(--theme-deafult);
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  .nav-sub-childmenu {
  display: none;
  position: relative !important;
  right: 0;
  width: 100%;
  padding: 0;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  .nav-sub-childmenu.opensubchild {
  display: block;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  .nav-sub-childmenu
  li
  a {
  padding: 6px 45px;
  padding-left: 56px !important;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  .nav-sub-childmenu
  li
  a:after {
  display: none;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a {
  padding: 7px 25px;
  font-size: 14px;
  color: rgba(43, 43, 43, 0.6);
  display: block;
  position: relative;
  letter-spacing: 0.06em;
  font-weight: 500;
  font-family: Roboto;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a:after {
  left: 20px;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a:hover {
  margin-left: 0;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li.mega-menu
  .sidebar-title.active
  ~ .mega-menu-container {
  display: block !important;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container {
  padding: 0;
  position: relative;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container.opensubmenu {
  display: block;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box {
  width: 100%;
  padding: 0;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section:hover
  h5 {
  color: var(--theme-deafult) !important;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section:hover
  h5:after {
  border-top: 2px solid var(--theme-deafult);
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title {
  position: relative;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5 {
  margin-bottom: 0;
  line-height: 1.9;
  padding: 6px 25px 6px 45px;
  font-size: 14px;
  position: relative;
  color: rgba(43, 43, 43, 0.6);
  display: block;
  letter-spacing: 0.06em;
  font-weight: 500;
  font-family: Roboto;
  cursor: pointer;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5:after {
  left: 25px;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  .according-menu {
  top: 8px;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content.opensubmegamenu
  li
  a {
  margin-bottom: 0;
  line-height: 1.9;
  padding: 6px 25px 6px 45px;
  font-size: 14px;
  position: relative;
  color: rgba(43, 43, 43, 0.6);
  display: block;
  letter-spacing: 0.06em;
  font-weight: 500;
  font-family: Roboto;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content.opensubmegamenu
  li
  a:after {
  display: none;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content.opensubmegamenu
  li
  a.active {
  color: var(--theme-deafult);
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content
  ul
  li
  a {
  line-height: 1.9;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content
  ul
  li
  a:hover {
  margin-left: 0;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link {
  border-radius: 10px;
  transition: all 0.5s ease;
  display: block;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link.active {
  background-color: #dad6ff;
  transition: all 0.5s ease;
  position: relative;
  margin-bottom: 10px;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link.active
  .according-menu
  i {
  color: var(--theme-deafult);
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link.active
  svg {
  color: var(--theme-deafult);
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link.active
  span {
  color: var(--theme-deafult);
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper .mega-box.col {
  flex-basis: unset;
}
.page-wrapper.compact-wrapper .page-body-wrapper .according-menu {
  position: absolute;
  right: 15px;
  top: 13px;
  display: block;
}
.page-wrapper.compact-wrapper .page-body-wrapper .according-menu i {
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
}
.page-wrapper.compact-wrapper .page-body-wrapper .main-menu.border-section {
  border: none;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  .main-menu
  .menu-left
  .main-menu-right
  .toggle-nav {
  position: absolute;
  z-index: 2;
  right: 145px;
  top: 6px;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  .main-menu
  .menu-left
  .main-menu-right
  .toggle-nav
  .sidebar-name {
  font-size: 18px;
  padding-left: 5px;
  text-transform: uppercase;
}
.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  min-height: calc(100vh - 80px);
  margin-top: 80px;
  margin-left: 280px;
}
.page-wrapper.compact-wrapper.dark-sidebar .toggle-sidebar svg,
.page-wrapper.compact-wrapper.color-sidebar .toggle-sidebar svg {
  stroke: var(--theme-deafult);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper,
.page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper {
  background-color: #262932 !important;
}
.page-wrapper.compact-wrapper.dark-sidebar
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a
  span,
.page-wrapper.compact-wrapper.color-sidebar
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a
  span {
  color: rgba(255, 255, 255, 0.7);
}
.page-wrapper.compact-wrapper.dark-sidebar
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a
  svg,
.page-wrapper.compact-wrapper.color-sidebar
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a
  svg {
  stroke: rgba(255, 255, 255, 0.7);
}
.page-wrapper.compact-wrapper.dark-sidebar
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5,
.page-wrapper.compact-wrapper.color-sidebar
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5 {
  color: rgba(255, 255, 255, 0.7);
}
.page-wrapper.compact-wrapper.dark-sidebar
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content.opensubmegamenu
  li
  a,
.page-wrapper.compact-wrapper.color-sidebar
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content.opensubmegamenu
  li
  a {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}
.page-wrapper.compact-wrapper.dark-sidebar
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container::after,
.page-wrapper.compact-wrapper.color-sidebar
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .mega-menu-container::after {
  background-color: rgba(255, 255, 255, 0.2);
}
.page-wrapper.compact-wrapper.dark-sidebar
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link.active,
.page-wrapper.compact-wrapper.color-sidebar
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link.active {
  background-color: rgba(115, 102, 255, 0.2);
}
.page-wrapper.compact-wrapper.dark-sidebar
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link.active
  svg,
.page-wrapper.compact-wrapper.color-sidebar
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link.active
  svg {
  color: var(--theme-deafult);
  stroke: var(--theme-deafult);
}
.page-wrapper.compact-wrapper.dark-sidebar
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a,
.page-wrapper.compact-wrapper.color-sidebar
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}
.page-wrapper.compact-wrapper.dark-sidebar
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  ul.sidebar-submenu
  li
  a
  span,
.page-wrapper.compact-wrapper.color-sidebar
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  ul.sidebar-submenu
  li
  a
  span {
  color: rgba(255, 255, 255, 0.7);
}
.page-wrapper.compact-wrapper.dark-sidebar
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  ul.sidebar-submenu
  li
  a.active,
.page-wrapper.compact-wrapper.color-sidebar
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  ul.sidebar-submenu
  li
  a.active {
  color: var(--theme-deafult);
}
.page-wrapper.compact-wrapper.dark-sidebar
  div.sidebar-wrapper
  .logo-wrapper
  .for-light,
.page-wrapper.compact-wrapper.color-sidebar
  div.sidebar-wrapper
  .logo-wrapper
  .for-light {
  display: none;
}
.page-wrapper.compact-wrapper.dark-sidebar
  div.sidebar-wrapper
  .logo-wrapper
  .for-dark,
.page-wrapper.compact-wrapper.color-sidebar
  div.sidebar-wrapper
  .logo-wrapper
  .for-dark {
  display: block;
}
.page-wrapper.compact-wrapper.dark-sidebar .according-menu i,
.page-wrapper.compact-wrapper.color-sidebar .according-menu i {
  color: rgba(255, 255, 255, 0.7);
}
.page-wrapper.compact-wrapper.dark-sidebar .sidebar-main-title p,
.page-wrapper.compact-wrapper.color-sidebar .sidebar-main-title p {
  color: rgba(255, 255, 255, 0.7);
}
.page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper {
  background-color: #2f3c4e;
}
.page-wrapper.compact-wrapper.color-sidebar .sidebar-main-title p {
  display: none;
}
.page-wrapper.compact-wrapper.color-sidebar .sidebar-main-title h6 {
  margin-bottom: 0;
}
.page-wrapper .bg-overlay1 {
  display: none;
}
.page-wrapper.compact-sidebar .bg-overlay1 {
  transition: 0.8s;
  display: block;
  visibility: visible;
  opacity: 0;
}
.page-wrapper.compact-sidebar .bg-overlay1.active {
  opacity: 1;
  z-index: 8;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
}
.page-wrapper.compact-sidebar .toggle-sidebar {
  display: block !important;
  position: relative;
  right: unset;
  top: unset;
  margin-right: 15px;
}
.page-wrapper.compact-sidebar .sidebar-main-title {
  display: none;
}
.page-wrapper.compact-sidebar .logo-wrapper {
  display: none;
}
.page-wrapper.compact-sidebar .logo-icon-wrapper {
  display: block;
  text-align: center;
  padding: 24px 30px;
  box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
}
.page-wrapper.compact-sidebar .page-header {
  margin-left: 150px;
  width: calc(100% - 150px);
}
.page-wrapper.compact-sidebar .page-header .header-wrapper .logo-wrapper {
  display: none;
}
.page-wrapper.compact-sidebar .page-header.close_icon {
  margin-left: 0;
  width: calc(100% - 0px);
}
.page-wrapper.compact-sidebar .page-body-wrapper .page-body {
  margin-top: 80px;
  margin-left: 150px;
}
.page-wrapper.compact-sidebar .page-body-wrapper .footer {
  margin-left: 150px;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper {
  position: fixed;
  top: 0;
  z-index: 9;
  background: #fff;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper > div {
  height: auto;
  line-height: inherit;
  background: #fff;
  width: 150px;
  transition: 0.3s;
  box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .left-arrow,
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .right-arrow {
  display: none;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links {
  margin: 30px 0;
  height: calc(100vh - 113px);
  overflow: auto;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a {
  color: #2c323f;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a
  span {
  letter-spacing: 0.7px;
  font-family: Roboto;
  text-transform: capitalize;
  color: #2c323f;
  font-size: 13px;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a.active {
  background: rgba(115, 102, 255, 0.1);
  color: #7366ff;
  border-radius: 20px;
  margin: 0 10px;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a.active
  span {
  color: #7366ff;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a.active
  ~ .sidebar-submenu,
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a.active
  ~ .mega-menu-container {
  display: block;
  animation: fadeInLeft 300ms ease-in-out;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-title {
  display: block;
  padding: 20px 0;
  border-bottom: 1px solid #f3f3f3;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-submenu,
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .mega-menu-container {
  position: fixed;
  top: 0px;
  height: calc(100vh - 0px);
  overflow: auto;
  left: 150px;
  width: 230px;
  background-color: #fff;
  box-shadow: 0 16px 15px 15px rgba(126, 55, 216, 0.03);
  transition: all 0.5s ease-in-out;
  display: none;
  padding-top: 20px;
  z-index: -1;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-submenu
  li
  a,
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .mega-menu-container
  li
  a {
  padding: 15px 15px 15px 40px;
  display: block;
  position: relative;
  border-bottom: 1px solid #f1f1f1;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-submenu
  li
  a
  .sub-arrow,
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .mega-menu-container
  li
  a
  .sub-arrow {
  display: none;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-submenu
  li
  a
  .according-menu,
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .mega-menu-container
  li
  a
  .according-menu {
  position: absolute;
  right: 20px;
  top: 16px;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-submenu
  li
  a.active,
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .mega-menu-container
  li
  a.active {
  background-color: rgba(115, 102, 255, 0.1);
  color: #7366ff;
  transition: all 0.3s ease;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-submenu
  li
  .submenu-content
  li
  a.active,
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .mega-menu-container
  li
  .submenu-content
  li
  a.active {
  background-color: transparent;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-submenu
  > li
  > a::after,
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .mega-menu-container
  > li
  > a::after {
  position: absolute;
  content: "";
  left: 20px;
  top: 48%;
  width: 6px;
  border-top: 2px solid #595c60;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-submenu
  .mega-box,
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .mega-menu-container
  .mega-box {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-submenu
  .mega-box
  .link-section,
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .mega-menu-container
  .mega-box
  .link-section {
  text-align: left;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-submenu
  .mega-box
  .link-section
  .submenu-title
  h5,
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5 {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  padding: 15px 15px 15px 40px;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-submenu
  .mega-box
  .link-section
  .submenu-title
  h5::after,
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5::after {
  position: absolute;
  content: "";
  left: 20px;
  top: 48%;
  width: 6px;
  border-top: 2px solid #595c60;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list {
  position: relative;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  .badge {
  position: absolute;
  left: 53%;
  top: 7px;
  font-weight: 500;
  font-size: 9px;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  .sidebar-link {
  text-align: center;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  .sidebar-link
  svg {
  display: block;
  margin: 0 auto 6px;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  .sidebar-link
  > span {
  display: block;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 14px;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  .sidebar-link
  .according-menu {
  display: none;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .mega-menu {
  text-align: center;
  padding: 0;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .mega-menu
  svg {
  display: block;
  margin: 0 auto 6px;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .mega-menu
  .according-menu {
  position: absolute;
  right: 20px;
  top: 13px;
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper.close_icon {
  transform: translateX(-150px);
}
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  ~ .page-body,
.page-wrapper.compact-sidebar
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  ~ .footer {
  margin-left: 0px;
}
.page-wrapper.compact-small .toggle-sidebar {
  position: relative;
  right: unset;
  top: unset;
  margin-right: 15px;
}
.page-wrapper.compact-small .sidebar-main-title,
.page-wrapper.compact-small .logo-wrapper {
  display: none;
}
.page-wrapper.compact-small .logo-icon-wrapper {
  display: block;
  text-align: center;
  padding: 27px 30px;
  box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
}
.page-wrapper.compact-small .page-header {
  margin-left: 90px;
  width: calc(100% - 90px);
}
.page-wrapper.compact-small .page-header .header-wrapper .logo-wrapper {
  display: none;
}
.page-wrapper.compact-small .page-header.close_icon {
  margin-left: 0;
  width: calc(100% - 0px);
}
.page-wrapper.compact-small .page-body-wrapper .page-body {
  margin-left: 90px;
}
.page-wrapper.compact-small .page-body-wrapper .footer {
  margin-left: 90px;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper > div {
  width: 90px;
}
.page-wrapper.compact-small
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-title {
  display: block;
  padding: 15px 0;
}
.page-wrapper.compact-small
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-submenu,
.page-wrapper.compact-small
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .mega-menu-container {
  left: 90px;
}
.page-wrapper.compact-small
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-submenu
  li
  a,
.page-wrapper.compact-small
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .mega-menu-container
  li
  a {
  padding: 10px 15px 10px 40px;
}
.page-wrapper.compact-small
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-submenu
  li
  a
  .sub-arrow,
.page-wrapper.compact-small
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .mega-menu-container
  li
  a
  .sub-arrow {
  display: none;
}
.page-wrapper.compact-small
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .sidebar-submenu
  li
  a
  .according-menu,
.page-wrapper.compact-small
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  .mega-menu-container
  li
  a
  .according-menu {
  top: 11px;
}
.page-wrapper.compact-small
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  .badge {
  display: none;
}
.page-wrapper.compact-small
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  .sidebar-link
  svg {
  display: block;
  margin: 0 auto;
  width: 20px;
}
.page-wrapper.compact-small
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  .sidebar-link
  > span {
  display: none;
}
.page-wrapper.compact-small
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .mega-menu
  svg {
  margin: 0 auto;
}
.page-wrapper.compact-small
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .mega-menu
  a
  > span {
  display: none;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper.close_icon {
  transform: translateX(-150px);
}
.page-wrapper.compact-small
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  ~ .page-body,
.page-wrapper.compact-small
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  ~ .footer {
  margin-left: 0px;
}
.page-wrapper.only-body {
  background-color: #f2f0fa;
}
.page-wrapper.only-body .page-body-wrapper {
  background-color: #f2f0fa;
}
.page-wrapper.only-body .page-header {
  position: relative;
}
.page-wrapper.only-body .page-header .nav-menus .profile-nav .media-body > span,
.page-wrapper.only-body .page-header .nav-menus .profile-nav .media-body > p {
  color: #fff !important;
}
.page-wrapper.only-body .page-header .nav-menus > li > svg {
  stroke: #fff;
}
.page-wrapper.only-body .page-header .nav-menus > li > a > svg {
  stroke: #fff;
}
.page-wrapper.only-body .page-header .nav-menus > li > div .lang-txt,
.page-wrapper.only-body .page-header .nav-menus > li > span .lang-txt {
  color: #fff;
}
.page-wrapper.only-body .page-header .nav-menus > li > div i,
.page-wrapper.only-body .page-header .nav-menus > li > span i {
  color: #fff;
}
.page-wrapper.only-body .page-header .nav-menus > li > div svg,
.page-wrapper.only-body .page-header .nav-menus > li > span svg {
  stroke: #fff;
}
.page-wrapper.only-body .page-header .left-header,
.page-wrapper.only-body .page-header .header-logo-wrapper {
  display: none;
}
.page-wrapper.only-body .page-header .header-wrapper {
  padding: 0 !important;
}
.page-wrapper.only-body .page-header .header-wrapper .nav-right {
  position: absolute;
  right: 60px;
  top: 58px;
}
.page-wrapper.only-body .page-header .header-wrapper .search-full {
  top: 37px;
  width: calc(100% - 50px);
  left: 25px;
}
.page-wrapper.only-body
  .page-header
  .header-wrapper
  .search-full
  .form-group
  .Typeahead
  .u-posRelative {
  background-color: #fff;
  border-radius: 15px;
}
.page-wrapper.only-body
  .page-header
  .header-wrapper
  .search-full
  .Typeahead-menu {
  top: 78px;
  width: calc(100% - 30px);
  margin: 0 auto;
  right: 0;
}
.page-wrapper.only-body
  .page-body-wrapper
  .breadcrumb-item
  + .breadcrumb-item::before {
  color: #fff;
}
.page-wrapper.only-body .page-body-wrapper .page-title {
  padding-top: 20px;
  padding-bottom: 20px;
}
.page-wrapper.only-body .page-body-wrapper .page-title .breadcrumb li {
  color: #fff;
}
.page-wrapper.only-body .page-body-wrapper .page-title .breadcrumb-item a svg {
  color: #fff;
}
.page-wrapper.only-body .page-body-wrapper .page-title .row h3 {
  color: #fff;
}
.page-wrapper.only-body .sidebar-wrapper,
.page-wrapper.only-body footer {
  display: none;
}
.page-wrapper.only-body .page-body-wrapper .page-body {
  margin-top: 0px;
  min-height: calc(100vh - 0px);
}
.page-wrapper.only-body .page-body-wrapper .page-title {
  background: linear-gradient(26deg, var(--theme-deafult) 0%, #a26cf8 100%);
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 30px;
  border-radius: 15px;
  margin-top: 30px;
}
.page-wrapper.only-body .page-body-wrapper .page-title > .row .col-6 {
  flex: 0 0 100%;
  max-width: 100%;
}
.page-wrapper.only-body .page-body-wrapper .page-title .breadcrumb {
  margin-top: 5px;
  justify-content: flex-start;
}
.page-wrapper.modern-type .card .cal-date-widget .cal-info h2 {
  color: #7366ff;
}
.page-wrapper.modern-type .page-header {
  z-index: 100;
  margin-left: 0px;
  width: calc(100% - 0px);
}
.page-wrapper.modern-type .page-header.close_icon {
  margin-left: 0px;
  width: calc(100% - 0px);
}
.page-wrapper.modern-type .page-header .header-wrapper .header-logo-wrapper {
  display: flex;
  align-items: center;
}
.page-wrapper.modern-type .page-header .header-wrapper .logo-wrapper {
  display: block;
  margin-right: 30px;
}
.page-wrapper.modern-type .page-header .header-wrapper .toggle-sidebar {
  display: block !important;
  position: relative;
  top: unset;
  right: unset;
  margin-right: 15px;
}
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper {
  margin: 30px !important;
  top: 80px;
  bottom: 0;
  border-radius: 15px;
}
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper .logo-wrapper,
.page-wrapper.modern-type
  .page-body-wrapper
  div.sidebar-wrapper
  .logo-icon-wrapper {
  display: none;
}
.page-wrapper.modern-type
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links {
  height: calc(100vh - 160px);
}
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper.close_icon {
  left: -300px;
}
.page-wrapper.modern-type
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  .logo-icon-wrapper {
  display: none;
}
.page-wrapper.modern-type
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  ~ .page-body {
  margin-left: 0px;
}
.page-wrapper.modern-type
  .page-body-wrapper
  div.sidebar-wrapper.close_icon
  ~ footer {
  margin-left: 0px;
}
.page-wrapper.modern-type
  .page-body-wrapper
  div.sidebar-wrapper.close_icon:hover
  .logo-wrapper {
  display: none;
}
.page-wrapper.modern-type .page-body-wrapper .page-body {
  margin-left: 310px;
}
.page-wrapper.modern-type .page-body-wrapper .page-body .container-fluid {
  max-width: 1450px;
}
.page-wrapper.modern-type .page-body-wrapper .footer {
  margin-left: 390px;
  margin-right: 70px;
  border-radius: 10px;
}
.page-wrapper.modern-type
  .default-datepicker
  .react-datepicker
  .react-datepicker__day-name,
.page-wrapper.modern-type
  .default-datepicker
  .react-datepicker
  .react-datepicker__day,
.page-wrapper.modern-type
  .default-datepicker
  .react-datepicker
  .react-datepicker__time-name {
  width: 45px !important;
}
.page-wrapper.material-type {
  position: relative;
}
.page-wrapper.material-type::before {
  position: absolute;
  content: "";
  left: 0;
  top: -50px;
  width: 100%;
  height: 300px;
  background-image: linear-gradient(
    26deg,
    var(--theme-deafult) 0%,
    #a26cf8 100%
  );
}
.page-wrapper.material-type .card {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}
.page-wrapper.material-type .page-header {
  position: relative;
  max-width: 1560px;
  margin: 50px auto 0;
  border-radius: 15px 15px 0 0;
}
.page-wrapper.material-type .page-body-wrapper {
  /* background-image: url(../../images/other-images/boxbg.jpg); */
  background-blend-mode: overlay;
  background-color: rgba(255, 255, 255, 0.5);
}
.page-wrapper.material-type .page-body-wrapper .sidebar-wrapper {
  position: relative;
  max-width: 1560px;
  margin: 0 auto !important;
  top: unset;
  height: unset;
}
.page-wrapper.material-type .page-body-wrapper .page-body {
  margin: 0 auto;
  max-width: 1560px;
  background-color: #f8f8f8;
  box-shadow: 0 0 20px 7px rgba(8, 21, 66, 0.05);
  border-radius: 0 0 15px 15px;
}
.page-wrapper.material-type .footer {
  margin-top: 40px;
}
.page-wrapper.advance-layout {
  background-blend-mode: overlay;
  background-color: rgba(248, 248, 248, 0.7);
}
.page-wrapper.advance-layout .card {
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ecf3fa;
}
.page-wrapper.advance-layout .page-header {
  position: relative;
  background-color: #fff;
  margin: 0 auto;
}
.page-wrapper.advance-layout .page-body-wrapper {
  background: transparent;
}
.page-wrapper.advance-layout .page-body-wrapper .sidebar-wrapper {
  position: relative !important;
  top: unset !important;
  height: unset;
  box-shadow: none;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.page-wrapper.advance-layout .page-body-wrapper .sidebar-wrapper > div {
  overflow-x: hidden;
}
.page-wrapper.advance-layout .page-body-wrapper .page-body {
  margin-top: 0 !important;
}
.page-wrapper.material-type
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main.hovered
  .sidebar-links
  li.sidebar-list:hover
  .sidebar-submenu,
.page-wrapper.advance-layout
  .page-body-wrapper
  .sidebar-wrapper
  .sidebar-main.hovered
  .sidebar-links
  li.sidebar-list:hover
  .sidebar-submenu {
  display: none !important;
}
@keyframes pagebody {
  from {
    opacity: 0;
    transform: scale3d(0.98, 0.98, 0.98) translate(-20px, -50px);
  }
  50% {
    opacity: 1;
  }
}
@media screen and (max-width: 1780px) {
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper
    .sidebar-wrapper
    .sidebar-main
    .sidebar-links
    > li
    .mega-menu-container {
    right: -18%;
    width: 91%;
  }
}
@media screen and (max-width: 1480px) {
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper
    .sidebar-wrapper
    .sidebar-main
    .sidebar-links
    > li
    .mega-menu-container {
    right: -18%;
    width: 91%;
  }
}
@media screen and (max-width: 1460px) {
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper
    .sidebar-wrapper
    .sidebar-main
    .sidebar-links
    > li
    .mega-menu-container {
    right: -54%;
    width: 75%;
  }
  .sidebar-wrapper .sidebar-main .sidebar-links > li {
    margin-right: 8px;
  }
}
@media screen and (max-width: 1366px) {
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper
    .sidebar-wrapper
    .sidebar-main
    .sidebar-links
    > li
    .mega-menu-container {
    right: -49%;
    width: 70%;
  }
  .profile-dropdown.onhover-show-div {
    right: 0;
    left: unset !important;
  }
  .profile-dropdown.onhover-show-div:before,
  .profile-dropdown.onhover-show-div:after {
    right: 10px !important;
    left: unset !important;
  }
}
@media screen and (max-width: 1199px) {
  .page-wrapper .page-header .header-wrapper .nav-right > ul > li {
    padding: 6px;
  }
  .left-header .mega-menu-container .submenu-title {
    margin: 20px 20px 0 20px;
  }
  .mobile-title.d-none {
    display: flex !important;
    justify-content: space-between;
    padding: 20px 30px 17px;
    border-bottom: 1px solid #ddd;
    margin: 0 -15px;
  }
  .mobile-title.d-none h5 {
    color: var(--theme-deafult);
  }
  .mobile-title.d-none svg {
    stroke: var(--theme-deafult) !important;
  }
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper
    .sidebar-wrapper
    .sidebar-main
    .sidebar-links
    > li
    .mega-menu-container {
    right: -35%;
    width: 56%;
  }
}
@media screen and (min-width: 992px) {
  .page-header .header-wrapper .nav-right .notification-dropdown {
    top: 46px;
  }
  .responsive-btn {
    display: none;
  }
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper
    .sidebar-main
    .sidebar-links
    > li
    .sidebar-submenu {
    width: 200px;
    opacity: 0;
    visibility: hidden;
    border-radius: 5px;
    transition: all 0.5s ease;
  }
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper
    .sidebar-main
    .sidebar-links
    > li
    .sidebar-submenu
    a
    .sub-arrow {
    position: absolute;
    right: 20px;
    top: 5px;
    color: #59667a;
    font-weight: 100;
    transition: all 0.5s ease;
    font-size: 10px;
    top: 9px;
  }
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper
    .sidebar-main
    .sidebar-links
    > li
    .sidebar-submenu
    li
    .nav-sub-childmenu {
    display: none;
    border-radius: 4px;
  }
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper
    .sidebar-main
    .sidebar-links
    > li
    .sidebar-submenu
    li:hover
    .nav-sub-childmenu {
    display: block !important;
    position: absolute;
    left: 200px;
    background-color: #fff;
    top: -10px;
    padding: 15px 0px 15px 0px;
    box-shadow: 0 0 1px 0 #898989;
  }
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper
    .sidebar-main
    .sidebar-links
    > li
    .sidebar-submenu
    li
    a:hover
    .sub-arrow {
    right: 16px;
    transition: all 0.5s ease;
  }
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper
    .sidebar-main
    .sidebar-links
    > li
    .mega-menu-container {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
  }
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper
    .sidebar-main
    .sidebar-links
    > li:hover
    .sidebar-submenu {
    opacity: 1;
    visibility: visible;
    margin-top: 8px;
    transition: all 0.5s ease;
  }
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper
    .sidebar-main
    .sidebar-links
    > li:hover
    .mega-menu-container {
    opacity: 1;
    visibility: visible;
    margin-top: 20px;
    transition: all 0.5s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .footer {
    margin-left: 0;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .toggle-nav {
    display: none;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .mobile-back {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) {
  .page-wrapper.material-icon {
    position: relative;
  }
  .page-wrapper.material-icon::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 240px;
    background: linear-gradient(26deg, var(--theme-deafult) 0%, #a26cf8 100%);
  }
  .page-wrapper.material-icon .toggle-sidebar {
    color: rgba(255, 255, 255, 0.8);
  }
  .page-wrapper.material-icon .toggle-sidebar:hover > svg {
    color: #fff !important;
    stroke: #fff !important;
  }
  .page-wrapper.material-icon .left-header .mega-menu .nav-link,
  .page-wrapper.material-icon .left-header .level-menu .nav-link {
    background-color: rgba(255, 255, 255, 0.09);
    color: rgba(255, 255, 255, 0.7);
  }
  .page-wrapper.material-icon .left-header .mega-menu .nav-link svg,
  .page-wrapper.material-icon .left-header .level-menu .nav-link svg {
    stroke: rgba(255, 255, 255, 0.7);
  }
  .page-wrapper.material-icon .page-header {
    position: relative;
    background: transparent;
    padding-left: 90px;
    max-width: 1660px;
    margin: 0 auto;
    width: calc(100% - 0px);
  }
  .page-wrapper.material-icon
    .page-header
    .header-wrapper
    .nav-right
    > ul
    > li {
    color: #fff;
  }
  .page-wrapper.material-icon
    .page-header
    .header-wrapper
    .nav-right
    > ul
    > li
    .notification-box
    svg,
  .page-wrapper.material-icon
    .page-header
    .header-wrapper
    .nav-right
    > ul
    > li
    .cart-box
    svg {
    stroke: #fff;
  }
  .page-wrapper.material-icon
    .page-header
    .header-wrapper
    .nav-right
    > ul
    > li
    > a
    > svg {
    stroke: #fff;
  }
  .page-wrapper.material-icon
    .page-header
    .header-wrapper
    .nav-right
    > ul
    > li
    > svg {
    stroke: #fff;
  }
  .page-wrapper.material-icon
    .page-header
    .header-wrapper
    .nav-right
    .language-nav
    .more_lang {
    color: #2b2b2b;
  }
  .page-wrapper.material-icon
    .page-header
    .header-wrapper
    .nav-right
    .onhover-show-div {
    color: #2b2b2b;
  }
  .page-wrapper.material-icon
    .page-header
    .header-wrapper
    .nav-right.right-header
    ul
    li
    .profile-media
    .media-body
    p {
    color: rgba(255, 255, 255, 0.7);
  }
  .page-wrapper.material-icon .page-header.close_icon {
    padding-left: 0;
    margin: 0 auto !important;
  }
  .page-wrapper.material-icon .page-body-wrapper .page-body {
    margin-top: 0 !important;
    padding-left: 90px;
    max-width: 1660px;
    margin: 0 auto;
  }
  .page-wrapper.material-icon
    .page-body-wrapper
    div.sidebar-wrapper.close_icon
    ~ .page-body {
    padding-left: 15px;
    margin: 0 auto !important;
  }
  .page-wrapper.material-icon .page-body-wrapper .page-title {
    color: #fff;
  }
  .page-wrapper.material-icon
    .page-body-wrapper
    .page-title
    .breadcrumb
    .breadcrumb-item
    a
    svg {
    color: #fff;
  }
  .page-wrapper.material-icon
    .page-body-wrapper
    .page-title
    .breadcrumb
    .breadcrumb-item.active {
    color: rgba(255, 255, 255, 0.6);
  }
  .page-wrapper.material-icon
    .page-body-wrapper
    .page-title
    .breadcrumb
    .breadcrumb-item
    + .breadcrumb-item::before {
    color: rgba(255, 255, 255, 0.8);
  }
}
@media screen and (max-width: 767px) {
  .sidebar-wrapper .menu .brand-logo img {
    width: 120px;
  }
}
@media screen and (max-width: 576px) {
  .toggle-nav i {
    font-size: 20px;
  }
}
/**===================== 78. Sidebar CSS Ends ==========================**/

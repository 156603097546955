.indexedStep {
  color: white;
  width: 10px;
  height: 10px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep.accomplished {
  background-color: green;
}

.indexedStep.accomplished_warn {
  background-color: #ffcc00;
}

#buyer-utility-map th {
  position: sticky;
  left: 0;
  z-index: 50;
}

$purple: rgb(201, 94, 255);
$deep-purple: rgb(47,16,62);
$green: #32D74B;
$deep-green: rgb(6,60,14);
$blue: rgb(39, 164, 214);
$blue: #005bea;
$deep-blue: rgb(23,51,59);
$white: #FFFFFF;
$black-2: #333333;
$black-333: #333333;
$black-888: #888888;
$gradient-colorful: radial-gradient(closest-side, rgba(228, 79, 79, 0.7), rgba(228, 79, 79, 0)) 600% 500% / 90% 90% no-repeat, radial-gradient(closest-side, rgba(228, 79, 79, 0.7), rgba(228, 79, 79, 0)) 600% 180% / 90% 90% no-repeat, radial-gradient(closest-side, rgba(250, 250, 218, 0.2), rgba(250, 250, 218, 0)) 100% 150% / 75% 50% no-repeat, radial-gradient(closest-side, rgba(250, 250, 218, 0.3), rgba(250, 250, 218, 0)) -400% -100% / 90% 80% no-repeat, radial-gradient(closest-side, rgba(94, 225, 249, 0.8), rgba(94, 225, 249, 0)) -100% -250% / 85% 80% no-repeat, radial-gradient(closest-side, rgba(94, 225, 249, 0.6), rgba(94, 225, 249, 0)) -170% 100% / 70% 60% no-repeat, linear-gradient(30deg, rgb(110, 16, 206) 10%, rgba(110, 16, 206, 0) 70%, rgba(255, 255, 255, 0) 90%) 50% 50% / 100% 100% no-repeat, linear-gradient(144deg, rgba(233, 235, 104, 0), rgba(233, 235, 104, 0.3)) no-repeat, linear-gradient(90deg, rgba(104, 184, 235, 0.11), rgba(15, 216, 223, 0.11)) no-repeat, rgb(255, 255, 255);
$gradient-colorful2: radial-gradient(closest-side, rgba(228, 84, 79, 0.7), rgba(228, 79, 79, 0)) 600% 500% / 90% 90% no-repeat, radial-gradient(closest-side, rgba(228, 79, 79, 0.7), rgba(228, 79, 79, 0)) 600% 180% / 90% 90% no-repeat, radial-gradient(closest-side, rgba(250, 250, 218, 0.2), rgba(250, 250, 218, 0)) 100% 150% / 75% 50% no-repeat, radial-gradient(closest-side, rgba(250, 250, 218, 0.3), rgba(250, 250, 218, 0)) -400% -100% / 90% 80% no-repeat, radial-gradient(closest-side, rgba(8, 46, 150, 0.8), rgba(94, 225, 249, 0)) -100% -250% / 85% 80% no-repeat, radial-gradient(closest-side, rgba(94, 225, 249, 0.6), rgba(94, 225, 249, 0)) -170% 100% / 70% 60% no-repeat, linear-gradient(30deg, rgb(79, 8, 151) 10%, rgba(110, 16, 206, 0) 70%, rgba(255, 255, 255, 0) 90%) 50% 50% / 100% 100% no-repeat, linear-gradient(144deg, rgba(233, 235, 104, 0), rgba(233, 235, 104, 0.3)) no-repeat, linear-gradient(90deg, rgba(104, 184, 235, 0.11), rgba(15, 216, 223, 0.11)) no-repeat, rgb(255, 255, 255);
$gradient-purple: linear-gradient( 320deg,rgba(235,70,52,0.5),rgba(235,70,52,0) ),linear-gradient(180deg,#bf5af2 0%,#7c0eb4 100%);
$gradient-green: linear-gradient( -220deg,rgba(255,251,0,0.4),rgba(255,251,0,0) 50% ),linear-gradient(180deg,rgba(0,0,0,0.21),rgba(0,0,0,0)),linear-gradient(179deg,#32d74b,#1b892b);
//$gradient-blue: linear-gradient( 320deg,rgba(92,235,52,0.3),rgba(92,235,52,0) ),linear-gradient(180deg,#27a4d6 0%,#1a6d8e 100%);
$gradient-blue: linear-gradient( 320deg,rgba(92,235,52,0.3),rgba(92,235,52,0) ),linear-gradient(180deg,#00c6fb 0%,#005bea 100%);
$gradient-grey-dark: linear-gradient(rgba(118, 118, 128, 0.35), rgba(118, 118, 128, 0.18));
$gradient-light: linear-gradient(rgba(245, 245, 245, 0.95), rgba(240, 240, 240, 0.9)) rgb(191, 90, 242);

@keyframes pulse {
    0% {
      background-color: #FFE889;
    }
    50% {
      background-color: #26FFFF;
    }
  }

.text-purple { color: $purple; }
.text-green { color: $green; }
.text-blue { color: $blue; }
.text-black-333 {color: $black-333}
.text-black-888 {color: $black-888}
.text-deep-purple {color: $deep-purple}
.text-deep-blue {color: $deep-blue}
.text-deep-green {color: $deep-green}

.bg-gradient-colourful { background: $gradient-colorful; animation: pulse 10s ease-out 0s alternate infinite none running; }
.bg-gradient-purple { background: $gradient-purple; }
.bg-gradient-green { background: $gradient-green; }
.bg-gradient-blue { background: $gradient-blue; }
.bg-gradient-grey-dark { background: $gradient-grey-dark; }
.bg-gradient-light { background: $gradient-light; }


.gradient-box { padding: 50px 50px 0 50px;  border-radius: 24px; height: 100%; position: relative; }
.icon-box { border-radius: 12px; height: 100%;}

.event-card {border-radius: 12px !important;
    .card-img-top { border-radius: 12px 12px 0 0; }
    .card-body { border-radius: 0 0 12px 12px; }
}
.btn-hero {cursor: pointer; transition: 0.5s; &:hover { opacity: 80%; }}

.rounded-12 { border-radius: 12px }
.smaller { font-size: 60%; }

.p-relative {position: relative;}

.text-s-1 { font-weight: 700; font-size: 64px; line-height: 1.03125em; }
.text-s-2{ font-weight: 600; font-size: 32px; line-height: 1.16667em; }
.text-s-2-bold{ font-weight: 700; font-size: 32px; line-height: 1.16667em; }
.text-s-3 { font-weight: 700; font-size: 64px; line-height: 1.03125em; }
.text-s-4 { font-weight: 700; font-size: 24px; }
.text-s-5 { font-weight: 700; font-size: 35px; }
.text-s-6 { font-weight: 700; font-size: 22px; line-height: 1.16667em; }
.text-s-6-light { font-weight: 400; font-size: 22px; line-height: 1.16667em; }
.text-s-7 { font-weight: 600; font-size: 20px; line-height: 1.16667em; }
.text-s-8 { font-weight: 700; font-size: 17px; white-space: nowrap; }
.text-s-9 { font-weight: 700; font-size: 11px; white-space: nowrap;}
.text-s-10 { font-size: 1.1rem; margin-bottom: 1rem; line-height: 1.6;}
.text-s-11 { font-size: 0.9rem; line-height: 1.03125em; text-align: right;}
.text-s-12 { font-size: 0.9rem; line-height: 1.4em;}


.no-dropdown-caret .dropdown-toggle:after { content: none }

.pointer {cursor: pointer;}

// Border bottom on all except last child
.border-bottom-sans-end {
  border-bottom: 1px solid #EFEFEF;
}
.border-bottom-sans-end:last-child {
  border-bottom: 0px;
}

// List with checkmark on Course registration page

ul.checkmark-list {
  list-style: none;
  list-style-type: none;
  padding-left: 0px;
  li:before {
    display: inline-block;
    content: '\2713\0020';
    margin-right: 10px;
  }
}

// Checkmark on progress

.custom-checkmark input[type="checkbox"] {
  border: 2px solid #000;
  height: 15px;
  width: 15px;
  accent-color: black;
}

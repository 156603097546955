/* Course cover */
.dark_bg {
    background-color: #3A3B3C !important;
}

.project_cover_card {
    border: 1px solid #707070 !important;
    background-color: #3A3B3C !important;
}

.project_cover_card_body {
    position: relative;
    background-color: #3A3B3C;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}

.project_cover_img {
    min-height: 550px;
    background-position: center;
    background-size: cover;
}

.project_cover_img1 {
    min-height: 350px;
    background-position: center;
    background-size: cover;
}

.project_cover_logo {
    width: 50px;
    height: auto;
    /* padding: 6px; */
    /* background-color: #efefef; */
}

.project_cover_content {
    /* margin-left: 10px; */
    flex-grow: 1;
}

.enroll_btn {
    padding: 10px !important;
}

.cta_tabs {
    padding: 5px 25px !important;
    border-radius: 10px !important;
    font-weight: bold;
    color: white;
}

.tick_list {
    list-style: none;
    padding: 0;
}
.tick_list li {
    position: relative;
    padding-left: 1.5em;
}
.tick_list li:before {
    content: '✓';
    position: absolute;
    left: 0;
    top: 0.25em;
    width: 1.25em;
    height: 1.25em;
    /* border: 1px solid green; */
    /* background-color: green; */
    /* border-radius: 50%; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.app_icon, .card .app_icon {
    background-color: rgba(58, 59, 60, 1) !important;
    cursor: pointer;
}
.app_icon:hover {
    background-color: rgba(58, 59, 60, 0.6) !important;
}
.app_icon_image {
    height: 2.5rem;
    width: auto;
}

.folder_icon, .card .folder_icon {
    /* background-color: rgba(58, 59, 60, 1) !important; */
    cursor: pointer;
}
.folder_icon:hover {
    background-color: rgba(58, 59, 60, 0.6) !important;
}
.folder_icon_image {
    height: 3rem;
    width: auto;
}


  [data-theme="dark"] {
    background-color: #232626;
    color: #fff;
  }
  [data-theme="light"] {
    background-color: #fff;
    color:  #3F3F3F;
  }
  
  [data-theme-navbar="dark"] {
    background-color: rgb(23 25 26);
    color: #fff;
  }
  [data-theme-navbar="light"] {
    background-color: #ededed;
    color:  #3F3F3F;
  }

  iframe {
    border-radius: 0.25rem;
  }

/* consultation cover */
.consultation_cover_card {
    border: 1px solid #707070 !important;
    background-color: #3A3B3C !important;
}

.consultation_cover_card_body {
    position: relative;
    background-color: #3A3B3C;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}

.consultation_cover_img {
    min-height: 550px;
    background-position: center;
    background-size: cover;
}

.consultation_cover_img1 {
    min-height: 350px;
    background-position: center;
    background-size: cover;
}

.consultation_cover_logo {
    width: 50px;
    height: auto;
}

.consultation_cover_content {
    margin-left: 10px;
    flex-grow: 1;
}

.register_btn {
    padding: 10px  !important;
}

.cta_tabs {
    padding: 5px 25px !important;
    border-radius: 10px !important;
    font-weight: bold;
    color: white;
}
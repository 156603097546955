/* Course cover */
.dark_bg {
    background-color: #3A3B3C !important;
}

.course_cover_card {
    border: 1px solid #707070 !important;
    background-color: #3A3B3C !important;
}

.course_cover_card_body {
    position: relative;
    background-color: #3A3B3C;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}

.course_cover_img {
    min-height: 550px;
    background-position: center;
    background-size: cover;
}

.course_cover_img1 {
    min-height: 350px;
    background-position: center;
    background-size: cover;
}

.course_cover_logo {
    width: 50px;
    height: auto;
}

.course_cover_content {
    /* margin-left: 10px; */
    flex-grow: 1;
}

.enroll_btn {
    padding: 10px !important;
}

.cta_tabs {
    padding: 5px 25px !important;
    border-radius: 10px !important;
    font-weight: bold;
    color: white;
}

.tick_list {
    list-style: none;
    padding: 0;
}
.tick_list li {
    position: relative;
    padding-left: 1.5em;
}
.tick_list li:before {
    content: '✓';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.25em;
    height: 1.25em;
    /* border: 1px solid green; */
    /* background-color: green; */
    /* border-radius: 50%; */
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sub_module_element {
    cursor: pointer;
    padding: 5px;
}
.sub_module_element:hover {
    background-color: #111111;
}

.instructor_img {
    height: 60px !important;
    width: 60px !important;
}
/* Timeline cover */
.timeline_cover_card {
  border: 1px solid #707070 !important;
  background-color: #3a3b3c !important;
}

.timeline_cover_card_body {
  position: relative;
  background-color: #3a3b3c;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timeline_cover_img {
  height: 350px;
  background-position: center;
  background-size: cover;
  /* background-image: url("./assets/igesia-cover.jpg"); */
}

.timeline_cover_logo {
  position: absolute;
  width: 120px;
  height: 120px;
  /* background-color: white; */
  border-radius: 50%;
  padding: 2px;
  z-index: 10;
  top: -40px;
}

.timeline_cover_content {
  margin-left: 120px;
}

.follow_btn {
  padding: 10px 100px !important;
}

/* Call to action */
.cta_card {
  border: 1px solid #707070 !important;
  background-color: #3a3b3c !important;
}
.cta_tabs {
  padding: 5px 25px !important;
  border-radius: 10px !important;
  font-weight: bold;
  color: white;
}
.services_cards {
  /* border-radius: 10px !important; */
  border: 1px solid #707070 !important;
  background-color: #232626 !important;
}

/* Spotlight */
.spotlight_card {
  /* border: 1px solid #707070 !important; */
  background-color: #3a3b3c !important;
  color: white;
  cursor: pointer;
}

/* About */
.about_card {
  border: 1px solid #707070 !important;
  background-color: #3a3b3c !important;
  color: white;
}

/* Custom content */
.custom_content_card {
  border: 1px solid #707070 !important;
  background-color: #3a3b3c !important;
}

/* Write post */
.write_post_card {
  border: 1px solid #707070 !important;
  background-color: #3a3b3c !important;
}

.write_post_modal_header {
  background-color: #232626 !important;
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}
.write_post_modal_body {
  background-color: #232626 !important;
  border-bottom-left-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}
.write_post_modal_form {
  max-height: 50vh;
  overflow-y: scroll;
}
.write_post_modal_close_btn {
  text-decoration: none !important;
  cursor: pointer;
  color: #818181;
  font-size: 36px;
  position: absolute;
}
.write_post_modal_close_btn:hover {
  color: #f1f1f1;
}
.write_post_modal_remove_img_btn {
  text-decoration: none !important;
  cursor: pointer;
  color: #818181;
  font-size: 36px;
  position: absolute;
  top: 0;
  right: 0.75rem;
}
.write_post_modal_remove_img_btn:hover {
  color: #f1f1f1;
}

.write_post_card_text_area {
  background-color: #232626 !important;
}

/* Filter timeline data */
.filter_card {
  border: 0px !important;
  background-color: #232626 !important;
}
.filter_tabs {
  padding: 5px 25px !important;
  border-radius: 10px !important;
  font-weight: bold;
  color: white;
}

/* Post data */
.post_card {
  border: 1px solid #707070 !important;
  background-color: #3a3b3c !important;
  color: white;
}

.react-calendar {
    border: none;
    width: 350px;
    padding: 10px;
    font-size: 12px;
  }
  
  .react-calendar button {
    border-radius: 50%;
    padding: 13px !important;
    align-items: center;
  }
  
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #232626;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#style-4::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}

#style-4::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

#style-4::-webkit-scrollbar-thumb {
  background-color: #141414;
  /* border: 2px solid #555555; */
}

.active-dot::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 2px;
  color: #007bff !important;
  bottom: 0%;
  left: 50%;
}
.active-dot::after:hover {
  position: absolute;
  content: "";
  width: 2px;
  height: 2px;
  color: #0056b3 !important;
  bottom: 0%;
  left: 50%;
}

/* svg {
  pointer-events: none;
} */

.custom-file-label-dark::after {
  color: #ffffff !important;
  background-color: #495057 !important;
}
.progresscheck .progress-bar {
  color: black;
}

.pointer {
  cursor: pointer;
}
.changecol {
  background-color: #5375b2;
  border-radius: 10px;
  font-weight: 900;
}

.graphbuttons {
  background-color: inherit !important;
  border: none !important;
  font-weight: 900 !important;
  margin-right: 15px !important;
}

.graph-active {
  background-color: #5375b2 !important;
  border: none !important;
  font-weight: 900 !important;
  border-radius: 10px !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}
.nodata{
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}
.nodatatop{
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  margin-top: 20vh;
  font-size: 5em;
}


.bg-graphite {
  /* background-color: #232626; Arani*/
  background-color: #FEFEFE;  /* Arani */
}
.full-screen {
  min-width: 100vw;
  min-height: 100vh;
}

.ck-editor__editable_inline {
  min-height: 300px;
  background-color: #232626 !important;
  border-color: black !important;
  color: white;
}

.ck .ck-toolbar {
  background-color: #aeaeae !important;
  border-color: black !important;
}

#post-modal .modal-content {
  border-radius: 0.75rem !important;
  border-color: #232626;
  background-color: #232626;
}

/* For tabs on event page. bad approach -Arani */

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link  {
  color: #007BFE !important;
  background-color: transparent;
  border-bottom: 2px solid #007BFE;
  border-radius: 0;
}

html {
  scroll-behavior: smooth;
}
.balloon1 {
  position: relative;
  margin-top: 25px !important;
  margin-bottom: 5px !important;
  background: #42a5f5;
  color: #fff !important;
  border-radius: 10px;
  display: block;
  max-width: 75%;
  padding: 7px 13px 7px 13px;
}
.balloon1 a {
  color: #fff !important;
  text-decoration: underline;
}

.balloon1:before {
  content: attr(data-is);
  position: absolute;
  right: 0;
  bottom: -25px;
  display: block;
  font-size: 0.75rem;
  color: rgba(84, 110, 122, 1);
  width: 100%;
  text-align: right;
}

.message {
  clear: both;
  word-wrap: break-word;
  font-size: 14px;
  .timestamp {
    margin-top: 2px;
    margin-left: 5px;
  }
  img {
    width: 300px;
    border-radius: 5px;
  }
  .message__self {
    padding: 6px 8px;
    max-width: 550px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);
    float: right;
    background: #e1ffc7;
    border-radius: 5px 0px 5px 5px;
    margin-right: 30px;
    margin-bottom: 5px !important;
    .author {
      display: none;
    }
    .timestamp {
      margin-left: 0px;
    }
  }
  .message__others {
    padding: 6px 8px;
    max-width: 550px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);
    float: left;
    background-color: #f0f2f5 !important;
    color: var(--text-theme);
    border-radius: 0 8px 8px 8px;
    margin-left: 30px;
    margin-bottom: 5px !important;
    .author {
      float: left;
      background-color: none !important;
      padding: 0px;
      border-radius: 0;
      margin-left: 0;
      max-width: 100%;
      font-size: 13px;
    }
  }
  .url_message_img {
    height: 90px !important;
    width: 90px !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 5px;
  }
  .url_message_text {
    width: calc(100% - 110px);
    max-height: 90px !important;
    height: 90px !important;
    margin-left: 5px;
  }
  @media only screen and (max-width: 767px) {
    .message__self {
      margin-right: 0px;
      max-width: calc(var(--vw, 1vw) * 80);
    }
    .message__others {
      margin-left: 0px;
      max-width: calc(var(--vw, 1vw) * 80);
    }
  }
}

.message__image {
  padding: 1em 0 0 0;
  width: 300px;
  border-radius: 5px;
}

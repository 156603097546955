.react-calendar__tile--now {
  background: var(--primary);
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: var(--primary);
}
.react-calendar__navigation__arrow,
.react-calendar__navigation__label {
  color: #ffffff;
}

.mobile_sidebar_left  {
    width: 100%;
    position: fixed;
    z-index: 100;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #232626;
    overflow-x: hidden;
    transition: 0.5s;
  }
  
  .mobile_sidebar_left a {
    padding: 8px;
    text-decoration: none;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  .mobile_sidebar_left a:hover {
    color: #f1f1f1;
  }
  
  .mobile_sidebar_left .close_btn {
    font-size: 36px;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
  }
  
  .open_btn {
    font-size: 20px;
    cursor: pointer;
    background-color: #232626;
    color: white;
    padding: 10px 15px;
    text-align: left;
    border: none;
    width: 100%;
  }
  
  .open_btn:hover {
    background-color:#444;
  }
.NoteView-data {
  font-size: 14px;
  font-weight: 300;
  height: 380px;
  overflow-y: auto;
}

.NoteView-action{
  position: absolute;
  right: 60px;
  bottom: 10px;
}